import {
    Box,
    Text,
    Image,
    Button,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Checkbox,
    Tbody,
    Td,
    Flex,
  } from "@chakra-ui/react";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import ReactPaginate from "react-paginate";
  import { rowes } from "../assets/images";
import { useDispatch } from "react-redux";
import { getAlloffers } from "../api/offer";
  
  function Items({ currentItems, handleChange, handleId,addRedemption }) {
    return (
      <>
        <Box mt="2%">
          <TableContainer
            rounded="md"
        
            border="1px"
            borderColor="gray.300"
          >
            <Table size="sm">
              <Thead bg="gray.100">
                <Tr>
                  <Th p={4}>
                    <Checkbox
                      colorScheme="red"
                      isChecked={
                        currentItems?.filter(ticket => ticket?.isChecked !== true).length < 1
                      }
                      name="allChecked"
                      onChange={handleChange}
                    ></Checkbox>
                  </Th>
                  <Th>
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="#6B6162"
                      m={2}
                    >
                      Offer name
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="#6B6162"
                      textAlign="center"
                    >
                      Status
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="#6B6162"
                      textAlign="center"
                    >
                      Quantity
                    </Text>
                  </Th>
                  <Th>
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="#6B6162"
                      textAlign="center"
                    >
                      Sold
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize="12px" fontWeight="600" color="#6B6162" textAlign="center">
                      Redeemed
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize="12px" fontWeight="600" color="#6B6162" textAlign="center">
                      Price
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize="12px" fontWeight="600" color="#6B6162" textAlign="center">
                      Sales
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize="12px" fontWeight="600" color="#6B6162" textAlign="center">
                      Created
                    </Text>
                  </Th>
                  <Th>
                    <Text>Action</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody bg="white" w="full" >
                {currentItems?.map(value => (
                  <Tr key={value._id} mt={4}>
                    <Td p={4} mt={4}>
                      <Checkbox
                        isChecked={value.isChecked || false}
                        colorScheme="red"
                        name={value._id}
                        onChange={handleChange}
                      />
                    </Td>
                    <Td>
                      <Flex alignItems="center">
                        <Image
                          borderRadius="full"
                          boxSize="30px"
                          src={value.offerImage[0] || rowes}
                          ml="10px"
                        />
                        <Text
                          fontSize="14px"
                          fontWeight="600"
                          color="#3B3538"
                          ml="10px"
                          whiteSpace="break-spaces"
                        >
                          {value.offerName}
                         
                          <br />
                          <Text
                            fontSize="12px"
                            fontWeight="400"
                            color="#3B3538"
                          >
                           {value.sellerDetails.name.businessName}
                          </Text>
                        </Text>
                      </Flex>
                    </Td>
                    <Td w={100}>
                      <Button
                        bg={
                          value.status === "Sold"
                            ? "#CCC9C7"
                            : value.status === "Suspended"
                            ? "#FFA3AC"
                            : value.status === "active"
                            ? "#B5D779"
                            : value.status === "Paused"
                            ? "#FDE68B"
                            : "Orange"
                        }
                        fontSize="14px"
                        fontWeight="400"
                        textAlign={"center"}
                        w={85}
                        h={6}
                        borderRadius="20px"
                      >
                       {value.status}
                      </Button>
                    </Td>
                    <Td>
                      <Text fontSize="14px" fontFamily="Commissioner"textAlign="center">
                      {value.totalQuantity}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                    {value?.sales?.sold}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="14px" textAlign="center">
                        {value?.sales?.redeemed}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                      £ {value?.price?.discountedPrice}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                      £ {(value?.sales?.sold) * (value?.price?.discountedPrice)}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="14px" fontFamily="Commissioner" textAlign="center">
                       {value.createdAt}
                        </Text>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="white"
                        color="#FF1A57"
                        border="1px"
                        borderColor="#FF1A57"
                        fontSize="xs"
                        borderRadius="lg"
                        px={5}
                        onClick={() => handleId(value._id, value)}
                      >
                       Edit
                      </Button>
                      <Button
                        colorScheme="white"
                        color="#FF1A57"
                        border="1px"
                        borderColor="#FF1A57"
                        fontSize="xs"
                        borderRadius="lg"
                        
                        mx={2}
                        onClick={() => {
                          addRedemption(value._id);
                        }}
                      >
                       Add Redemption
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  }
  
  function OffersTable({ itemsPerPage, items, handleChange, handleId,addRedemption,totalOffers,setUsers }) {

    const dispatch = useDispatch()
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    
    useEffect(() => {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(items);
      setPageCount(Math.ceil(totalOffers/ itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);

    const handlePageClick = (event) => {
      let pageNo = event.selected
      dispatch(getAlloffers(pageNo)).then((res)=>{
        setUsers(res.data.offersListing)
      }).catch((err)=>{
        console.log(err)
      })
      const newOffset = (event.selected * itemsPerPage) % totalOffers;
      setItemOffset(newOffset);
    };
    return (
      <>
        <Items
          currentItems={currentItems}
          handleChange={handleChange}
          handleId={handleId}
          addRedemption={addRedemption}
        />
        <Box bg="white" w="full">
          <ReactPaginate
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item me-auto"
            previousLinkClassName="page-link ms-auto bg-transparent"
            nextClassName="page-item ms-auto"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination justify-content-center shadow"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </Box>
      </>
    );
  }
  
  export default OffersTable;
  

  