import {
  Box,
  Center,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { frame, updatedLogo } from "../../assets/icons";
import { FormControl, FormLabel, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../api/user";
import { getFromLocalStorage } from "../../helpers";
import { toast } from "react-toastify";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .email("Enter valid Email"),
  })
  .required();
const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const token = getFromLocalStorage("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

const handleForgotPassword = (data) => {
  dispatch(forgotPassword(data)).then((res)=>{
    toast.success(res.otp)
    navigate("/otpVerification",{
      state: {
        email:data.email,
        otp:res.otp
      }
    })
  }).catch((err)=> {
    toast.error(err.data.message)
  })
}
  const backToLogin = () => {
    navigate("/login")
  }
  return (
    <Box w="100vw" h="100vh">
      <Box
        w={["100%", "30%"]}
        position={["relative", "absolute"]}
        top="50%"
        right="50%"
        mx={"auto"}
        transform={`translate(50%, -50%)`}
      >
        <Center>
          <Image src={updatedLogo} />
        </Center>
        <Text textAlign="center" fontSize="14px">
          Admin Dashboard V1.0
        </Text>
        <Box w="full" spacing={8}>
          <Text
            textAlign="center"
            my={4}
            fontSize="xl"
            fontWeight="bold"
            color="#3B3538"
          >
            Forgot Password
          </Text>
          <FormControl mt="30px" w={["90%", "78%"]} mx="auto">
          <FormLabel color="#6B6162" fontSize="14px">
              Email
            </FormLabel>
            <Input
          
              type="email"
              placeholder="email address"
              boxShadow="lg" 
              w={["100%", "360px"]}
              variant="outline"
              {...register("email", {required: true})}
              _focusVisible={{
                outline: "none",
           }}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.email?.message}
            </Text>
          </FormControl>
          <Box w={["90%", "78%"]} mx="auto">
            <Button
              bg="primary"
              onClick={handleSubmit(handleForgotPassword)}
              mt={6}
              w={["100%", "360px"]}
              mx="auto"
              color="white"
              isLoading={isLoading}
            >
              Submit
            </Button>
            <Text
              my={2}
              fontSize="sm"
              cursor="pointer"
              textAlign="center"
              color="primary"
              onClick={backToLogin}
            >
              Back to Login
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
