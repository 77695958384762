import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import { Box, Text } from '@chakra-ui/react'
import { getAllNotifications, markAsSeenNotifications } from '../../api/admin'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { userSvg } from '../../assets/icons'

const Notification = () => {
    const dispatch = useDispatch();
    const [notification,setNotification] = useState()

    const getAllNotificationData = async ( ) =>{
        try {
            const res = await dispatch(getAllNotifications()).then((res)=>{
                setNotification(res?.data?.notifications)
            });
        } catch (error) {
            console.log(error)
        }
    }

    const seenNotification = async() =>{
      try {
        const res = await dispatch(markAsSeenNotifications()).then((res)=>{
          console.log(res)
          // setNotification(res?.data?.notifications)
      });
      } catch (error) {
        console.log(error)
      }
    }
    useEffect(()=>{
    getAllNotificationData();
    seenNotification()
    },[])

 
  return (
   <Layout>
    <Box
            w={{ xl: "96%", lg: "95%", md: "92%" }}
            mx={{ xl: "auto", lg: "auto", md: "auto" }}
            display="flex"
            justifyContent="space-between"
          >
            <Text
              fontFamily="Rubik"
              color="#2E1A47"
              fontSize={["20px", "34px"]}
              fontWeight="600"
              lineHeight="40px"
              letter="-4%"
              w="full"
            >
              Push Notification
            </Text>
            <Header />
          </Box>

 <Box w="98%"  mt="12">
 <div class="notification-ui_dd-content">
 {notification?.map((item) => {
    return (
            <div class="notification-list notification-list--unread align-items-center">
             
                <div class="notification-list_content">
                <div class="notification-list_img">
                 <img src={item?.sellerId?.profileImage || userSvg} alt="user"/>
                  </div>
                    <div class="notification-list_detail">
                   <p><b>{item?.type}</b> </p>
                   <h5 class="text-muted">{item?.title}</h5>
                  <p class="text-muted mt-2"><small>{moment(item?.updatedAt).format("DD-MM-YYYY")} | {moment(item?.updatedAt).format("HH-MM")} </small></p>
               </div>
              </div> 
              </div> )
               })
                }

                
             
            
        </div>
 </Box>
    
   </Layout>
  )
}

export default Notification