import {
  Box,
  Text,
  Image,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Flex,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { rowes } from "../assets/images";

function Items({ currentItems, handleChange, handleId }) {
  return (
    <>
      <Box mt="2%">
        <TableContainer
          rounded="md"
          w={{lg:"98%",md:"92%"}}
          mx="auto"
          border="1px"
          borderColor="gray.300"
        >
          <Table size="sm">
            <Thead bg="gray.100">
              <Tr>
                <Th p={4}>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      currentItems?.filter(ticket => ticket?.isChecked !== true).length < 1
                    }
                    name="allChecked"
                    onChange={handleChange}
                  ></Checkbox>
                </Th>
                <Th>
                  <Text fontSize="xs" ml={1}>
                    TICKET NAME
                  </Text>
                </Th>
                <Th>
                  <Text textAlign="center" fontSize="xs">
                    STATUS
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign="center">
                    MESSAGES
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign="center">
                    FIRST SENT
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign="center">
                    LAST REPLY
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign="center">
                    ACTION
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody bg="white" w="full" >
              {currentItems?.length > 0 ? currentItems?.map(value => (
                <Tr key={value._id} mt={4}>
                  <Td p={4} mt={4}>
                    <Checkbox
                      isChecked={value.isChecked || false}
                      colorScheme="red"
                      name={value._id}
                      onChange={handleChange}
                    />
                  </Td>
                  <Td>
                    <Flex>
                      <Image
                        borderRadius="full"
                        boxSize="30px"
                        src={value.seller.profileImage || rowes}
                        ml="2px"
                      />
                      <Text
                        fontSize={15}
                        ml="10px"
                        fontWeight={600}
                        color="gray.600"
                      >
                        {value.issue}
                        <br />
                        <p style={{fontSize:"12px"}}>{value?.ticketId}</p>
                        <Text fontSize={12} color="gray.500">
                          {value.seller.name.contactPerson}
                        </Text>
                      </Text>
                    </Flex>
                  </Td>
                  <Td minW="80px">
                    <Box
                      bg={
                        value.status === "resolved"
                          ? "#B5D779"
                          : value.status === "Pending"
                          ? "#FFA3AC"
                          : value.status === "active"
                          ? "#B5D779"
                          : value.status === "Paused"
                          ? "#FDE68B"
                          : "orange"
                      }
                      borderRadius="20px"
                      p={2}
                    >
                      <Text textAlign="center" fontSize={12} fontWeight={600}>
                        {value.status}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Text
                      fontFamily="Commissioner"
                      fontStyle="normal"
                      fontWeight={600}
                      fontSize="14px"
                      lineHeight="21px"
                      textAlign="center"
                      color="#3B3538"
                    >
                      {value.messageCount}
                    </Text>
                  </Td>

                  <Td>
                    <Text
                      fontFamily="Commissioner"
                      fontStyle="normal"
                      fontWeight={600}
                      fontSize="14px"
                      lineHeight="21px"
                      textAlign="center"
                      color="#3B3538"
                    >
                      {moment(value.firstSent).format("lll")}
                    </Text>
                  </Td>

                  <Td>
                    <Text
                      fontFamily="Commissioner"
                      fontStyle="normal"
                      fontWeight={600}
                      fontSize="14px"
                      lineHeight="21px"
                      textAlign="center"
                      color="#3B3538"
                    >
                      {moment(value.updatedAt).format("lll")}
                    </Text>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="white"
                      color="#FF1A57"
                      border="1px"
                      borderColor="#FF1A57"
                      fontSize="xs"
                      borderRadius="lg"
                      px={5}
                      onClick={() => {
                        handleId(value._id,value.roomId);
                      }}
                    >
                     Reply
                    </Button>
                  </Td>
                </Tr>
              )) : (<Box w="full" >
                <Text textAlign="center">Tickets are not generated by seller yet.</Text>
              </Box>)}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

function TicketTable({ itemsPerPage, items, handleChange, handleId,totalCount,getTicketsData }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items);
    setPageCount(Math.ceil(totalCount / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
      let pageNo = event.selected;
      getTicketsData(pageNo);
    const newOffset = (event.selected * itemsPerPage) % totalCount;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items
        currentItems={currentItems}
        handleChange={handleChange}
        handleId={handleId}
        // totalCount={totalCount}
        // getTicketsData={getTicketsData}
      />
      <Box bg="white" w="98%" mx="auto">
        <ReactPaginate
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item me-auto"
          previousLinkClassName="page-link ms-auto bg-transparent"
          nextClassName="page-item ms-auto"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center shadow"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Box>
    </>
  );
}

export default TicketTable;
