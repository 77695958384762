import {Flex, Text} from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate} from 'react-router-dom';

const NavItem = ({navSize, title, icon, path}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  return (
      <Flex
      onClick={() => navigate(path)}
        mb={1}
        cursor="pointer"
        flexDirection="row"
        justifyContent={navSize === 'small' || window.innerWidth < 1024 && 'center'}
        w="100%"
        bg={pathname == path && "#FF1A57"}
        _hover={{
          bg: '#FF1A57',
          color: 'white',
     
        }}
        color={pathname === path ? 'white' : '#7B6F6F'}
        alignItems={'center'}
        p={3}>
        {/* <Image src={icon} h="20px" color={active ? '#FF1A57' : '#7B6F6F'} /> */}
        {icon}
        <Text
          display={navSize === 'small' || window.innerWidth < 1024 ? 'none' : 'flex'}
          color={pathname == path ? 'white' : '#7B6F6F'}
          _hover={{
            color:'white'
          }}
          fontSize="14px"
          ml={4}>
          {title}
        </Text>
      </Flex>
  );
};

export default NavItem;
