import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  dashboardData:null,
  graphData: null
};

const authSlice = createSlice({
  name: "authUser",
  initialState,

  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.user.token || action.payload.token;
    },
    socialLoginSuccess: (state, action) => {
      state.user = action.payload;
      state.token = action.payload.token;
    },
    setEmailforOtp: (state, action) => {
      state.emailForOtp = action.payload;
    },
    setForgetPasswordToken: (state, action) => {
      state.forgetPasswordToken = action.payload;
    },
    userProfile: (state, action) => {
      state.user = action.payload;
    },
    eventList: (state, action) => {
      state.events = action.payload;
    },
    getDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    getGraph: (state, action) => {
      state.graphData = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    }
  },
});

export const {
  loginSuccess,
  setEmailforOtp,
  setForgetPasswordToken,
  socialLoginSuccess,
  eventList,
  getDashboardData,
  logout,
  getGraph
} = authSlice.actions;

export default authSlice.reducer;