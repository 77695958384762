import React from 'react'

const SellerDatabase = (props:React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
    <path d="M16.6663 18.3333C17.1266 18.3333 17.4997 17.9602 17.4997 17.5C17.4997 17.0397 17.1266 16.6666 16.6663 16.6666C16.2061 16.6666 15.833 17.0397 15.833 17.5C15.833 17.9602 16.2061 18.3333 16.6663 18.3333Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.50033 18.3333C7.96056 18.3333 8.33366 17.9602 8.33366 17.5C8.33366 17.0397 7.96056 16.6666 7.50033 16.6666C7.04009 16.6666 6.66699 17.0397 6.66699 17.5C6.66699 17.9602 7.04009 18.3333 7.50033 18.3333Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 1H4.27273L6.46545 11.7117C6.54027 12.08 6.7452 12.4109 7.04436 12.6464C7.34351 12.8818 7.71784 13.0069 8.10182 12.9997H16.0545C16.4385 13.0069 16.8129 12.8818 17.112 12.6464C17.4112 12.4109 17.6161 12.08 17.6909 11.7117L19 4.9999H5.09091" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default SellerDatabase