import {
  Box,
  Text,
  Image,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import Layout from "../../components/Layout";
import { icon_18 } from "../../assets/icons";
import { changeSellersStatus, getAllSellerData } from "../../api/seller";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import PaginatedItems from "../../components/PaginatedComponent";
import useFetch from "../../customHooks/useFetch";

const SellerDatabase = () => {
  const { sellers } = useSelector((state) => state.sellers);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [ids, setIds] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      if (checked) {
        setIds(ids);
      } else {
        setIds([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      if (checked) {
        setIds([...ids, idss[0]]);
      } else {
        let idsss = ids.filter((value) => value !== name);
        setIds(idsss);
      }
    }
  };

  const { data, isLoading } = useFetch(getAllSellerData());
  useEffect(() => {
    if (data) {
      setUsers(data?.data?.sellers);
    }
  }, [data]);

  const handleId = (id) => {
    navigate(`/edit-seller/${id}`);
  };

  const handleChangeStatus = (status) => {
    const apiData = {
      ids,
      status,
    };
    if (status === "approved") {
      const selectedSellers = sellers.sellers.filter((s) =>
        ids.includes(s._id)
      );

      const sellersMissingPlan = selectedSellers.some(
        (s) => s.commission || s.subscriptionPlan
      );

      if (!sellersMissingPlan) {
        toast.error(
          "Please add a commission or subscription plan before approval."
        );
        return;
      }
    }
    dispatch(changeSellersStatus(apiData)).then((res) => {
      if (res.statusCode == 200) {
        const data = users.map((item) => {
          const item2 = ids.find((i2) => i2 == item._id);
          return item2 ? { ...item, accountStatus: status } : item;
        });
        setUsers(data);
        setIds([]);
        toast.success(res.message);
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  //sometimes it wrks but sometimes not

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    
    if (query === "") {
      setUsers(sellers?.sellers); // Reset to original list when query is empty
    } else {
      const updatedList = sellers.sellers.filter((item) => {
        return (
          item.name.businessName.toLowerCase().includes(query) ||
          item.accountStatus.toLowerCase().includes(query)
        );
      });
      setUsers(updatedList);
    }
  };
  
  const filterBy = (filtertype) => {
    const updatedList = sellers.sellers.filter(
      (item) => item.accountStatus === filtertype
    );
    setUsers(updatedList);
  };
  

  return (
    <Layout>
      {isLoading ? (
        <HStack w="full" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box display="flex" w={{xl:"98%",lg:"98%",md:"92%"}} mx={{lg:"auto",md:"auto"}}>
            <Box
              w="full"
              mx="auto"
              display="flex"
              justifyContent="space-between"
            >
              <Text color="#2E1A47" w="full" fontSize={32} fontWeight="600">
                Seller Database
              </Text>
              <Header />
            </Box>
          </Box>
          <Box w="full">
            <Box
              display="flex"
              flexDirection={["column", "row"]}
              w={{lg:"98%",md:"92%"}}
              mx={{lg:"auto",md:"auto"}}
              my={4}
              justifyContent="space-between"
            >
              <Box
                w={["100%", "32%"]}
                textAlign="center"
                boxShadow="lg"
                p="15px"
                rounded="lg"
                border="1px"
                bg="white"
                borderColor="white"
              >
                <Text fontWeight="bold" color="#FF1A57" fontSize="5xl">
                  {sellers?.activeSellers}
                </Text>
                <Text
                  textTransform="capitalize"
                  fontWeight="bold"
                  color="#2E1A47"
                  fontSize="lg"
                >
                  active sellers
                </Text>
                <Button
                  fontSize="xs"
                  mt="15px"
                  fontWeight="bold"
                  bg="white"
                  border="1px"
                  borderColor="#FF1A57"
                  onClick={() => filterBy("approved")}
                >
                  <Box p={1}>
                    <Image src={icon_18} />
                  </Box>
                  <Text color="#FF1A57">Filter by Selling</Text>
                </Button>
              </Box>

              <Box
                w={["100%", "32%"]}
                textAlign="center"
                boxShadow="lg"
                p="10px"
                rounded="lg"
                bg="white"
                border="1px"
                borderColor="white"
              >
                <Text fontWeight="bold" color="#FF1A57" fontSize="5xl">
                  {sellers?.totalSellers}
                </Text>
                <Text
                  textTransform="capitalize"
                  fontWeight="bold"
                  color="#2E1A47"
                  fontSize="2xl"
                >
                  total sellers
                </Text>
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="center"
                  p={5}
                >
                  <Text fontSize="xs" fontWeight="bold" color="green.600">
                    +{sellers?.thisMonth}
                  </Text>
                  <Text
                    color="#2E1A47"
                    fontSize="xs"
                    mt="15px"
                    fontWeight="bold"
                  >
                    {" "}
                    this month
                  </Text>
                </Stack>
              </Box>

              <Box
                w={["100%", "32%"]}
                textAlign="center"
                boxShadow="lg"
                p="10px"
                rounded="lg"
                bg="#FFEBEB"
                border="1px"
                borderColor="#FFEBEB"
              >
                <Text fontWeight="bold" color="#FF1A57" fontSize="5xl">
                  {sellers?.pendingSellers}
                </Text>
                <Text
                  textTransform="capitalize"
                  fontWeight="bold"
                  color="#2E1A47"
                  fontSize="2xl"
                >
                  pending sellers
                </Text>
                <Button
                  fontSize="xs"
                  mt="15px"
                  fontWeight="bold"
                  bg="#FFEBEB"
                  border="1px"
                  borderColor="#FF1A57"
                  onClick={() => filterBy("Pending")}
                >
                  <Box p={1}>
                    <Image src={icon_18} />
                  </Box>
                  <Text color="#FF1A57">Filter by Pending</Text>
                </Button>
              </Box>
            </Box>
            <Box flexDirection={["column", "row"]}>
              <Box
                w={{lg:"98%",md:"92%"}}
                mx={{lg:"auto",md:"auto"}}
                mt="15px"
                flexDirection={["column", "row"]}
              >
                <HStack w="full" display="flex" justifyContent="space-between">
                  <Box w="full">
                    <Text fontSize={14} color="gray.500" fontWeight={600}>
                      Search Sellers
                    </Text>
                    <InputGroup w={["100%"]}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FiSearch color="red" />}
                      />
                      <Input
                        type="tel"
                        bg="white"
                        shadow="md"
                        fontSize="sm"
                        placeholder="Search for seller name and status..."
                        onChange={handleSearch}
                      />
                    </InputGroup>
                  </Box>
                  <Box w={{lg:"100%",md:"80%"}}>
                    <Text fontSize={14} color="gray.500" fontWeight={600}>
                      Bulk actions (select offers)
                    </Text>
                    <Box
                      w="full"
                      mt={["2", "0"]}
                      justifyContent="space-between"
                      display="flex"
                      flexDirection={["column", "row"]}
                      gap={2}
                    >
                      <Button
                        w={["100%", "32%"]}
                        color="white"
                        fontSize="sm"
                        bg="#FF1A57"
                        shadow="lg"
                        disabled={ids?.length < 1 ? true : false}
                        onClick={() => handleChangeStatus("approved")}
                        isLoading={isLoading}
                      >
                        Approve Seller(s)
                      </Button>
                      <Button
                        disabled={ids?.length < 1 ? true : false}
                        w={["100%", "32%"]}
                        color="white"
                        bg="#FF1A57"
                        shadow="lg"
                        border="none"
                        fontSize="sm"
                        isLoading={isLoading}
                        onClick={() => handleChangeStatus("reject")}
                      >
                        Reject Seller(s)
                      </Button>
                      <Button
                        color="white"
                        bg="#FF1A57"
                        shadow="lg"
                        disabled={ids?.length < 1 ? true : false}
                        w={["100%", "32%"]}
                        border="none"
                        fontSize="sm"
                        isLoading={isLoading}
                        onClick={() => handleChangeStatus("suspend")}
                      >
                        Suspend Seller(s)
                      </Button>
                      <Button
                        color="white"
                        bg="#FF1A57"
                        shadow="lg"
                        disabled={ids?.length < 1 ? true : false}
                        w={["100%", "32%"]}
                        border="none"
                        fontSize="sm"
                        isLoading={isLoading}
                        onClick={() => handleChangeStatus("resume")}
                      >
                        Resume Seller(s)
                      </Button>
                    </Box>
                  </Box>
                </HStack>
              </Box>
            </Box>
            <Box mt="2%" w="full" mx="auto">
              <PaginatedItems
                itemsPerPage={5}
                items={users}
                handleChange={handleChange}
                handleId={handleId}
              />
            </Box>
          </Box>
        </>
      )}
    </Layout>
  );
};

export default SellerDatabase;
