import { configureStore } from '@reduxjs/toolkit';
// import thunk from 'redux-thunk';
import reducer from "./reducers";

// function logger({ getState }) {
//   return (next) => (action) => {
//     // Call the next dispatch method in the middleware chain.
//     const returnValue = next(action);

//     // This will likely be the action itself, unless
//     // a middleware further in chain changed it.
//     return returnValue;
//   };
// }


const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });
  
  export default store;