import { getAllSubAdmins } from "../redux/reducers/adminSlice";
import { getGraph } from "../redux/reducers/userSlice";
import api from "./index";

// Get All Sub Admins //
export const getSubAdmins = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        "/admin/subAdmin/get-subAdmins-listing?limit=100"
      );
      resolve(res.data);
      dispatch(getAllSubAdmins(res.data.data));
    } catch (err) {
      reject(err.data.message);
    }
  });
};

// Get Sub Admin by Id //
export const getSubAdminById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/subAdmin/get-admin-details/${id}`);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

// Update Admin Details //
export const updateSubAdmin = (id, apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/subAdmin/update-AdminDetails/${id}`, apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

// Update Signle Admin Status //
export const updateSingleSubAdminStatus = (id, apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/subAdmin/update-single-admin-status/${id}`, apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

// Delete Single Admin //
export const deleteSingleSubAdmin = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(`/admin/subAdmin/delete-admin/${id}`);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
// Update Admin Status //
export const updateSubAdminStatus = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/subAdmin/update-admins-status`, apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
// Add New Sub Admin //
export const addNewSubAdmin = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/admin/subAdmin/create-SubAdmin", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

//-------------Edit Admin Profile-------------//
export const editProfile = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/admin/update-profile", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

// get graph data //
export const getGraphData = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/get-dashboard-graph?type=${type}`);
      resolve(res.data);
      dispatch(getGraph(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllNotifications = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/notification/all`);
      resolve(res.data);
      // dispatch(getGraph(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

export const markAsSeenNotifications = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/notification/mark-as-seen`);
      resolve(res.data);
      // dispatch(getGraph(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

export const clearAllNotifications = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/notification/clear-all`);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

