import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import Layout from "../../components/Layout";
import { useForm } from "react-hook-form";

import { addNewCategory, addSubCategory } from "../../api/category";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers";
import axios from "axios";
import GetCategory from "../../components/GetCategory";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

const schema = yup
  .object({
    category: yup.string(),
  })

  .required('Category is required');

const token = getFromLocalStorage("token");

const AddNewCategory = () => {
  // const { showPassword, renderIcon } = useShowPassword();
  const [isLoading, setIsLoading] = useState(false);
  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const [showSubCategoryInput, setShowSubCategoryInput] = useState(false);
  const [category, setCategory] = useState("");
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  

  const handleAddCategory = (data) => {
    if (data.category === "") {
      toast.error("Category can not be empty!");
    } else if (data.category) {
      setIsLoading(true);
      const apiData = {
        category: data.category,
      };
      dispatch(addNewCategory(apiData))
        .then(async (res) => {
          setIsLoading(false);
          toast.success(res.message);
          if (res && data.subcategory) {
            await axios
              .post(
                process.env.REACT_APP_BASE_URL +
                  "/admin/category/create-subCategory",
                {
                  id: res.data.data._id,
                  category: data.subcategory,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                setIsLoading(false);
                toast.success(res.message);
                navigate("/categories");
              })
              .catch((err) => {
                setIsLoading(false);
                toast.error(err.message);
              });
          } else {
            setIsLoading(false);
            navigate("/categories");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          toast.error(err?.data?.message);
        });
    } else if (data.subcategory !== "") {
      setIsLoading(true);
      const apiData = {
        id: category._id,
        category: data.subcategory,
      };
      addSubCategory(apiData);
    }
  };

  const addSubCategory = async (apiData) => {

    await dispatch(addSubCategory(apiData))
      .then((res) => {
        setIsLoading(false);
        toast.success("Category & Sub-Category added");
        navigate("/categories");
      })
      .catch((err) => console.log(err));
  };

  const handleBackButton = () => {
    navigate("/categories");
  };

  const handleCategory = (e) => {
    if (e.target.value) {
      const category = JSON.parse(e.target.value);
      setCategory(category);
      setIsButtonDisabled(false);
    }
  };

  const handleShowCategory = () => {
    setIsCategoryDisabled((prev) => !prev);
    setShowCategoryInput((prev) => !prev);
    setIsButtonDisabled(false);
  };
  const handleSubCategory = () => {
    if (showCategoryInput || category) {
      setShowSubCategoryInput((prev) => !prev);
      setIsButtonDisabled(false);
    }
  };
  

  return (
    <>
      <Layout>
        <Box w="98%" h="100vh" pl={7} flexDirection={["column", "row"]}>
          <Box w="100%" mx="auto" display="flex" justifyContent="space-between">
            <Button variant="ghost" onClick={handleBackButton}>
              <Text
                color="#1E0F42"
                fontWeight="600"
                fontSize="20px"
                fontFamily="Rubik"
              >
                {"<"} Back
              </Text>
            </Button>
            <Header />
          </Box>
          <Box w={["100%", "40%"]}>
            <Box>
              <Text fontSize="34px" fontWeight="600" color="#1E0F42">
                Add new category
              </Text>
            </Box>
            <Box mt={6}>
              <Box w="full" mt="20px">
                <GetCategory
                  handleCategory={handleCategory}
                  disabled={isCategoryDisabled}
                />
              </Box>
              <HStack my={4} spacing={4}>
                <Button bg="#1E0F42" rounded="lg" isDisabled={category}>
                  <Text
                    color="white"
                    fontSize="14px"
                    fontWeight="600"
                    onClick={handleShowCategory}
                  >
                    + Add New Category
                  </Text>
                </Button>
                {category && (
                  <Button bg="#1E0F42" rounded="lg" mt={4}>
                    <Text
                      color="white"
                      fontSize="14px"
                      fontWeight="600"
                      onClick={handleSubCategory}
                    >
                      + Add Sub-Category
                    </Text>
                  </Button>
                )}
              </HStack>
              {showCategoryInput && (
                <>
                  <FormControl mt={5}>
                    <FormLabel>
                      <Text fontSize="14px" fontWeight="400" color="#6B6162">
                        Category Name
                      </Text>
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      border="1px"
                      borderColor="gray.300"
                      placeholder="Category name"
                      {...register("category")}
                    />
                  </FormControl>
                  <Button
                    bg="#1E0F42"
                    rounded="lg"
                    mt={4}
                    isDisabled={category}
                  >
                    <Text
                      color="white"
                      fontSize="14px"
                      fontWeight="600"
                      onClick={handleSubCategory}
                    >
                      + Add Sub-Category
                    </Text>
                  </Button>
                </>
              )}
            </Box>
            {showSubCategoryInput && (
              <Box mt={6}>
                <FormControl>
                  <FormLabel>
                    <Text fontSize="14px" fontWeight="400" color="#6B6162">
                      Sub-category
                    </Text>
                  </FormLabel>
                  <Input
                    placeholder="Sub-category"
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    border="1px"
                    borderColor="gray.300"
                    {...register("subcategory")}
                  />
                </FormControl>
              </Box>
            )}

            <Box mt={10} mb={10}>
              <Button
                bg="#FF1A57"
                isLoading={isLoading}
                isDisabled={isButtonDisabled}
                rounded="lg"
                w="full"
              >
                <Text
                  color="white"
                  fontSize="14px"
                  fontWeight="600"
                  onClick={handleSubmit(handleAddCategory)}
                >
                  Add
                </Text>
              </Button>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default AddNewCategory;