import { getTickets, getTicket } from "../redux/reducers/ticketSlice";
import api from "./index";

//**************** get all tickets **************** */
export const getAllTickets = (pageNo) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/supportCentre/get-support-tickets?pageNo=${pageNo}`);
      dispatch(getTickets(res.data.data));
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
//**************** get details of single ticket **************** */
export const getSingleTicketsDetails = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `/admin/supportCentre/support-ticket-details/${id}`
      );
      resolve(res.data);
      dispatch(getTicket(res.data.data));
    } catch (err) {
      reject(err.data.message);
    }
  });
};
//**************** change support ticket status **************** */
export const changeTicketStatus = (id, apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/supportCentre/change-status/${id}`, apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
//**************** bulk resolved get multiple ids  ticket status **************** */
export const statusChangeMultipleTickets = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        "admin/supportCentre/bulk-resloved-status",
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//**************** bulk resolved get multiple ids  ticket status **************** */
export const deleteBulkTickets =
  ([ids]) =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.put(`/admin/supportCentre/delete-bulk-tickets`);
        // console.log(res.data, "fdfjsosfs");
        // dispatch(getCategories(res.data.data.categories));
        resolve(res.data);
      } catch (err) {
        reject(err.data.message);
      }
    });
  };
//**************** send message for support center **************** */
export const sensMessage = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/supportCentre/send-message/${id}`);
      // console.log(res.data, "fdfjsosfs");
      // dispatch(getCategories(res.data.data.categories));
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
