import { Box, FormLabel, Select } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../api/category";

const GetCategory = ({ width, handleCategory, disabled }) => {
  const { categories } = useSelector((state) => state.offers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories())
      .then((res) => console.log(res.data))
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      <Box w={width}>

        <FormLabel color="#6B6162" fontSize="14px" fontWeight="400">
          Category
        </FormLabel>
        <Select
          placeholder="Select Category"
          isDisabled={disabled}
          fontSize="14px"
          color="#6B6162"
          bg="white"
          onChange={handleCategory}
          border="1px"
          borderColor="gray.400"
        >
          {categories?.map((value) => (
            <option key={value._id} value={JSON.stringify(value)}>
              {value.mainCategory}
            </option>
          ))}
        </Select>
      </Box>
    </>
  );
};

export default GetCategory;
