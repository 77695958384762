import * as yup from "yup";
import { category } from "../assets/icons";
export const offerValidator = yup
.object({
  offerName: yup.string().required("Offer Name is required"),
  quantity: yup
    .number()
    .typeError("Quantity must be a number")
    .required("field is required*"),
  startDate: yup.string().required("StartDate is required"),
  endDate: yup.string().required("EndDate is required"),
  cancellationPolicy: yup.string().required("Cancellation Policy is required"),
  validUntill: yup.number().required("cannot be decimal"),
  description: yup.string().required("offer Description is required"),
  ageRestrictedProduct: yup.string().required("Select any one"),
  terms:yup.string().required("Terms&Conditions are required"),
  popularity: yup.string().required("Popularity is required"),
  // : yup.sellerIdstring().required("Seller is required"),
  category: yup.string().required("Category is required"),
  

})
.required();