import {
  Box,
  Center,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { frame, updatedLogo } from "../../assets/icons";
import { FormControl, FormLabel, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { setPassword } from "../../api/user";
import { useLocation } from "react-router-dom";

const schema = yup
  .object({
    password: yup.string().required("Password is required").min(3).max(16),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Password confirm is required"),
  })
  .required();
const SetPassword = () => {
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfrimPassword, setShowConfrimPassword] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickOnNew = () => setShowNewPassword(!showNewPassword);
  const handleClickOnConfirm = () =>
    setShowConfrimPassword(!showConfrimPassword);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { state } = useLocation();
  const token = state.token;
  const handleNewPassword = (data) => {
    const apiData = {
      password: data.password,
      confirmPassword: data.confirmPassword,
    };

    dispatch(setPassword(token, apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          navigate("/login");
        }
      })
      .catch((err) => {
        toast.error(err.message || 'Something went wrong');
      });
  };

  const backToLogin = () => {
    navigate("/login");
  };
  return (
    <Box w="100vw" h="100vh">
      <Box
        w={"100%"}
        h="full"
        display="grid"
        placeItems="center"
        position={["relative", "absolute"]}
      >
      <Box>
      <Center>
          <Image src={updatedLogo} />
        </Center>
        <Text textAlign="center" fontSize="14px">
          Admin Dashboard V1.0
        </Text>
        <Box w="full" spacing={8}>
          <Text
            textAlign="center"
            my={4}
            fontSize="xl"
            fontWeight="bold"
            color="#3B3538"
          >
            Set Password
          </Text>
          <FormControl mt="30px"  mx="auto">
            <FormLabel color="#6B6162" fontSize="18px" mb={-0}>
              New Password
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Enter New Password"
                boxShadow="lg"
                variant="outline"
                type={showNewPassword ? "text" : "password"}
                {...register("password", { required: true })}
                _focusVisible={{
                  outline: "none",
                }}
              />
              <InputRightElement width="4.5rem">
                {/* {showNewPassword ? "Hide" : "Show"} */}
                {showNewPassword ? (
                   <ViewIcon
                   h="1.75rem"
                   size="lg"
                   color="#6B6162"
                   onClick={handleClickOnNew}
                 >
                 </ViewIcon>
                ) : (
                  <ViewOffIcon
                  h="1.75rem"
                  size="lg"
                  color="#6B6162"
                  onClick={handleClickOnNew}
                >
                </ViewOffIcon>
                )}
               
              </InputRightElement>
            </InputGroup>
            <Text my={1} color="red" fontSize="xs">
              {errors.password?.message}
            </Text>
            <FormLabel color="#6B6162" fontSize="18px" mt={5} mb={-0}>
              Confirm Password
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="Enter Confirm Password"
                boxShadow="lg"
                type={showConfrimPassword ? "text" : "password"}
                variant="outline"
                {...register("confirmPassword", { required: true })}
                _focusVisible={{
                  outline: "none",
                }}
              />
              <InputRightElement width="4.5rem">

              {showConfrimPassword ? (
                   <ViewIcon
                   h="1.75rem"
                   size="lg"
                   color="#6B6162"
                   onClick={handleClickOnConfirm}
                 >
                 </ViewIcon>
                ) : (
                  <ViewOffIcon
                  h="1.75rem"
                  size="lg"
                  color="#6B6162"
                  onClick={handleClickOnConfirm}
                >
                </ViewOffIcon>
                )}
              
              </InputRightElement>
            </InputGroup>
            <Text my={1} color="red" fontSize="xs">
              {errors.confirmPassword?.message}
            </Text>
          </FormControl>
          <Box w="full" mx="auto">
            <Button
              bg="primary"
              onClick={handleSubmit(handleNewPassword)}
              mt={6}
              w={"100%"}
              mx="auto"
              color="white"
              isLoading={isLoading}
            >
              Submit
            </Button>
            <Text
              my={2}
              fontSize="xs"
              cursor="pointer"
              textAlign="center"
              color="primary"
              onClick={backToLogin}
            >
              Back to Login
            </Text>
          </Box>
        </Box>
      </Box>
      </Box>
    </Box>
  );
};

export default SetPassword;
