
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Hide,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import { TABLE } from "./table";
import { SearchIcon } from "@chakra-ui/icons";
import Header from "../../components/Header";

const Index = () => {
  return (
    <Layout>
      <Box
        w="95%"
        mx="auto"
        display="flex"
        justifyContent="space-between"
        flexDirection={["column", "row"]}
      >
        <Box w={["100%", "50%"]}>
          <Text
            fontFamily="Rubik"
            color="#1E0F42"
            fontSize="34px"
            fontWeight="600"
            lineHeight="40px"
            letter="-4%"
          >
            Admin Database
          </Text>
        </Box>
        <Box
          display="flex"
          w={["100%", "17%"]}
          flexDirection={["column", "row"]}
          pos={["absolute", "static"]}
          top={"7"}
          right={["4"]}
        >
          <Header />
        </Box>
      </Box>
      <Box
        w="95%"
        mx="auto"
        display="flex"
        justifyContent="space-between"
        mt="15px"
        gap="2"
        flexDirection={["column","row"]}
      >
        <Box w={["100%","56%"]}>
          <FormLabel color="#6B6162" fontSize="14px" fontWeight="400">
            Search admins
          </FormLabel>
          <Box display="flex">
          <InputGroup w={["98%","100%"]} mt={["2","0"]}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="#FF1A57" />}
            />
            <Input
              type="tel"
              pl={12}
              _focus={{
                outline: "none",
                border: "none",
              }}
              _after={{
                outline: "none",
                border: "none",
              }}
              _active={{
                outline: "none",
              }}
              bg="white"
              borderRadius={5}
              borderColor="#B1ABA9"
              placeholder="Search for offer name, business, location, or status..."
            />
          </InputGroup>

          </Box>
        </Box>
        <Box
          w={["100%","15%"]}
          display="flex"
          justifyContent={"flex-end"}
          flexDirection="column"
        >
          <Button bg="#1E0F42" color="#FFFFFF" fontSize="14px" fontWeight="600">
            New Admin
          </Button>
          <inputIcon/>
        </Box>
        <Box
          w={["100%","20%"]}
          display="flex"
          justifyContent={"flex-end"}
          flexDirection="column"
          gap="2"
        >
          <Text color="#6B6162" fontSize="14px" fontWeight="400">
            Bulk actions(select admins)
          </Text>
          <Button bg="#CCC9C7" color="#978D8C" fontSize="14px" fontWeight="600">
            Disable Admins
          </Button>
        </Box>
      </Box>

      <Box w="95%" mx="auto" mt="15px">
        <TableContainer rounded="md">
          <Table size="md">
            <Thead bg="gray.100">
              <Tr>
                <Th ml={5}>
                  <Checkbox defaultChecked></Checkbox>
                </Th>
                <Th>
                  <Text fontSize="xs">ADMIN NAME</Text>
                </Th>
                <Th>
                  <Text fontSize="xs">STATUS</Text>
                </Th>
                <Th>
                  <Text fontSize="xs">EMAIL</Text>
                </Th>
                <Th>
                  <Text fontSize="xs">ACCOUNT CREATED</Text>
                </Th>
                <Th>
                  <Text fontSize="xs"></Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {TABLE.map((value, index) => (
                <Tr>
                  <Td>{value.checkbox}</Td>
                  <Td>
                    <Flex>
                      <Image
                        borderRadius="full"
                        boxSize="30px"
                        src={value.image}
                        alt="Dan Abramov"
                        ml="10px"
                      />
                      <Text fontSize="xs" ml="10px">
                        {value.name}
                        <br />
                        <Text fontSize="8px" color="gray.500">
                          {value.address}
                        </Text>
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Button
                      bg={value.status === "Active" ? "#A3CE5A" : "#D3D3D3"}
                      fontSize="xs"
                      h="25px"
                      borderRadius="20px"
                      color="black"
                    >
                      {value.status}
                    </Button>
                  </Td>
                  <Td>
                    <Text fontSize="xs" color="black">
                      {value.email}
                    </Text>
                  </Td>

                  <Td>
                    <Text fontSize="xs">
                      {value.time}
                      <br />
                      {value.date}
                    </Text>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="white"
                      color="#FF1A57"
                      border="1px"
                      borderColor="#FF1A57"
                      h="25px"
                      fontSize="xs"
                      borderRadius="md "
                      p={5}
                    >
                      {value.button}
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        boxShadow="base"
        mt="3%"
        display="flex"
        justifyContent="space-between"
        p="5px"
        mb="3%"
      >
        <Box ml={25}>
          <Text fontSize="sm" color="gray.500">
            Previous
          </Text>
        </Box>
        <Box >
          <Flex gap={5}>
            <Text fontSize="sm" color="gray.500">
              1
            </Text>
            <Text ml="10px" fontSize="sm" color="gray.500">
              2
            </Text>
            <Text ml="10px" fontSize="sm" color="gray.500">
              3
            </Text>
            <Text ml="10px" fontSize="sm" color="gray.500">
              _
            </Text>
            <Text ml="10px" fontSize="sm" color="gray.500">
              8
            </Text>
            <Text ml="10px" fontSize="sm" color="gray.500">
              9
            </Text>
            <Text ml="10px" fontSize="sm" color="gray.500">
              10
            </Text>
          </Flex>
        </Box>
        <Box>
          <Text fontSize="sm" color="gray.500" mr={25}>
            Next
          </Text>
        </Box>
      </Box>
    </Layout>
  );
};

export default Index;
