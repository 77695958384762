import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admin: {},
  subAdmins:[],
  notyCount:null
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getAdmins: (state, action) => {
      state.admin = action.payload;
    },
    getAllSubAdmins: (state, action) => {
      state.subAdmins = action.payload;
    },
    notificationCount:(state,action)=>{
      state.notyCount = action.payload
    },
    resetNotificationCount:(state) => {
      state.notyCount = null
    }
  },
});

export const { getAdmins, getAllSubAdmins,notificationCount,resetNotificationCount } = adminSlice.actions;

export default adminSlice.reducer;
