import {
  AspectRatio,
  Box,
  Button,
  Hide,
  HStack,
  Image,
  Input,
  Select,
  Stat,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import { buyer, user } from "../../assets/images";
import profilee from "../../assets/images/profilee.png";
import { icon_10 } from "../../assets/icons";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBuyerStatus,
  getBuyerById,
  getBuyers,
  updateBuyerDetails,
} from "../../api/buyer";
import { useForm } from "react-hook-form";
import { singleFileUpload } from "../../api/category";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { FaRegEdit } from "react-icons/fa";
import { checkStringPresence } from "../../helpers";
const schema = yup
  .object({
    name: yup.string().required("First Name is required"),
    dob: yup.string().required("Date of birth is required"),
    specialDiscount: yup.string().required("Special Discount is required"),
    email: yup
      .string()
      .email("Email must be in valid format")
      .required("Email is required"),
  })
  .required();

const EditBuyer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [profileImage, setProfileImage] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { buyers } = useSelector((state) => state.buyers);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const imageRef = useRef();
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (id) {
      getApiData();
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      let defaultValues = {
        name: data?.name || "Doe",
        dob: moment(data?.dob).format("YYYY-MM-DD"),
        email: data?.email || "demo@example.com",
        specialDiscount: data?.specialDiscount || "Student",
      };
      reset(defaultValues);
      setPreview(data?.profileImage);
      setProfileImage(data?.profileImage === "user.png" ? buyer : data?.profileImage);
    }
  }, [data, reset]);

  const getApiData = () => {
    setLoading(true);
    dispatch(getBuyerById(id))
      .then((res) => {
        setData(res.data.buyer);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const img = e.target.files[0];
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => setProfileImage(res.data.image))
        .catch((error) => console.log(error));
      setPreview(URL.createObjectURL(img));
    }
  };

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  const handleUpdateBuyer = (data) => {
    const apiData = {
      profileImage,
      name: data.name,
      email: data.email,
      dob: moment(data?.dob).format("DD/MM/YYYY"),
      specialDiscount: data.specialDiscount,
    };
    dispatch(updateBuyerDetails(id, apiData)).then((res) => {
      if (res.statusCode === 200) {
        toast.success("Buyer updated successfully");
        dispatch(getBuyers());
        navigate("/buyer-database");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleSuspendBuyer = () => {
    const apiData = {
      ids: [id],
      status: "suspend",
    };
    dispatch(changeBuyerStatus(apiData)).then((res) => {
      if (res.statusCode === 200) {
        dispatch(getBuyers());
        toast.success(res.message);
        navigate("/buyer-database");
      }
    });
  };

  const clickOnButton = () => {
    navigate("/buyer-database");
  };
  return (
    <Layout>
      <Box
        w={{lg:"98%",md:"96%"}}
        mx={{lg:"auto",md:"auto"}}
        display="flex"
        justifyContent="space-between"
        flexDirection={["column", "row"]}
      >
        <Box>
          <Text
            fontFamily="Rubik"
            color="#2E1A47"
            fontSize={32}
            fontWeight="600"
            lineHeight="40px"
            letter="-4%"
          >
            Edit Buyer
          </Text>
        </Box>

        <Box
          display="flex"
          w={["100%", "17%"]}
          flexDirection={["column", "row"]}
          pos={["absolute", "static"]}
          top={"7"}
          right={["4"]}
        >
          <Header />
        </Box>
      </Box>

      <Box flexDirection={["column", "row"]}>
        <Box
          w={{lg:"60%",md:"98%"}}
          mx={{lg:"auto",md:"auto"}}
          display="flex"
          mt="10px"
          ml={{lg:"2%"}}
          justifyContent="space-between"
          flexDirection={["column", "row"]}
          p={4}
          // alignItems="center"
        >
          <Box display="flex" alignItems="center" w={["100%", "60%"]} gap={2}>
            <Box
              display="flex"
              alignItems="center"
              position="relative"
              onClick={handleButtonClick}
            >
              <Image
                borderRadius="full"
                src=  {checkStringPresence(profileImage)?profilee:profileImage||profilee}
                w="120px"
                h="120px"
              />
              <Box flexDirection={["column", "row"]}>
                {/* <Image
                  position="absolute"
                  borderRadius="full"
                  src={icon_10}
                  w="20px"
                  h="20px"
                  bg="#1E0F42"
                  top={0}
                  right={4}
                /> */}

                <Box
                  pt={2}
                  position="absolute"
                  bg="#1E0F42"
                  top={-1}
                  right={2}
                  w="36px"
                  h="36px"
                  align="center"
                  rounded="full"
                  color="white"
                  onClick={handleButtonClick}
                >
                  <AspectRatio w="22px" h="22px" cursor="pointer">
                    <FaRegEdit size="sm" />
                  </AspectRatio>
                </Box>
                <Input
                  type="file"
                  display="none"
                  accept="image/png, image/gif, image/jpeg"
                  ref={imageRef}
                  onChange={handleChange}
                ></Input>
              </Box>
            </Box>

            <Box>
              <Text
                fontFamily="Rubik"
                fontStyle="normal"
                fontWeight="600"
                fontSize="34px"
                lineHeight="40px"
              >
                {data?.name}
              </Text>
              <Text
                fontStyle="normal"
                fontWeight="600"
                fontSize="23px"
                lineHeight="28px"
                color="#72992E"
              >
                {data?.accountStatus}
              </Text>
              <Text
                fontStyle="normal"
                fontWeight="400"
                fontSize="16px"
                lineHeight="16px"
                color="#3B3538"
                mt="3px"
              >
                {data?.email}
              </Text>
            </Box>
          </Box>
          <Box flexDirection={["column", "row"]}>
            <Text
              fontStyle="normal"
              fontFamily="Commissioner"
              fontWeight="400"
              fontSize="14px"
              lineHeight="16px"
              color="#6B6162"
            >
              Quick Actions
            </Text>
            {/* <Button
              w={["95%", "100%"]}
              bg="#1E0F42"
              color="white"
              fontSize="14px"
              borderRadius="5px"
              mt="10px"
              // w={["100%", "100%"]}
            >
              View Purchases({data?.purchases})
            </Button> */}
            <br />
            <Button
              w={["95%", "100%"]}
              bg="#FFA3AC"
              color="black"
              fontSize="14px"
              borderRadius="5px"
              mt="10px"
              onClick={handleSuspendBuyer}
            >
              Suspend Buyer
            </Button>
          </Box>
        </Box>
      </Box>

      <Box flexDirection={["column", "row"]}>
        <Box
          w={{lg:"60%",md:"94%"}}
          mx={{lg:"auto",md:"auto"}}
          display="flex"
          mt="10px"
          ml={{lg:"2%"}}
          boxShadow="2xl"
          justifyContent="space-between"
          p="30px"
          borderRadius="8px"
          bg="#FFFFFF"
          border="1px solid #E8E6E3"
          flexDirection={["column", "row"]}
          gap={5}
        >
          <Box textAlign="center">
            <Text fontWeight="700" fontSize="34px" color="#3B3538">
              {data?.purchases}
            </Text>
            <Text fontWeight="500" fontSize="12px" color="#3B3538">
              Purchases
            </Text>
          </Box>
          <Box textAlign="center">
            <Stat>
              <StatNumber fontWeight="700" fontSize="34px" color="#3B3538">
                £{data?.spend}
              </StatNumber>
            </Stat>
            <Text fontWeight="500" fontSize="12px" color="#3B3538">
              Total spend
            </Text>
          </Box>
          <Box textAlign="center">
            <Stat>
              <StatNumber fontWeight="700" fontSize="34px" color="#3B3538">
                £{data?.walletBalance}
              </StatNumber>
            </Stat>
            <Text fontWeight="500" fontSize="12px" color="#3B3538">
              Wallet Balance
            </Text>
          </Box>
        </Box>
      </Box>

      <Box w={{lg:"60%",md:"94% "}} ml="22px" mt="50px" mb="50px">
        <FormControl
          display="flex"
          flexdirection={["column", "row"]}
          justifyContent="space-between"
          flexDirection={["column", "row"]}
          gap={1}
        >
          <Box w={{lg:"66%",md:"66%"}}>
            <FormLabel color="#6B6162">Buyer Name</FormLabel>
            <Input
              type="text"
              placeholder="First Name"
              fontSize="14px"
              color="#4B4447"
              mt="-2"
              bg="white"
              shadow="2xl"
              {...register("name")}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.name?.message}
            </Text>
          </Box>

          <Box w={["90%", "32%"]}>
            <FormLabel color="#6B6162">Special Discounts</FormLabel>
            <Select
              type="text"
              placeholder="Select Discount"
              fontSize="14px"
              color="#4B4447"
              mt="-2"
              w="full"
              bg="white"
              shadow="2xl"
              {...register("specialDiscount")}
            >
              <option value="Student">Student</option>
              <option value="NHS">NHS</option>
            </Select>
            <Text my={1} color="red" fontSize="xs">
              {errors.specialDiscount?.message}
            </Text>
          </Box>
        </FormControl>
        <FormControl
          mt="20px"
          display="flex"
          direction="row"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
          gap={3}
        >
          <Box w={["90%", "66%"]}>
            <FormLabel color="#6B6162">Email</FormLabel>
            <Input
              type="email"
              placeholder="example@gmail.com"
              fontSize="14px"
              color="#4B4447"
              bg="white"
              shadow="2xl"
              mt="-2"
              {...register("email")}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.email?.message}
            </Text>
          </Box>
          <Box w={["90%", "32%"]}>
            <FormLabel color="#6B6162">Date of Birth</FormLabel>
            <Input
              type="date"
              fontSize="14px"
              color="#4B4447"
              bg="white"
              shadow="2xl"
              mt="-2"
              max={new Date().toISOString().split('T')[0]}
              {...register("dob")}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.dob?.message}
            </Text>
          </Box>
        </FormControl>
      </Box>
      <Box
        w={{lg:"60%",md:"94%"}}
        mx={{lg:"auto",md:"auto"}}
        ml={{lg:"22px"}}
        mt="20px"
        mb="50px"
        flexDirection={["column", "row"]}
      >
        <Button
          w="full"
          bg="#FF1A57"
          color="white"
          fontSize="14px"
          borderRadius="5px"
          mt="10px"
          onClick={handleSubmit(handleUpdateBuyer)}
        >
          Confirm Changes
        </Button>
      </Box>
    </Layout>
  );
};

export default EditBuyer;
