import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const useShowPassword = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function renderIcon() {
    return (
      <Box position="absolute" bottom={3} right={5}>
        {showPassword ? (
          <BsFillEyeFill
            className="icon"
            color="#999"
            onClick={handleShowPassword}
          />
        ) : (
          <BsFillEyeSlashFill
            className="icon"
            color="#999"
            onClick={handleShowPassword}
          />
        )}
      </Box>
    );
  }
  return { showPassword, handleShowPassword, renderIcon };
};

export default useShowPassword;
