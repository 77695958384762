import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Image,
  Hide,
  Input,
  InputLeftElement,
  InputGroup,
  Button,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  Accordion,
  Slider,
  HStack,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteCategory,
  getAllCategories,
  deleteSubCategory,
  getSubCategory,
} from "../../api/category";
import CategoryTable from "../../components/CategoryTable";
// import CategoryTable from "../../components/CategoryTable";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import AddCategoryModal from "../../components/modals/addCategoryModal";
import AddSubCategoryModal from "../../components/modals/addSubCategoryModal";
import useFetch from "../../customHooks/useFetch";

const Category = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.offers);

  const [category, setCategory] = useState([]);
  const [catData, setCatData] = useState({});
  const [ids, setIds] = useState([]);
  const [users, setUsers] = useState([]);

  const [subCatData, setSubCatData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const navigate = useNavigate();
  const { data, isLoading } = useFetch(getAllCategories());

  useEffect(() => {
    if (data) setUsers(data);
  }, [data]);

  const handleSearch = (event) => {
    const updatedList = data?.filter((item) =>
      JSON.stringify(item?.mainCategory)
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setUsers(updatedList);
  };

  const handleOpenSubCategoryModal = (data) => {
    setSubCatData(data);
    onOpen();
  };

  const handleEditSubCategory = (data) => {
    setSubCatData(data);
    onOpen();
  };

  const handleDeleteSubcategory = (id) => {
    dispatch(deleteSubCategory(id))
      .then((res) => {
        console.log(res, "res");
        if (res.statusCode === 200) {
          toast.success(res.message);
          // getCategorydata()
        }
      })
      .catch((err) => toast.error(err.messsage));
  };

  const handleOpenCategoryModal = (data) => {
    setCatData(data);
    onOpenAdd();
  };

  const handleDeleteCategory = (id) => {
    dispatch(deleteCategory(id))
      .then((res) => {
        if (res.statusCode === 200) {
          const data = users.filter((x) => x._id !== id);
          setUsers(data);
          toast.success(res.message);
        }
      })
      .catch((err) => toast.error(err.messsage));
  };

  const handleSpacebarPress = (event) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      alert("Spaces are not allowed in this field");
      event.preventDefault(); // Prevents the space character from being added to the input
    }
  };
  return (
    <>
      <Layout>
        {isLoading ? (
          <HStack w="full" h="100vh" justifyContent="center">
            <Spinner size="lg" color="red" />
          </HStack>
        ) : (
          <>
            <Box w={{ lg: "98%", md: "96%" }} mx={{ lg: "auto", md: "auto" }}>
              <Box
                w="100%"
                mx="auto"
                display="flex"
                justifyContent="space-between"
              >
                <Text
                  color="#1E0F42"
                  fontWeight="600"
                  fontSize="34px"
                  fontFamily="Rubik"
                >
                  Category
                </Text>
                <Header />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={["column", "row"]}
                gap={2}
                mt={4}
              >
                <Box w={{ lg: "76%", md: "76%" }}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<SearchIcon color="#FF1A57" />}
                    />
                    <Input
                      border="1px"
                      bg="white"
                      shadow="md"
                      borderColor="gray.300"
                      placeholder="Search for the category..."
                      fontSize="16px"
                      fontWeight="400"
                      color="#978D8C"
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Box>

                <Box w="full">
                  <Box w={{ lg: "25%", md: "40%" }}>
                    <Button
                      w="100%"
                      bg="white"
                      border="1px"
                      borderColor="#FF1A57"
                      _hover={{
                        bg: "none",
                      }}
                      shadow="md"
                      onClick={() =>
                        handleOpenCategoryModal({
                          type: "Add",
                          id: "",
                          category: "",
                        })
                      }
                    >
                      <Text fontSize="14px" fontWeight="600" color="#FF1A57">
                        Add New Category
                      </Text>
                    </Button>
                  </Box>
                  <AddCategoryModal
                    onOpen={onOpenAdd}
                    isOpen={isOpenAdd}
                    onClose={onCloseAdd}
                    data={catData}
                    setCategory={setUsers}
                    handleSpacebarPress={handleSpacebarPress}
                  />
                </Box>
              </Box>
            </Box>
            <AddSubCategoryModal
              data={subCatData}
              isOpen={isOpen}
              onClose={onClose}
              setCategory={setUsers}
              handleSpacebarPress={handleSpacebarPress}
            />

            <Box mt="2%" w="98%" mx="auto">
              <CategoryTable
                itemsPerPage={8}
                items={users}
                handleOpenCategoryModal={handleOpenCategoryModal}
                handleDeleteCategory={handleDeleteCategory}
                handleEditSubCategory={handleEditSubCategory}
                handleOpenSubCategoryModal={handleOpenSubCategoryModal}
                handleDeleteSubcategory={handleDeleteSubcategory}
              />
            </Box>
          </>
        )}
      </Layout>
    </>
  );
};

export default Category;
