import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Layout from "../../components/Layout";

import { buyer } from "../../assets/images";
import { icon_10, icon_13, icon_14 } from "../../assets/icons";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useEffect } from "react";
import Header from "../../components/Header";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addNewSubAdmin, getSubAdmins } from "../../api/admin";
import { singleFileUpload } from "../../api/category";
import dumyImage from "../../assets/images/profilee.png"

const schema = yup
  .object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    // role: yup.string().required("Role required"),
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
    // startingDate: yup.string().required("Starting date is required"),
    confirmPassword: yup.string().required("Confirm Password is required"),
  })
  .required();

const AddSubAdmin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [preview, setPreview] = useState(null);
  const imageRef = useRef();

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  const handleChange = (e) => {
    const img = e.target.files[0];
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => {
          setPreview(res.data.image);
        })
        .catch((error) => console.log(error));
    }
    setPreview(URL.createObjectURL(img));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddAdmin = (data) => {
    setLoading(true);
    const apiData = {
      profileImage: preview,
      firstName: data.firstName,
      lastName: data.lastName,
      // role: data.role,
      email: data.email,
      // startingDate: data.startingDate,
      password: data.password,
      confirmPassword: data.confirmPassword,
      permissions: {
        manageCategory: data.manageCategory,
        sellerDatabase: data.sellerDatabase,
        buyerDatabase: data.buyerDatabase,
        pushNotifications: data.pushNotifications,
        supportTickets: data.supportTickets,
        manageAdmins: data.manageAdmins,
        manageOffers: data.manageOffers,
      },
    };
    dispatch(addNewSubAdmin(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          setLoading(false);
          toast.success(res.message);
          naviagte("/manage-admin");
          dispatch(getSubAdmins());
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err);
      });
  };

  return (
    <Layout>
      <Stack w="98%" mx="auto">
        <Box
          w="100%"
          mx="auto"
          display="flex"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box w="95%" mx="auto">
            <Text
              fontFamily="Rubik"
              color="#2E1A47"
              fontSize="34px"
              fontWeight="600"
              lineHeight="40px"
              letter="-4%"
            >
              Add Admin
            </Text>
          </Box>
          <Box
            display="flex"
            w={["100%", "17%"]}
            flexDirection={["column", "row"]}
            pos={["absolute", "static"]}
            top={"7"}
            right={["4"]}
          >
            <Header />
          </Box>
        </Box>

        <Box
          w="60%"
          display="flex"
          mt="10px"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box gap={2}>
            <Box>
              <Box position="relative" onClick={handleButtonClick}>
                <Image
                  borderRadius="full"
                  src={preview || dumyImage}
                  alt="Dan Abramov"
                  w="120px"
                  h="120px"
                />
                <Image
                  borderRadius="full"
                  src={icon_10}
                  alt="Dan Abramov"
                  w="22px"
                  h="22px"
                  bg="#1E0F42"
                  position="absolute"
                  right={2}
                  top={2}
                  padding="4px"
                />
              </Box>
              <Input
                type="file"
                display="none"
                accept="image/png, image/gif, image/jpeg"
                ref={imageRef}
                onChange={handleChange}
              ></Input>
            </Box>
          </Box>
        </Box>
        <Box w={["98%", "60%"]} ml="22px" mt="50px" mb="50px">
          <FormControl
            display="flex"
            direction="row"
            justifyContent="space-between"
            flexDirection={["column", "row"]}
            gap={3}
          >
            <Box w={["90%", "50%"]}>
              <FormLabel color="#6B6162">First Name</FormLabel>
              <Input
                type="text"
                placeholder="First Name"
                bg="white"
                fontSize="14px"
                color="#4B4447"
                border="1px"
                borderColor={"grey"}
                {...register("firstName")}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.firstName?.message}
              </Text>
            </Box>
            <Box w={["90%", "50%"]}>
              <FormLabel color="#6B6162">Last Name</FormLabel>
              <Input
                type="text"
                placeholder="Last Name"
                fontSize="14px"
                bg="white"
                color="#4B4447"
                border="1px"
                borderColor={"grey"}
                {...register("lastName")}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.lastName?.message}
              </Text>
            </Box>
            {/* <Box w={["90%", "30%"]}>
              <FormLabel color="#6B6162">Role</FormLabel>
              <Select
                type="text"
                placeholder="Select Role"
                fontSize="14px"
                bg="white"
                color="#4B4447"
                border="1px"
                borderColor={"grey"}
                {...register("role")}
              >
                <option value="Student">Student</option>
                <option value="NHS">NHS</option>
                <option value="Corporate">Corporate</option>
              </Select>
              <Text my={1} color="red" fontSize="xs">
                {errors.role?.message}
              </Text>
            </Box> */}
          </FormControl>
          <FormControl
            mt="20px"
            display="flex"
            direction="row"
            justifyContent="space-between"
            flexDirection={["column", "row"]}
            gap={3}
          >
            <Box w={["90%", "50%"]}>
              <FormLabel color="#6B6162">Email</FormLabel>
              <Input
                type="email"
                bg="white"
                fontSize="14px"
                color="#4B4447"
                border="1px"
                borderColor={"grey"}
                {...register("email")}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.email?.message}
              </Text>
            </Box>
            {/* <Box w={["90%", "30%"]}>
              <FormLabel color="#6B6162">Starting Date</FormLabel>
              <Input
                type="date"
                bg="white"
                fontSize="14px"
                color="#4B4447"
                border="1px"
                borderColor={"grey"}
                {...register("startingDate")}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.startingDate?.message}
              </Text>
            </Box> */}
          </FormControl>
          <FormControl
            mt="20px"
            display="flex"
            direction="row"
            justifyContent="space-between"
            flexDirection={["column", "row"]}
            gap={3}
          >
            <Box w="full">
              <FormLabel color="#6B6162">Password</FormLabel>
              <Input
                type="password"
                bg="white"
                fontSize="14px"
                color="#4B4447"
                border="1px"
                borderColor={"grey"}
                {...register("password")}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.password?.message}
              </Text>
            </Box>
            <Box w="full">
              <FormLabel color="#6B6162">Confirm Password</FormLabel>
              <Input
                type="password"
                bg="white"
                fontSize="14px"
                color="#4B4447"
                border="1px"
                borderColor={"grey"}
                {...register("confirmPassword")}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.confirmPassword?.message}
              </Text>
            </Box>
          </FormControl>
        </Box>

        <Box
          w={["90%", "60%"]}
          ml="22px"
          boxShadow="2xl"
          bg="white"
          p="10px"
          borderRadius="md"
          flexDirection={["column", "row"]}
        >
          <Text fontSize="14px" fontWeight="400" color="#6B6162">
            Permissions
          </Text>

          <Flex mt="7px">
            <Checkbox colorScheme="red" {...register("manageCategory")}>
              <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
                Categories
              </Text>
            </Checkbox>
          </Flex>
          <Flex mt="7px">
            <Checkbox colorScheme="red" {...register("sellerDatabase")}>
              <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
                Seller database
              </Text>
            </Checkbox>
          </Flex>

          <Flex mt="7px">
            <Checkbox colorScheme="red" {...register("buyerDatabase")}>
              <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
                Buyer database
              </Text>
            </Checkbox>
          </Flex>

          <Flex mt="7px">
            <Checkbox colorScheme="red" {...register("pushNotifications")}>
              <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
                Push notifications
              </Text>
            </Checkbox>
          </Flex>
          <Flex mt="7px">
            <Checkbox colorScheme="red" {...register("supportTickets")}>
              <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
                Support tickets
              </Text>
            </Checkbox>
          </Flex>
          <Flex mt="7px">
            <Checkbox colorScheme="red" {...register("manageAdmins")}>
              <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
                Manage admins
              </Text>
            </Checkbox>
          </Flex>
          <Flex mt="7px">
            <Checkbox colorScheme="red" {...register("manageOffers")}>
              <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
                Manage Offers
              </Text>
            </Checkbox>
          </Flex>
        </Box>
        <Box
          w={["90%", "60%"]}
          ml="22px"
          mt="20px"
          mb="50px"
          flexDirection={["column", "row"]}
        >
          <Button
            w="full"
            bg="#FF1A57"
            isLoading={loading}
            color="white"
            fontSize="14px"
            borderRadius="5px"
            mt="10px"
            onClick={handleSubmit(handleAddAdmin)}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </Layout>
  );
};

export default AddSubAdmin;
