import "react-loading-skeleton/dist/skeleton.css";
import Navigation from "./routes/Navigation";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import store from "./redux/store";
import { Suspense, lazy, useEffect, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { onMessageListener, requestForToken } from "./firebase";
import { Helmet } from "react-helmet";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./api";

function App() {
  const toastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
  };

  useEffect(() => {
    const handleNotification = async () => {
      const payload = await onMessageListener();
      toast.success("New Notification Received")
      // setNotification(payload);
      // You can customize the notification handling here, e.g., show a notification using a library like react-toastify or any other notification library
    };

    handleNotification();
  }, []);

  return (
    <>
      <Helmet>
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBbd_Q8kvM9rmBakgRFXTgvjYpgVhNKB-M&libraries=places" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SkeletonTheme baseColor="grey" highlightColor="white">
            <Navigation />
            <Suspense
              fallback={
                <Box
                  w="full"
                  h="100vh"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner
                    thickness="8px"
                    speed="0.65s"
                    emptyColor="transparent"
                    color="#FF1A57"
                    size="xl"
                  />
                </Box>
              }
            >
              <ToastContainer {...toastOptions} />
            </Suspense>
        </SkeletonTheme>
      </Provider>
       </QueryClientProvider>
    </>
  );
}

export default App;
