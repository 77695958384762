import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sellers: [],
  seller: null
};

const sellerSlice = createSlice({
  name: "sellers",
  initialState,
  reducers: {
    getAllsellers: (state, action) => {
      state.sellers = action.payload;
    },
    getSeller: (state, action) => {
      state.seller = action.payload;
    },
  },
});

export const { getAllsellers, getSeller } = sellerSlice.actions;

export default sellerSlice.reducer;
