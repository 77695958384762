import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  Center,
  Textarea,
  VStack,
  StatNumber,
  Stat,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import Layout from "../../components/Layout";
import { icon_16, icon_17, offer } from "../../assets/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addNewCategory } from "../../api/category";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  deleteMultipleOffers,
  getAlloffers,
  redemption,
  updateOffersStatus,
} from "../../api/offer";
import Header from "../../components/Header";
import OffersTable from "../../components/OffersTable";
import useFetch from "../../customHooks/useFetch";
import RedemptionModal from "../../components/modals/RedemptionModal";

const ManageOffers = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [offerId, setOfferId] = useState("");
  const [days, setDays] = useState(0);
  const [ids, setIds] = useState([]);
  const [sellerIds, setSellerIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [totalOffers,setTotalOffers] = useState(null)
  const { offers } = useSelector((state) => state.offers);

  const { data, isLoading } = useFetch(getAlloffers(0));

  useEffect(() => {
    if (data) {
      setUsers(data?.data?.offersListing);
      setTotalOffers(data?.data?.paginationCount?.totalOffers)
    }
  }, [data]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      if (checked) {
        setIds(ids);
      } else {
        setIds([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );

      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      let sellerIdss = tempUser.filter((value) => value._id === name).map((item)=>item.sellerDetails._id);
      if (checked) {
        // console.log(idss,"=======")
        setIds([...ids, idss[0]]);
        setSellerIds([...sellerIds,sellerIdss[0]])
      } else {
        let idsss = ids.filter((value) => value !== name);
        // let sellerIdsss = sellerIds.filter((value)=> value ===  )
        setIds(idsss);
      }
    }
  };

 
    const handleId = (id, currentItems) => {
      navigate(`/edit-offers/${id}`);
    };
    
 
  const addRedemption = (id) => {
    setOfferId(id);
    onOpen1();
  };
  const handleRedemption = (id) => {
    const apiData = {
      days,
      id: offerId,
    };
    dispatch(redemption(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          onClose1();
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        onClose1();
      });
  };

  const handleAddNewCategory = () => {
    if (category !== "") {
      setLoading(true);
      const apiData = {
        category,
      };
      dispatch(addNewCategory(apiData))
        .then((res) => {
          setLoading(false);
          toast.success("Category added!");
          setCategory("");
          onClose();
        })
        .catch((errr) => {
          setLoading(false);
          toast.error(errr || "Something went wrong");
          console.log(errr);
        });
    } else {
      toast.error("Category name can not empty");
    }
  };

  const handleEditBtn = (uid) => {
    navigate(`/edit-offers/${uid}`);
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const updatedList = data?.data?.offersListing.filter((item) => {
      return (
        item.offerName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.status.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });

    if (query === "") {
      
      setUsers(offers?.offersListing);
    } else {
      setUsers(updatedList);
    }
  };

  const handleUpdateStatus = (status) => {
    const apiData = {
      ids,
      sellerIds,
      status,
    };
    dispatch(updateOffersStatus(apiData)).then((res) => {
      if (res.statusCode == 200) {
        const data = users.map((item) => {
          const item2 = ids.find((i2) => i2 == item._id);
          return item2 ? { ...item, status } : item;
        });
        setUsers(data);
        setIds([]);
        toast.success(res.message);
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  const handleDeleteOffers = () => {
    const apiData = {
      ids,
    };
    dispatch(deleteMultipleOffers(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          const data = users.filter((x) => ids.indexOf(x._id) < 0);
          setUsers(data);
          setIds([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      {isLoading ? (
        <HStack w="full" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box
            w={{ xl: "95%", lg: "95%", md: "92%" }}
            mx={{ xl: "auto", lg: "auto", md: "auto" }}
            display="flex"
            justifyContent="space-between"
          >
            <Text
              fontFamily="Rubik"
              color="#2E1A47"
              fontSize={["20px", "34px"]}
              fontWeight="600"
              lineHeight="40px"
              letter="-4%"
              w="full"
            >
              Manage Offers
            </Text>
            <Header />
          </Box>
          <Box
            w={{ lg: "95%", md: "92%" }}
            mx={{ lg: "auto", md: "auto" }}
            display="flex"
            justifyContent="space-between"
            my="20px"
            flexDirection={["column", "row"]}
          >
            <Box
              w={["100%", "50%", "24%"]}
              h={"186px"}
              textAlign="center"
              border="1px"
              borderColor="gray.200"
              rounded="lg"
              p={"1"}
              display={"flex"}
              flexDirection="column"
              justifyContent={"space-evenly"}
              bg="white"
              boxShadow="xl"
            >
              <Box>
                <Text
                  fontWeight="600"
                  fontFamily={"Rubik"}
                  fontStyle={"normal"}
                  fontSize="40px"
                  lineHeight={"56px"}
                  color="#B5D779"
                >
                  {offers?.paginationCount?.activeOffers}
                </Text>
                <Text
                  fontWeight="400"
                  fontFamily={"Commissioner"}
                  fontStyle={"normal"}
                  color="#1E0F42"
                  fontSize={{ lg: "28px", md: "20px" }}
                  lineHeight="34px"
                  rounded="lg"
                  mt="-15px"
                >
                  Active Offers
                </Text>
              </Box>
              <Box mt="-16px">
                <Text
                  fontWeight="600"
                  fontFamily={"Rubik"}
                  fontStyle={"normal"}
                  fontSize="40px"
                  lineHeight={"56px"}
                  color="#FF1A57"
                >
                  {offers?.paginationCount?.totalOffers}
                </Text>
                <Text
                  fontWeight="400"
                  fontFamily={"Commissioner"}
                  fontStyle={"normal"}
                  color="#1E0F42"
                  fontSize={{ lg: "28px", md: "20px" }}
                  lineHeight="34px"
                  mt="-15px"
                >
                  Total Offers
                </Text>
              </Box>
            </Box>

            <Box
              w={["100%", "50%", "24%"]}
              textAlign="center"
              h={"186px"}
              boxShadow="xl"
              rounded="lg"
              p={"1"}
              display={"flex"}
              flexDirection="column"
              justifyContent={"space-evenly"}
              border="1px"
              borderColor="gray.200"
            >
              <Box>
                <Text
                  fontWeight="600"
                  fontFamily={"Rubik"}
                  fontStyle={"normal"}
                  fontSize="48px"
                  lineHeight={"56px"}
                  color="#FF1A57"
                >
                  {offers?.paginationCount?.totalSoldOffer}
                </Text>
                <Stat>
                  <StatNumber
                    color="#7B6F6F"
                    fontSize="28px"
                    lineHeight={"34px"}
                    fontFamily={"Commissioner"}
                    fontWeight="500"
                    mt="-8px"
                    letterSpacing={"-0.02em"}
                  >
                    £ {offers?.paginationCount?.totalSoldOfferAmount}
                  </StatNumber>
                </Stat>
              </Box>
              <Box>
                <Text
                  fontWeight="400"
                  fontFamily={"Commissioner"}
                  fontStyle={"normal"}
                  color="#1E0F42"
                  fontSize={{ lg: "28px", md: "20px" }}
                  lineHeight="34px"
                >
                  Total Sold
                </Text>
              </Box>
            </Box>

            <Box
              w={["100%", "50%", "24%"]}
              textAlign="center"
              h={"186px"}
              boxShadow="2xl"
              rounded="lg"
              p={"1"}
              display={"flex"}
              flexDirection="column"
              justifyContent={"space-evenly"}
              border="1px"
              borderColor="gray.200"
            >
              <Box>
                <Text
                  fontWeight="600"
                  fontFamily={"Rubik"}
                  fontStyle={"normal"}
                  fontSize="48px"
                  lineHeight={"56px"}
                  color="#FF1A57"
                >
                  {offers?.paginationCount?.totalRedeemed}
                </Text>
                <Stat>
                  <StatNumber
                    color="#7B6F6F"
                    fontSize="28px"
                    lineHeight={"34px"}
                    fontFamily={"normal"}
                    fontWeight="500"
                    mt="-8px"
                    letterSpacing={"-0.02em"}
                  >
                    £ {offers?.paginationCount?.totalRedeemedAmount}
                  </StatNumber>
                </Stat>
              </Box>
              <Box>
                <Text
                  fontWeight="400"
                  fontFamily={"Commissioner"}
                  fontStyle={"normal"}
                  color="#1E0F42"
                  fontSize={{ lg: "28px", md: "20px" }}
                  lineHeight="34px"
                >
                  Total Redemeed
                </Text>
              </Box>
            </Box>

            <Box
              w={["100%", "50%", "24%"]}
              textAlign="center"
              boxShadow="2xl"
              p="1"
              rounded="lg"
              bg="#FFEBEB"
              display="flex"
              flexDirection={"column"}
              justifyContent="space-evenly"
              border="1px"
              borderColor="gray.200"
            >
              <Box>
                <Text
                  fontWeight="600"
                  fontFamily={"Rubik"}
                  fontStyle={"normal"}
                  fontSize="48px"
                  lineHeight={"56px"}
                  color="#FF1A57"
                >
                  {offers?.paginationCount?.suspendedOffers}
                </Text>

                <Text
                  fontWeight="400"
                  fontFamily={"Commissioner"}
                  fontStyle={"normal"}
                  color="#1E0F42"
                  fontSize={{ lg: "28px", md: "20px" }}
                  lineHeight="34px"
                  mt="-5px"
                >
                  Suspended Offers
                </Text>
              </Box>
              <Box>
                <Button
                  bg="transparent"
                  border="1px solid #FF1A57"
                  w="140px"
                  h="30px"
                  p="5px"
                >
                  <Flex>
                    <Image src={icon_16} />
                    <Text color="#FF1A57" fontSize="xs">
                      filter by suspended
                    </Text>
                  </Flex>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box flexDirection={["column", "row"]}>
            <Box
              w={{ lg: "95%", md: "92%" }}
              mx={{ lg: "auto", md: "auto" }}
              mt="15px"
              flexDirection={["column", "row"]}
            >
              <Box
                w={["98%", "80%"]}
                display="flex"
                justifyContent="space-between"
              >
                <Text fontSize="14px" fontWeight="400" color="#6B6162">
                  Search Offers
                </Text>
                <Text
                  fontSize="14px"
                  color="#6B6162"
                  fontWeight="400"
                  mr={["0", "7"]}
                >
                  Bulk actions(select offers)
                </Text>
              </Box>

              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                flexDirection={["column", "row"]}
                gap={2}
              >
                <InputGroup w={["30%", "full"]} mt={["2", "0"]}>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="#FF1A57" />}
                  />
                  <Input
                    type="tel"
                    bg="white"
                    shadow="lg"
                    pl={12}
                    _focus={{
                      outline: "none",
                      border: "none",
                    }}
                    _after={{
                      outline: "none",
                      border: "none",
                    }}
                    _active={{
                      outline: "none",
                    }}
                    borderRadius={5}
                    borderColor="#B1ABA9"
                    placeholder="Search for offer name and status..."
                    onChange={handleSearch}
                  />
                </InputGroup>
                <Box
                  w={["98%", "full"]}
                  justifyContent="space-between"
                  flexDirection={["column", "row"]}
                >
                  <Button
                    variant="solid"
                    w={["100%", "33%"]}
                    bg="secondary"
                    onClick={() => handleUpdateStatus("active")}
                  >
                    <Text fontSize="xs" color="white">
                      Resume offer(s)
                    </Text>
                  </Button>
                  <Button
                    // onClick={onOpen}
                    bg="primary"
                    variant="outline"
                    w={["100%", "33%"]}
                    ml={["0%", "2%"]}
                    border="none"
                    mt={["2", "0"]}
                    onClick={() => handleUpdateStatus("pending")}
                  >
                    <Text fontSize="xs" color="white">
                      Pause offer(s)
                    </Text>
                  </Button>
                  <Button
                    bg="primary"
                    variant="outline"
                    w={["100%", "30%"]}
                    ml={["0%", "2%"]}
                    border="none"
                    mt={["2", "0"]}
                    onClick={handleDeleteOffers}
                  >
                    <Text fontSize="xs" color="white">
                      Delete offer(s)
                    </Text>
                  </Button>
                  <Modal isOpen={isOpen} size="sm" onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        <Center>
                          <Image
                            src={icon_17}
                            bg="#FFEBEB"
                            p="20px"
                            borderRadius="full"
                          />
                        </Center>
                        <Text textAlign="center" mt="10px">
                          Are you sure you wish to suspend your selected offers?
                        </Text>
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody pb={6}>
                        <Text color="#7B6F6F" fontSize="14px" fontWeight="400">
                          This will pause the listing and prompt the seller to
                          take action to resume.
                        </Text>

                        <Textarea
                          placeholder="Enter message to seller(s)(optional)"
                          type="text"
                          mt="15px"
                          noOfLines={6}
                          htmlSize={40}
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        />
                        <VStack mt="20px">
                          <Input type="text" />
                          <Button
                            bg="#FF1A57"
                            w="full"
                            color="white"
                            fontSize="14px"
                          >
                            Add/Edit
                          </Button>
                        </VStack>
                      </ModalBody>

                      <ModalFooter>
                        <VStack w="full">
                          <Button
                            bg="#FF1A57"
                            w="full"
                            color="white"
                            fontSize="14px"
                            isLoading={isLoading}
                            onClick={handleAddNewCategory}
                          >
                            Suspend 1 Offer
                          </Button>
                          <Button
                            variant="ghost"
                            alignSelf="center"
                            onClick={onClose}
                          >
                            Cancel
                          </Button>
                        </VStack>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              </Box>
              <OffersTable
                itemsPerPage={10}
                items={users}
                setUsers={setUsers}
                handleChange={handleChange}
                handleId={handleId}
                addRedemption={addRedemption}
                totalOffers={totalOffers}
              />
            </Box>
            <RedemptionModal
              isOpen={isOpen1}
              onClose={onClose1}
              setDays={setDays}
              handleRedemption={handleRedemption}
            />
          </Box>
        </>
      )}
    </Layout>
  );
};

export default ManageOffers;
