import React from "react";
import { useSelector } from "react-redux";
import BuyerDatabase from "../IconComponent/BuyerDatabase";
import Category from "../IconComponent/Category";
import Home from "../IconComponent/Home";
import ManageAdmins from "../IconComponent/ManageAdmins";
import ManageOffers from "../IconComponent/ManageOffers";
import PushNotification from "../IconComponent/PushNotification";
import SellerDatabase from "../IconComponent/SellerDatabase";
import SupportTickets from "../IconComponent/SupportTickets";
import { getFromLocalStorage } from "../helpers";

const useMenu = () => {
  const user = getFromLocalStorage("userData");
  // const { admin } = useSelector((state) => state.admin);

  const MENU = [
    {
      img: <Home />,
      heading: "Home",
      Path: "/",
      permission: true,
    },
    {
      img: <ManageOffers />,
      heading: "Manage Offers",
      Path: "/manage-offers",
      permission: user?.permissions?.manageOffers,
    },
    {
      img: <SellerDatabase />,
      heading: "Seller Database",
      Path: "/seller-database",
      permission: user?.permissions?.sellerDatabase,
    },
    {
      img: <SellerDatabase />,
      heading: "Billing Report",
      Path: "/billing-report",
      permission: user?.userRole === "superAdmin" ? true: false,
    },
    {
      img: <BuyerDatabase />,
      heading: "Buyer Database",
      Path: "/buyer-database",
      permission: user?.permissions?.buyerDatabase,
    },
    {
      img: <PushNotification />,
      heading: "Push Notifications",
      Path: "/push-notification",
      permission: user?.permissions?.pushNotifications,
    },
    {
      img: <Category />,
      heading: "Categories",
      Path: "/categories",
      permission: user?.permissions?.manageCategory,
    },
    {
      img: <SupportTickets />,
      heading: "Support Tickets",
      Path: "/support-tickets",
      permission: user?.permissions?.supportTickets,
    },
    {
      img: <ManageAdmins />,
      heading: "Manage Admins",
      Path: "/manage-admin",
      permission: user?.permissions?.manageAdmins,
    },
  ];
  return { MENU };
};

export default useMenu;
