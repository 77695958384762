import { Box, Button, Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword, otpVerification } from "../../api/user";
import { useLocation, useNavigate } from "react-router-dom";
import { frame, updatedLogo } from "../../assets/icons";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";


const VerifyPassword = () => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (otp) => setOtp(otp);
  const { state } = useLocation();

  const [timeLeft, setTimeLeft] = useState(30);
  const [message, setMessage] = useState('No code? send again(30s)');

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setMessage('');
    }
  }, [timeLeft]);

  const resendOtp = ()=>{
    setTimeLeft(30)
    setMessage('No code? send again(30s)')
    dispatch(forgotPassword({email:state.email})).then((res)=>{
      toast.success(res.otp)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const handleSubmit = () => {
    const apiData = {
      email: state.email,
      otp,
    };
    dispatch(otpVerification(apiData))
      .then((res) => {
        toast.success(res.message);
        navigate("/setPassword", {
          state: {
            token: res.token,
          },
        });
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };
  return (
    <Box
      display={"grid"}
      placeItems={"center"}
      h={"100vh"}
      flexDirection={["column", "row"]}
    >
      <Box
        w={["100%", "60%"]}
        display={"grid"}
        placeItems={"center"}
        gap="40px"
        flexDirection={["column", "row"]}
      >
        <Box>
          <VStack>
            <Image src={updatedLogo} />
            <Text fontSize="xs" ml={""}>
              Admin Dashboard V1.0
            </Text>
          </VStack>
          <Text
            textAlign={"center"}
            fontSize="20px"
            color="#3B3538"
            fontWeight="600"
            mt="20px"
          >
            Verification code
          </Text>
          <Text
            textAlign={"center"}
            fontSize="16px"
            fontWeight="400"
            mt="20px"
            color="#3B3538"
          >
            Please enter the verification code sent on <br />({state?.email})
          </Text>
        </Box>
        <Box display="flex" w={["55%", "25%"]} justifyContent="space-between">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            placeholder="please enter otp"
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            inputStyle={{
              backgroundColor: "white",
              border: "1px solid gray",
              fontWeight: "600",
              fontSize: "34px",
              borderRadius: 4,
              width: 30,
              height: 50,
            }}
          />
        </Box>
        <Text color="#3B3538" fontSize="14px" fontWeight="400">
          {/* No code? send again(30s) */}
          {message.replace('(30s)', `(${timeLeft}s)`)}
          {timeLeft == 0 ? <span style={{
            cursor:"pointer",color:"#FF1A57"
          }} onClick={resendOtp} >Resend Code</span>:null}
        </Text>
        <Button
          color="white"
          bg="#FF1A57"
          w={["75%", "40%"]}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default VerifyPassword;
