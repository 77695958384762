import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Hide,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import * as yup from "yup";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { changeOldPassword } from "../../api/user";
import { icon_10 } from "../../assets/icons";
import { profile } from "../../assets/images";
import Layout from "../../components/Layout";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../components/Header";

const schema = yup
  .object({
    oldPassword: yup
      .string()
      .required("Password is required")
      .min(5, "Password must be 5 characters at minimum")
      .max(16),
    newPassword: yup
      .string()
      .required("Password is required")
      .min(5, "Password must be 5 characters at minimum")
      .max(16),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], "Passwords must match")
      .required("Password confirm is required"),
  })
  .required();

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [show, setShow] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfrimPassword, setShowConfrimPassword] = React.useState(false);
  const [changePassword, setChangePassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => setShow(!show);
  const handleClickOnNew = () => setShowNewPassword(!showNewPassword);
  const handleClickOnConfirm = () =>
    setShowConfrimPassword(!showConfrimPassword);

  const handlePassword = (e) => {
    setChangePassword(e.target.value);
  };
  // console.log(changePassword);

  const handleChangePassword = (data) => {
    setIsLoading(true);
    dispatch(changeOldPassword(data))
      .then((res) => {
        setIsLoading(false);
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <Layout boxSize={"borderBox"}>
        <Box w="95%" mx="auto" display="flex" justifyContent="space-between">
          <Button
            color="#2E1A47"
            fontWeight="600"
            FontSize="20px"
            variant="ghost"
          >
            {"< Back"}
          </Button>
          <Header />
        </Box>
        <Box w="95%" mx="auto">
          <Text
            fontFamily="Rubik"
            color="#2E1A47"
            fontSize={["30px", "34px"]}
            fontWeight={["550", "600"]}
            lineHeight="40px"
            letter="-4%"
          >
            Profile
          </Text>
        </Box>
        <Box
          w="60%"
          display="flex"
          mt="10px"
          ml="2%"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box
            display="flex"
            w={["160%", "80%"]}
            gap={2}
            flexDirection={["column", "row"]}
          >
            <Box>
              <Box position="relative">
                <Image
                  borderRadius="full"
                  src={profile}
                  alt="Dan Abramov"
                  w={["90px", "150px"]}
                  h={["90px", "150px"]}
                />
              </Box>
              <Box border={"1px"} borderColor={"green"}>
                <Image
                  borderRadius="full"
                  src={icon_10}
                  alt="Dan Abramov"
                  w={["35px", "48px"]}
                  h={["35px", "48px"]}
                  bg="#1E0F42"
                  position="absolute"
                  left={["88px", "370px"]}
                  top={["127px", "130px"]}
                  padding={3}
                />
              </Box>
            </Box>
            <Box mt="35px">
              <Text
                fontFamily="Rubik"
                fontStyle="normal"
                fontWeight="600"
                fontSize={["28px", "34px"]}
                lineHeight="40px"
                color="#3B3538"
              >
                Charles Surname
              </Text>
              <Text
                fontStyle="normal"
                fontWeight="600"
                fontSize="23px"
                lineHeight="28px"
                color="#FF1A57"
              >
                Super admin
              </Text>
            </Box>
          </Box>
        </Box>
        <Box w={["100%", "60%"]} ml={[2, 8]} mt={8}>
          <Text
            fontSize="34px"
            fontWeight="600"
            color="#1E0F42"
            fontFamily="Rubik"
          >
            Reset password
          </Text>
          <FormControl mt={5}>
            <FormLabel mb="0.5">
              <Text fontSize="14px" fontWeight="400" color="#6B6162">
                Old Password
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                w={["95%", "100%"]}
                border="1px"
                borderColor="gray.400"
                pr="4.5rem"
                name="oldPassword"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                {...register("oldPassword", { required: true })}
              />
              <InputRightElement width="4.5rem">
                <ViewIcon
                  h="1.75rem"
                  size="lg"
                  color="#6B6162"
                  onClick={handleClick}
                >
                  {show ? "Hide" : "Show"}
                </ViewIcon>
              </InputRightElement>
            </InputGroup>

            <FormLabel mt={8} mb="0.5">
              <Text fontSize="14px" fontWeight="400" color="#6B6162">
                New Password
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                w={["95%", "100%"]}
                border="1px"
                borderColor="gray.400"
                pr="4.5rem"
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter password"
                {...register("newPassword", { required: true })}
              />
              <InputRightElement width="4.5rem">
                <ViewIcon
                  h="1.75rem"
                  size="lg"
                  color="#6B6162"
                  onClick={handleClickOnNew}
                >
                  {showNewPassword ? "Hide" : "Show"}
                </ViewIcon>
              </InputRightElement>
            </InputGroup>

            <FormLabel mt={8} mb="0.5">
              <Text fontSize="14px" fontWeight="400" color="#6B6162">
                Confirm Password
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                w={["95%", "100%"]}
                border="1px"
                borderColor="gray.400"
                pr="4.5rem"
                name="confirmPassword"
                type={showConfrimPassword ? "text" : "password"}
                placeholder="Enter password"
                {...register("confirmPassword", { required: true })}
              />
              <InputRightElement width="4.5rem">
                <ViewIcon
                  h="1.75rem"
                  size="lg"
                  color="#6B6162"
                  onClick={handleClickOnConfirm}
                >
                  {showConfrimPassword ? "Hide" : "Show"}
                </ViewIcon>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Box mt={10} w={["95%", "100%"]}>
            <Button
              bg="#FF1A57"
              w="full"
              isLoading={isLoading}
              onClick={handleSubmit(handleChangePassword)}
            >
              <Text fontSize="14px" fontWeight="600" color="#FFFFFF">
                Confirm changes
              </Text>
            </Button>
            <Button w="full" mt={5} mb={5} border="1px" borderColor="#1E0F42">
              <Text fontSize="14px" fontWeight="600" color="#1E0F42">
                Discard changes
              </Text>
            </Button>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default ResetPassword;
