import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import SideBar from "./SideBar";

const Layout = ({ children, className }) => {
  const [navSize, changeNavSize] = useState("large");

  return (
    <Box display="flex" bg="#F8F8F8" h="auto" >
      <Box w={{sm:60, lg:230,md:"10%"}}   h="100vh" zIndex={99999}>
      <SideBar navSize={navSize} />
      </Box>
      <Box
        w={{md:"94%",xl:"85%"}} mx={{md:"auto",lg:"auto",xl:"auto"}}
        pt="30px"
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
