import { Checkbox } from "@chakra-ui/react";

export const TABLE = [
    {
      checkbox: <Checkbox defaultChecked></Checkbox>,
      name: "Discounted Sausage Rolls",
      address: "Row's Bkaery|3B2 Market",
      image: "https://bit.ly/dan-abramov",
      status: "Active",
      email:"nishtha@gmail.com",
      time: "13:21",
      date: "04/06/2021",
      button: "Edit",
    },
    {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Active",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Disabled",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Active",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Active",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Disabled",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Active",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Active",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Disabled",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Active",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Active",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
      {
        checkbox: <Checkbox defaultChecked></Checkbox>,
        name: "Discounted Sausage Rolls",
        address: "Row's Bkaery|3B2 Market",
        image: "https://bit.ly/dan-abramov",
        status: "Active",
        email:"nishtha@gmail.com",
        time: "13:21",
        date: "04/06/2021",
        button: "Edit",
      },
]