import React, { useEffect } from "react";
import {
  RouterProvider,
  Route,
  createRoutesFromElements,
  createBrowserRouter
} from "react-router-dom";
import Login from "../pages/Login";
import BuyerDatabase from "../pages/BuyerDatabase";
import AdminDatabse from "../pages/AdminDatabse";
import MainDashboard from "../pages/MainDashboard";
import SupportTicket from "../pages/SupportTicket";
import TicketsChat from "../pages/TicketsChat";
import EditSeller from "../pages/EditSeller";
import SellerDatabase from "../pages/SellerDatabase";
import Category from "../pages/Category";
import AddNewCategory from "../pages/AddNewCategory";
import EditCategory from "../pages/EditCategory";
import NewOffer from "../pages/NewOffer";
import ResetPassword from "../pages/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword";
import OtpVerification from "../pages/OtpVerification";
import SetPassword from "../pages/SetPassword";
import Profile from "../pages/Profile";
import PrivateRoutes from "./privateRoutes";
import NotFound from "../components/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { getFromLocalStorage } from "../helpers";
import { loginSuccess } from "../redux/reducers/userSlice";
import AddSeller from "../pages/AddSeller";
import EditAdmin from "../pages/EditAdmin";
import ManageAdmin from "../pages/ManageAdmin";
import AddSubAdmin from "../pages/AddSubAdmin";
import EditBuyer from "../pages/EditBuyer";
import ManageOffers from "../pages/ManageOffers";
import EditOffers from "../pages/EditOffers";
import BillingReport from "../pages/BillingReport/BillingReport";
import Notification from "../pages/PushNotification";
import { getAllNotifications } from "../api/admin";
import { notificationCount } from "../redux/reducers/adminSlice";

const Navigation = () => {
  // const Category = lazy(() => import('../pages/Category'));

  const dispatch = useDispatch();
  const token = getFromLocalStorage("token");
  const user = getFromLocalStorage("userData");
  const { admin } = useSelector((state) => state.admin);
  
  useEffect(() => {
    if (Object.keys(getFromLocalStorage("userData")).length) {
      dispatch(
        loginSuccess({
          token,
          user,
        })
      );
    }
  }, [token]);

  useEffect(()=>{
    if(token)
    dispatch(getAllNotifications()).then((res)=>{
      const count = res.data.notifications.filter((item)=>item.isSeen === false).length;
      dispatch(notificationCount(count))
    })
  },[token])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<MainDashboard />} />
          {user?.permissions?.buyerDatabase ? (
            <Route path="/buyer-database" element={<BuyerDatabase />} />
          ) : null}
          {user?.permissions?.sellerDatabase ? (
            <Route path="/seller-database" element={<SellerDatabase />} />
          ) : null}
          {user?.permissions?.manageAdmins ? (
            <Route path="/manage-admin" element={<ManageAdmin />} />
          ) : null}
          {user?.permissions?.manageOffers ? (
            <Route path="/manage-offers" element={<ManageOffers />} />
          ) : null}
          {user?.permissions?.supportTickets ? (
            <Route path="/support-tickets" element={<SupportTicket />} />
          ) : null}
          {user?.userRole === "superAdmin" ? (
            <Route path="/billing-report" element={<BillingReport />} />
          ) : null}

          <Route path="/admin-database" element={<AdminDatabse />} />
          <Route path="/edit-sub-admin/:id" element={<EditAdmin />} />
          <Route path="/edit-seller/:id" element={<EditSeller />} />
          <Route path="/edit-buyer/:id" element={<EditBuyer />} />
          <Route path="/edit-offers/:id" element={<EditOffers />} />
          <Route path="/add-sub-admin" element={<AddSubAdmin />} />
          <Route path="/add-seller" element={<AddSeller />} />
          <Route path="/categories" element={<Category />} />
          <Route path="/add-new-category" element={<AddNewCategory />} />
          <Route path="/edit-category/:id" element={<EditCategory />} />
          <Route path="/new-offer" element={<NewOffer />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/push-notification" element={<Notification />} />
          <Route path="/tickets-chat/:id" element={<TicketsChat />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/otpVerification" element={<OtpVerification />} />
        <Route path="/setPassword" element={<SetPassword />} />
        <Route path="/*" element={<NotFound />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};
export default Navigation;
