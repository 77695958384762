import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input
  } from "@chakra-ui/react";
  import { useRef } from "react";
export default function RedemptionModal({onClose,isOpen,setDays,handleRedemption}) {
   
  
    const initialRef = useRef(null)
    const finalRef = useRef(null)
    return (
      <>
       
  
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Redemption Days</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Days</FormLabel>
                <Input onChange={(e)=>setDays(e.target.value)} placeholder='Days' type="number" />
              </FormControl>
  
              
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' onClick={handleRedemption} mr={3}>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }