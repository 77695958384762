import {Box, Button, Image} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {notfound} from '../assets/images';
import Layout from './Layout';
import Header from './Header';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Header/>
 <Box w="full" h="100vh" display="grid" placeItems="center">
      

      
    </Box>
    </Layout>
   
  );
};

export default NotFound;
