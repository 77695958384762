import {
  Box,
  Text,
  Image,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { FiSearch } from "react-icons/fi";
import Layout from "../../components/Layout";
import { icon_18 } from "../../assets/icons";
import { changeSellersStatus, getAllSellerData } from "../../api/seller";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import PaginatedItems from "../../components/PaginatedComponent";
import { getSubAdmins, updateSubAdminStatus } from "../../api/admin";
import AdminTable from "../../components/adminTable";

const ManageAdmin = () => {
  const { subAdmins } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usersList = useRef([]);
  const [users, setUsers] = useState([]);
  const [ids, setIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (subAdmins?.adminListing?.length > 0) {
      usersList.current = subAdmins?.adminListing;
      setUsers(subAdmins?.adminListing);
    }
  }, [subAdmins]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      if (checked) {
        setIds(ids);
      } else {
        setIds([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );

      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      if (checked) {
        setIds([...ids, idss[0]]);
      } else {
        let idsss = ids.filter((value) => value !== name);
        setIds(idsss);
      }
    }
  };

  useEffect(() => {
    dispatch(getSubAdmins()).then((res) => {
      setUsers(res.data.adminListing);
      setIsLoading(false);
    });
  }, []);

  const handleId = (id) => {
    navigate(`/edit-sub-admin/${id}`);
  };

  const handleChangeStatus = (status) => {
    setIsLoading(true);
    const apiData = {
      ids,
      status,
    };
    dispatch(updateSubAdminStatus(apiData))
      .then((res) => {
        setIsLoading(false);
        if (res.statusCode === 200) {
          dispatch(getSubAdmins()).then((res) =>
            setUsers(res.data.adminListing)
          );
          toast.success("Admin's status updated successfully");
          setIds([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSearch = (event) => {
    const updatedList = usersList.current?.filter((item) =>
      JSON.stringify(item.name.firstName)
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setUsers(updatedList);
  };

  // const filterBy = (filtertype) => {
  //   const updatedList = users.filter(
  //     (item) => item.accountStatus === filtertype
  //   );
  //   if (updatedList?.length > 0) {
  //     setUsers(updatedList);
  //   } else {
  //     setUsers(sellers?.sellers);
  //   }
  // };

  return (
    <Layout>
      {isLoading ? (
        <HStack w="full" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box display="flex" w={{lg:"98%",md:"92%"}} mx={{lg:"auto",md:"auto"}}>
            <Box
              w="full"
              mx="auto"
              display="flex"
              justifyContent="space-between"
            >
              <Text color="#2E1A47" w="full" fontSize={32} fontWeight="600">
                Admin Database
              </Text>
              <Header />
            </Box>
          </Box>
          <Box w={{lg:"98%",md:"92%"}} mx={{lg:"auto",md:"auto"}}>
            <HStack
              w="full"
              display="flex"
              justifyContent="space-between"
              alignItems="self-end"
            >
              <Box w="full">
                <Text fontSize={16} color="gray.500" fontWeight={600}>
                  Search Admins
                </Text>
                <InputGroup w={["100%"]}>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FiSearch color="red" />}
                  />
                  <Input
                    type="tel"
                    bg="white"
                    shadow="md"
                    fontSize="sm"
                    placeholder="Search for admin name..."
                    onChange={handleSearch}
                  />
                </InputGroup>
              </Box>
              <HStack w="full" alignItems="flex-end" mt={4}>
                <Button
                  color="white"
                  bg="#1E0F42"
                  shadow="lg"
                  w={["100%", "32%"]}
                  border="none"
                  fontSize="sm"
                  onClick={() => navigate("/add-sub-admin")}
                >
                  New Admin
                </Button>
                <Stack>
                  <Text fontSize={14} color="gray.500">
                    Bulk actions (select admins)
                  </Text>
                  <Box
                    w="full"
                    mt={["2", "0"]}
                    justifyContent="space-between"
                    display="flex"
                    flexDirection={["column", "row"]}
                    gap={2}
                    alignItems="center"
                  >
                    <Button
                      color="white"
                      fontSize="sm"
                      bg="#FF1A57"
                      shadow="full"
                      w={240}
                      disabled={ids?.length < 1 ? true : false}
                      onClick={() => handleChangeStatus("disabled")}
                      isLoading={isLoading}
                    >
                      Disable Admin(s)
                    </Button>
                  </Box>
                </Stack>
              </HStack>
            </HStack>
            <Box mt="2%" w="full" mx="auto">
              <AdminTable
                itemsPerPage={10}
                items={users}
                handleChange={handleChange}
                handleId={handleId}
              />
            </Box>
          </Box>
        </>
      )}
    </Layout>
  );
};

export default ManageAdmin;
