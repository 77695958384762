import React from 'react'

const ManageOffers = (props:React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<path d="M2.84134 11.175L8.81634 17.15C8.97113 17.3049 9.15494 17.4279 9.35727 17.5117C9.5596 17.5956 9.77648 17.6388 9.99551 17.6388C10.2145 17.6388 10.4314 17.5956 10.6337 17.5117C10.8361 17.4279 11.0199 17.3049 11.1747 17.15L18.333 9.99996V1.66663H9.99967L2.84134 8.82496C2.53092 9.13723 2.35669 9.55965 2.35669 9.99996C2.35669 10.4403 2.53092 10.8627 2.84134 11.175V11.175Z" stroke="#7B6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.167 5.83337H14.1587" stroke="#7B6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default ManageOffers