import React from 'react'

const Category = (props:React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<path d="M4.16699 8.33341H5.83366C7.50033 8.33341 8.33366 7.50008 8.33366 5.83341V4.16675C8.33366 2.50008 7.50033 1.66675 5.83366 1.66675H4.16699C2.50033 1.66675 1.66699 2.50008 1.66699 4.16675V5.83341C1.66699 7.50008 2.50033 8.33341 4.16699 8.33341Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.167 8.33341H15.8337C17.5003 8.33341 18.3337 7.50008 18.3337 5.83341V4.16675C18.3337 2.50008 17.5003 1.66675 15.8337 1.66675H14.167C12.5003 1.66675 11.667 2.50008 11.667 4.16675V5.83341C11.667 7.50008 12.5003 8.33341 14.167 8.33341Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.167 18.3334H15.8337C17.5003 18.3334 18.3337 17.5001 18.3337 15.8334V14.1667C18.3337 12.5001 17.5003 11.6667 15.8337 11.6667H14.167C12.5003 11.6667 11.667 12.5001 11.667 14.1667V15.8334C11.667 17.5001 12.5003 18.3334 14.167 18.3334Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.16699 18.3334H5.83366C7.50033 18.3334 8.33366 17.5001 8.33366 15.8334V14.1667C8.33366 12.5001 7.50033 11.6667 5.83366 11.6667H4.16699C2.50033 11.6667 1.66699 12.5001 1.66699 14.1667V15.8334C1.66699 17.5001 2.50033 18.3334 4.16699 18.3334Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default Category