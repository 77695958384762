import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  addNewCategory,
  getAllCategories,
  updateCategory,
} from "../../api/category";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const schema = yup
  .object({
    category: yup.string().trim("Space not allowed").required("Category is required"),
  })
  .required();

const AddCategoryModal = ({
  isOpen,
  onClose,
  data,
  setCategory,
  handleSpacebarPress
}) => {
  const {id, category, type} = data;

  console.log(type);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  useEffect(() => {
    if (category && id) {
      reset({
        category: data.category,
      });
    } else {
      reset({
        category: "",
      });
    }
  }, [data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleAddCategory = (data) => {
    setIsLoading(true);
    if (type === "Add") {
      const apiData = {
        category: data.category,
      };
      dispatch(addNewCategory(apiData))
        .then((res) => {
          setIsLoading(false);
          if (res.statusCode === 200) {
            toast.success("Category added successfully");
            dispatch(getAllCategories())
            .then((res) => setCategory(res))
            .catch((err) => console.log(err));
            onClose();
            reset({
              category: "",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.data.message);
        });
    } else {
      const apiData = {
        categoryName: data.category,
      };
      dispatch(updateCategory(id, apiData))
        .then((res) => {
          setIsLoading(false);
          if (res.statusCode === 200) {
            dispatch(getAllCategories())
              .then((res) => {toast.success("Edit category successfully");setCategory(res);})
              .catch((err) => console.log(err));
              onClose();

          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast(err.message);
        });
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {data.type} Category </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text fontSize="14px" fontWeight="400" color="#6B6162">
                  Category Name
                </Text>
              </FormLabel>
              <Input
                placeholder="Category"
                fontSize="14px"
                fontWeight="400"
                color="#4B4447"
                border="1px"
                borderColor="gray.300"
                {...register("category")}
                onKeyDown={handleSpacebarPress}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.category?.message}
              </Text>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              bg="#FF1A57"
              color="white"
              mr={3}
              isLoading={isLoading}
              onClick={handleSubmit(handleAddCategory)}
            >
              {data.type === "Edit" ? "Save" : "Add"}
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                reset({
                  category: "",
                });
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
