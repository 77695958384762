import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Spinner,
  Stat,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import Layout from "../../components/Layout";
import { Switch } from "@chakra-ui/react";
import { icon_16 } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import Header from "../../components/Header";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeBuyerStatus, deleteBuyers, getBuyers } from "../../api/buyer";
import { useState } from "react";
import BuyerTable from "../../components/buyerTable";
import { toast } from "react-toastify";
import useFetch from "../../customHooks/useFetch";

const BuyerDatabase = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [ids, setIds] = useState([]);
  const { buyers } = useSelector((state) => state.buyers);
  const [totalCount,setTotalCount] = useState(null)

  const dispatch = useDispatch();

  const handleId = (id) => {
    navigate(`/edit-buyer/${id}`);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      if (checked) {
        setIds(ids);
      } else {
        setIds([]);
      }
    } else {
      let tempUser = users.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );

      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);
      setUsers(tempUser);
      if (checked) {
        setIds([...ids, idss[0]]);
      } else {
        let idsss = ids.filter((value) => value !== name);
        setIds(idsss);
      }
    }
  };


  const { data, isLoading } = useFetch(getBuyers(1));

 
  useEffect(() => {
    if (data) setUsers(data?.data?.Buyers);
    setTotalCount(data?.data?.totalBuyers)
  }, [data]);

  const handleEditBtn = () => {
    navigate("/manage-buyer");
  };

  const handleChangeStatus = (status) => {
    // setIsLoading((prev) => !prev);
    const apiData = {
      ids,
      status,
    };
    dispatch(changeBuyerStatus(apiData))
      .then((res) => {
        // setIsLoading((prev) => !prev);
        if (res.statusCode === 200) {
          const data = users.map((item) => {
            const item2 = ids.find((i2) => i2 == item._id);
            return item2 ? { ...item,accountStatus:status } : item;
          });
          console.log(data,"data")
          setUsers(data)
          toast.success(res.message);
          setIds([]);
        }
      })
      .catch((err) => {
        // setIsLoading((prev) => !prev);
        console.log(err);
      });
  };

  const handleDeletebuyers = () => {
    const apiData = {
      ids,
    };
    dispatch(deleteBuyers(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          const data = users.filter((x) => !ids.includes(x._id));
          setUsers(data);
          setIds([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    const buyersData = data?.data?.Buyers;
    const updatedList = buyersData?.filter((item) =>
      JSON.stringify(item?.name).toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUsers(updatedList);
  };



  return (
    <Layout>
      {isLoading ? (
        <HStack w="full" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box
            w={{lg:"96%",md:"92%"}}
            mx={{lg:"auto",md:"auto"}}
            display="flex"
            justifyContent="space-between"
            flexDirection={["column", "row"]}
          >
            <Box w={["100%", "70%"]}>
              <Text
                fontFamily="Rubik"
                color="#1E0F42"
                fontSize={32}
                fontWeight={600}
                lineHeight="40px"
                letter="-4%"
              >
                Buyer Database
              </Text>
            </Box>

            <Header />
          </Box>
          <Box
            display="flex"
            flexDirection={["column", "row"]}
            justifyContent="space-between"
            mt="20px"
            w={{lg:"98%",md:"92%"}}
            mx="auto"
          >
            <Box
              w={["100%", "32%"]}
              bg="white"
              textAlign="center"
              boxShadow="lg"
              p="10px"
              py={8}
              rounded="lg"
            >
              <Text fontWeight={600} color="#FF1A57" fontSize="48px">
                {buyers?.activeBuyers}
              </Text>
              <Text
                fontWeight={600}
                color="#1E0F42"
                fontSize="28px"
                lineHeight="34px"
                textTransform="capitalize"
              >
                active buyers
              </Text>
              <Box mt="5px">
                <Button
                  bg="transparent"
                  border="1px solid #FF1A57"
                  w="140px"
                  h="30px"
                >
                  <Flex>
                    <Image src={icon_16} />
                    <Text color="#FF1A57" fontSize="xs" ml="5px">
                      filter by active
                    </Text>
                  </Flex>
                </Button>
              </Box>
            </Box>

            <Box
              w={["100%", "32%"]}
              textAlign="center"
              boxShadow="lg"
              p="10px"
              py={8}
              bg="white"
              rounded="lg"
            >
              <Text fontWeight={600} color="#FF1A57" fontSize="48px">
                {buyers?.totalBuyers}
              </Text>
              <Text
                fontWeight={600}
                color="#1E0F42"
                fontSize="28px"
                lineHeight="34px"
                textTransform="capitalize"
              >
                total buyers
              </Text>
              <HStack justifyContent="center" my={2} alignItems="center">
                <Text
                  fontSize="sm"
                  color="green"
                  fontWeight="bold"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  {/* +{buyers?.thisMonth} */}
                </Text>
                <Text
                  fontSize="sm"
                  mt="15px"
                  color="#1E0F42"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  this month
                </Text>
              </HStack>
            </Box>
            <Box
              w={["100%", "32%"]}
              textAlign="center"
              boxShadow="lg"
              p="10px"
              py={8}
              bg="white"
              rounded="lg"
            >
              <Stat>
                <StatNumber fontWeight={600} color="#FF1A57" fontSize="48px">
                  {buyers?.totalSpend}
                </StatNumber>
              </Stat>
              <Text
                fontWeight={600}
                color="#1E0F42"
                fontSize="28px"
                lineHeight="34px"
              >
                Total Spend
              </Text>
              <Flex>
                <Text
                  color="#1E0F42"
                  fontSize="xs"
                  mt="15px"
                  ml="20px"
                  fontWeight="bold"
                >
                  This Month
                </Text>
                <Spacer />
                <Switch id="email-alerts" colorScheme="green" mt="15px" />
                <Spacer />
                <Text color="#1E0F42" fontSize="xs" mt="15px" fontWeight="bold">
                  Total Spend
                </Text>
                <Spacer />
              </Flex>
            </Box>
          </Box>

          <Box
            w={{lg:"96%",md:"92%"}}
            mx={{lg:"auto",md:"auto"}}
            display="flex"
            justifyContent="space-between"
            mt="15px"
            gap="2"
            flexDirection={["column", "row"]}
          >
            <Box w={["100%", "49%"]}>
              <FormLabel fontSize={14} color="gray.500" fontWeight={600}>
                Search buyers
              </FormLabel>
              <InputGroup w={["100%", "96%"]} mt={["2", "-4px"]}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="#FF1A57" />}
                />
                <Input
                  type="tel"
                  pl={12}
                  _focus={{
                    outline: "none",
                    border: "none",
                  }}
                  _after={{
                    outline: "none",
                    border: "none",
                  }}
                  _active={{
                    outline: "none",
                  }}
                  bg="white"
                  borderRadius={5}
                  borderColor="#B1ABA9"
                  placeholder="Search for buyer name and status...."
                  onChange={handleSearch}
                />
              </InputGroup>
            </Box>
             
             
            <Box
              w={{xl:"50%",lg:"32%",md:"62%"}}
              display="flex"
              justifyContent={"flex-end"}
              flexDirection="column"
              gap="2"
            >
              {/* <Text fontSize={14} color="gray.500" fontWeight={600}>
                Bulk actions(select buyers)
              </Text>
              <Button
                color="white"
                fontSize="sm"
                bg="#FF1A57"
                mt={["2", "-4px"]}
                disabled={ids?.length < 1 ? true : false}
                onClick={() => handleChangeStatus("approved")}
                fontWeight="500"
              >
                Approve buyer(s)
              </Button> */}
            </Box>
            <Box
              w={["100%", "62%","45%"]}
              display="flex"
              justifyContent={"flex-end"}
              flexDirection="column"
            >
              <Button
                color="white"
                fontSize="sm"
                bg="#FF1A57"
                disabled={ids?.length < 1 ? true : false}
                onClick={() => handleChangeStatus("suspended")}
                fontWeight={600}
              >
                Suspend buyer(s)
              </Button>
            </Box>
            <Box
              w={["100%", "52%"]}
              display="flex"
              justifyContent={"flex-end"}
              flexDirection="column"
            >
              <Button
                color="white"
                fontSize="sm"
                bg="#FF1A57"
                disabled={ids?.length < 1 ? true : false}
                onClick={handleDeletebuyers}
                fontWeight={600}
              >
                Delete buyer(s)
              </Button>
            </Box>
          </Box>

          <Box mt="2%" w="full">
            <BuyerTable
              itemsPerPage={8}
              items={users}
              handleChange={handleChange}
              handleId={handleId}
              totalCount={totalCount}
              getBuyers={getBuyers}
              setUsers = {setUsers}
            />
          </Box>
        </>
      )}
    </Layout>
  );
};

export default BuyerDatabase;
