import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import React, {useRef, useState} from 'react';
import Layout from '../../components/Layout';
import {buyer} from '../../assets/images';
import {icon_10} from '../../assets/icons';
import {FormControl, FormLabel} from '@chakra-ui/react';
import {useEffect} from 'react';
import Header from '../../components/Header';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteSingleSubAdmin,
  getSubAdminById,
  getSubAdmins,
  updateSingleSubAdminStatus,
  updateSubAdmin,
} from '../../api/admin';
import {singleFileUpload} from '../../api/category';
import moment from 'moment';
import {toast} from 'react-toastify';
import dumyImage from "../../assets/images/profilee.png"

const schema = yup
  .object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    // role: yup.string().required('Role required'),
    email: yup.string().email(),
    password: yup.string(),
    // startingDate: yup.string().required('Starting date is required'),
    confirmPassword:  yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match"),
  })
  .required();

const EditAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState('');
  const [preview, setPreview] = useState(null);
  const [isManageCategory, setIsManageCategory] = useState(false);
  const [isSellerDatabase, setIsSellerDatabase] = useState(false);
  const [isBuyerDatabase, setIsBuyerDatabase] = useState(false);
  const [isPushNotification, setIsPushNotification] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isManageAdmins, setIsManageAdmins] = useState(false);
  const [isManageOffers, setIsManageOffers] = useState(false);
  const navigate = useNavigate();

  const checkRef = useRef();
  const dispatch = useDispatch();
  const imageRef = useRef();

  const handleButtonClick = e => {
    imageRef.current.click();
  };

  const handleChange = e => {
    const img = e.target.files[0];
    if (img) {
      dispatch(singleFileUpload(img))
        .then(res => {
          setPreview(res.data.image);
        })
        .catch(error => console.log(error));
    }
    setPreview(URL.createObjectURL(img));
  };

  useEffect(() => {
    if (id) {
      dispatch(getSubAdminById(id)).then(res => {
        setAdmin(res.data.adminDetails);
        console.log(res);
      });
    }
  }, []);

  let defaultValues = {};
  useEffect(() => {
    if (admin) {
      defaultValues.firstName = admin?.name?.firstName;
      defaultValues.lastName = admin?.name?.lastName;
      defaultValues.role = admin?.role;
      defaultValues.email = admin?.email;
      defaultValues.startingDate = moment(admin?.startingDate).format(
        'YYYY-MM-DD',
      );
      reset({...defaultValues});
      setPreview(admin?.profileImage);
      setIsManageCategory(admin?.permissions.manageCategory)
      setIsBuyerDatabase(admin?.permissions?.buyerDatabase)
      setIsSellerDatabase(admin?.permissions?.sellerDatabase)
      setIsPushNotification(admin?.permissions?.pushNotifications)
      setIsSupportTickets(admin?.permissions?.supportTickets)
      setIsManageAdmins(admin?.permissions?.manageAdmins)
      setIsManageOffers(admin?.permissions?.manageOffers)
    }
  }, [admin]);


  const {id} = useParams();

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddAdmin = data => {
    setLoading(true);
    const apiData = {
      profileImage: preview,
      firstName: data.firstName,
      lastName: data.lastName,
      // role: data.role,
      email: data.email,
      // startingDate: data.startingDate,
      password: data.password,
      confirmPassword: data.confirmPassword,
      permissions: {
        manageCategory: isManageCategory,
        sellerDatabase: isSellerDatabase,
        buyerDatabase: isBuyerDatabase,
        pushNotifications: isPushNotification,
        supportTickets: isSupportTickets,
        manageAdmins: isManageAdmins,
        manageOffers: isManageOffers,
      },
    };
    dispatch(updateSubAdmin(id, apiData))
      .then(res => {
        if (res.statusCode === 200) {
          setLoading(false);
          // toast.success(res.message);
          toast.success("Admin updated successfully")
          navigate('/manage-admin');
          dispatch(getSubAdmins());
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handleDisableAdmin = () => {
    const apiData = {
      status: 'disabled',
    };
    dispatch(updateSingleSubAdminStatus(id, apiData))
      .then(res => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          navigate('/manage-admin');
          dispatch(getSubAdmins());
        }
      })
      .catch(err => {
        toast.error(err);
      });
  };
  const handleDeleteAdmin = () => {
    dispatch(deleteSingleSubAdmin(id))
      .then(res => {
        if (res.statusCode === 200) {
          toast.success("Admin deleted successfully");
          navigate('/manage-admin');
          dispatch(getSubAdmins());
        }
      })
      .catch(err => {
        toast.error(err);
      });
  };
const handleBackBtn = () => {
  navigate(-1)
}

  return (
    <Layout>
      <Box
        w={{lg:"98%",md:"96%"}}
        mx={{lg:"auto",md:""}}
        display="flex"
        justifyContent="space-between"
        flexDirection={['column', 'row']}>
        <Box w={{lg:"50%"}}>
          <Button variant="ghost" onClick={handleBackBtn}>
            <Text
              fontFamily="Rubik"
              color="#1E0F42"
              fontSize="20px"
              fontWeight="600"
              lineHeight="40px"
              letter="-4%">
              {'<'}Back
            </Text>
          </Button>
        </Box>
        <Box
          display="flex"
          w={['100%', '17%']}
          flexDirection={['column', 'row']}
          pos={['absolute', 'static']}
          top={'7'}
          right={['4']}>
          <Header />
        </Box>
      </Box>
      <Box w="95%" mx="auto">
        <Text
          fontFamily="Rubik"
          color="#2E1A47"
          fontSize="34px"
          fontWeight="600"
          lineHeight="40px"
          letter="-4%">
          Edit Admin
        </Text>
      </Box>
      <Box
        w={{lg:"60%",md:"94%"}}
        display="flex"
        mt="10px"
        ml="2%"
        justifyContent="space-between"
        flexDirection={['column', 'row']}>
        <Box gap={2} w="full" display="flex">
          <Box position="relative" onClick={handleButtonClick}>
            <Image
              borderRadius="full"
              src={preview || dumyImage}
              alt="Dan Abramov"
              w="120px"
              h="120px"
            />
            <Box  alt="Dan Abramov"
              w="22px"
              h="22px"
              bg="#1E0F42"
              position="absolute"
              right={2}
              top={2}
              padding="4px"
              borderRadius="full">
            <svg  viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M10.0833 3.66667H3.66659C3.18036 3.66667 2.71404 3.85983 2.37022 4.20364C2.02641 4.54746 1.83325 5.01377 1.83325 5.50001V18.3333C1.83325 18.8196 2.02641 19.2859 2.37022 19.6297C2.71404 19.9735 3.18036 20.1667 3.66659 20.1667H16.4999C16.9861 20.1667 17.4525 19.9735 17.7963 19.6297C18.1401 19.2859 18.3333 18.8196 18.3333 18.3333V11.9167" stroke="#F8F8F8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.9583 2.29167C17.3229 1.92699 17.8175 1.72212 18.3333 1.72212C18.849 1.72212 19.3436 1.92699 19.7083 2.29167C20.0729 2.65634 20.2778 3.15094 20.2778 3.66667C20.2778 4.18239 20.0729 4.67699 19.7083 5.04167L10.9999 13.75L7.33325 14.6667L8.24992 11L16.9583 2.29167Z" stroke="#F8F8F8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
</Box>
            {/* <Image
              borderRadius="full"
              src={icon_10}
              alt="Dan Abramov"
              w="22px"
              h="22px"
              bg="#1E0F42"
              position="absolute"
              right={2}
              top={2}
              padding="4px"
            /> */}
            
          </Box>
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg"
            ref={imageRef}
            onChange={handleChange}></Input>
          <Box mt="35px">
            <Text
              fontFamily="Rubik"
              fontStyle="normal"
              fontWeight="600"
              fontSize="34px"
              lineHeight="40px">
              {admin?.name?.firstName} {admin?.name?.lastName}
            </Text>
            <Text
              fontStyle="normal"
              fontWeight="600"
              fontSize="23px"
              lineHeight="28px"
              color="#FF1A57">
              Sales
            </Text>
            <Text
              fontStyle="normal"
              fontWeight="400"
              fontSize="16px"
              lineHeight="16px"
              color="#3B3538"
              mt="3px">
              {moment(admin?.createdAt).format('LL')}
            </Text>
          </Box>
        </Box>
        <Box flexDirection={['column', 'row']} w={['160%', '25%']} ml={2}>
          <Text
            fontStyle="normal"
            fontFamily="Commissioner"
            fontWeight="400"
            fontSize="14px"
            lineHeight="16px"
            color="#6B6162"
            mt="13px">
            Quick Actions
          </Text>
          <Button
            bg="#1E0F42"
            color="white"
            fontSize="14px"
            borderRadius="5px"
            mt="10px"
            w={['95%', '100%']}
            onClick={handleDisableAdmin}>
            Disable Admin
          </Button>
          <br />
          <Button
            w={['95%', '100%']}
            bg="#FFA3AC"
            color="black"
            fontSize="14px"
            borderRadius="5px"
            mt="10px"
            onClick={handleDeleteAdmin}>
            Delete Admin
          </Button>
        </Box>
      </Box>
      <Box w={{lg:"60%",md:"94%"}} ml={{lg:"22px"}} mt="50px" mb="50px" mx={{md:"auto"}}>
        <FormControl
          display="flex"
          direction="row"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          gap={3}>
          <Box w={['90%', '50%']}>
            <FormLabel color="#6B6162">First Name</FormLabel>
            <Input
              type="text"
              fontSize="14px"
              color="#4B4447"
              bg="white"
              shadow="md"
              {...register('firstName')}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.firstName?.message}
            </Text>
          </Box>
          <Box w={['90%', '50%']}>
            <FormLabel color="#6B6162">Last Name</FormLabel>
            <Input
              type="text"
              fontSize="14px"
              bg="white"
              color="#4B4447"
              shadow="md"
              {...register('lastName')}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.lastName?.message}
            </Text>
          </Box>
          {/* <Box w={['90%', '30%']}>
            <FormLabel color="#6B6162">Role</FormLabel>

            <Select
              type="text"
              placeholder="Select Type"
              fontSize="14px"
              color="#4B4447"
              bg="white"
              shadow="md"
              {...register('role')}>
              <option value="Student">Student</option>
              <option value="NHS">NHS</option>
              <option value="Corporate">Corporate</option>
            </Select>
            <Text my={1} color="red" fontSize="xs">
              {errors.role?.message}
            </Text>
          </Box> */}
        </FormControl>
        <FormControl
          mt="20px"
          display="flex"
          direction="row"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          gap={3}>
          <Box w={['90%', '50%']}>
            <FormLabel color="#6B6162">Email</FormLabel>
            <Input
              type="email"
              fontSize="14px"
              color="#4B4447"
              bg="white"
              shadow="md"
              {...register('email')}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.email?.message}
            </Text>
          </Box>
          {/* <Box w={['90%', '30%']}>
            <FormLabel color="#6B6162">Starting Date</FormLabel>
            <Input
              type="date"
              fontSize="14px"
              color="#4B4447"
              bg="white"
              shadow="md"
              {...register('startingDate')}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.startingDate?.message}
            </Text>
          </Box> */}
        </FormControl>
        <FormControl
          mt="20px"
          display="flex"
          direction="row"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          gap={3}>
          <Box w="full">
            <FormLabel color="#6B6162">Password</FormLabel>
            <Input
              type="password"
              bg="white"
              fontSize="14px"
              color="#4B4447"
              shadow="md"
              {...register('password')}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.password?.message}
            </Text>
          </Box>
          <Box w="full">
            <FormLabel color="#6B6162">Confirm Password</FormLabel>
            <Input
              type="password"
              fontSize="14px"
              color="#4B4447"
              bg="white"
              shadow="md"
              {...register('confirmPassword')}
            />
            <Text my={1} color="red" fontSize="xs">
              {errors.confirmPassword?.message}
            </Text>
          </Box>
        </FormControl>
      </Box>
      <Box
        w={{lg:"60%",md:"94%"}}
        ml="22px"
        boxShadow="md"
        bg="white"
        p="10px"
        borderRadius="md"
        flexDirection={['column', 'row']}>
        <Text fontSize="14px" fontWeight="400" color="#6B6162">
          Permissions
        </Text>

        <Flex mt="7px">
          <Checkbox colorScheme="red"  isChecked={isManageCategory} onChange={e => setIsManageCategory(e.target.checked)}>

            <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
               Categories
            </Text>
          </Checkbox>
        </Flex>
        <Flex mt="7px">
          <Checkbox colorScheme="red" isChecked={isSellerDatabase} onChange={e => setIsSellerDatabase(e.target.checked)}>

            <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
              Seller database
            </Text>
          </Checkbox>
        </Flex>

        <Flex mt="7px">
          <Checkbox colorScheme="red" isChecked={isBuyerDatabase} onChange={e => setIsBuyerDatabase(e.target.checked)}>

            <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
              Buyer database
            </Text>
          </Checkbox>
        </Flex>

        <Flex mt="7px">
          <Checkbox colorScheme="red" isChecked={isPushNotification} onChange={e => setIsPushNotification(e.target.checked)}>

            <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
              Push notifications
            </Text>
          </Checkbox>
        </Flex>
        <Flex mt="7px">
          <Checkbox colorScheme="red" isChecked={isSupportTickets} onChange={e => setIsSupportTickets(e.target.checked)}>

            <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
              Support tickets
            </Text>
          </Checkbox>
        </Flex>
        <Flex mt="7px">
          <Checkbox colorScheme="red" isChecked={isManageAdmins} onChange={e => setIsManageAdmins(e.target.checked)}>

            <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
              Manage admins
            </Text>
          </Checkbox>
        </Flex>
        <Flex mt="7px">
          <Checkbox colorScheme="red" isChecked={isManageOffers} onChange={e => setIsManageOffers(e.target.checked)}>

            <Text fontSize="14px" fontWeight="400" color="#3B3538" ml="7px">
              Manage offers
            </Text>
          </Checkbox>
        </Flex>
      </Box>
      <Box
        w={{lg:"60%",md:"94%"}}
        ml="22px"
        mt="20px"
        mb="50px"
        flexDirection={['column', 'row']}>
        <Button
          w="full"
          bg="#FF1A57"
          color="white"
          fontSize="14px"
          borderRadius="5px"
          mt="10px"
          shadow="md"
          isLoading={loading}
          onClick={handleSubmit(handleAddAdmin)}>
          Confirm Changes
        </Button>
      </Box>
    </Layout>
  );
};

export default EditAdmin;
