import {
  Box,
  Text,
  Image,
  AspectRatio,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Select,
  Textarea,
  HStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import { placeholder, user,mapin,marker } from "../../assets/images";
import {  sellericon } from "../../assets/icons";
import { Form, useNavigate } from "react-router-dom";
import {
  addNewSeller,
  getAllSellerData,
  uploadMultipleImage,
} from "../../api/seller";
import { useForm,Controller  } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories, getSubCategory, singleFileUpload } from "../../api/category";
import { toast } from "react-toastify";
import { GrAddCircle, GrFormClose } from "react-icons/gr";
import { yupResolver } from "@hookform/resolvers/yup";
import GoogleMapReact from "google-map-react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import * as yup from "yup";
import { FaMapMarkerAlt } from "react-icons/fa";

const schema = yup
  .object({
    businessName: yup.string().required("Business name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email is not valid!"),
    // bio: yup.string().required("About is required"),
    primaryContact: yup.string().required("Primary Contact is required"),
    phone: yup
      .string()
      .min(10, "Enter valid Number")
      .max(10, "Enter valid Number"),
    password: yup
      .string()
      .required("Password is required")
      .min(8)
      .max(16)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

  const Marker = ({  }) => (
    <div
      style={{
        color: 'white',
        // background: 'red',
        padding: '10px 15px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        fontSize:"20px",
        transform: 'translate(-50%, -50%)'
      }}
    >
      <img src={marker} />
    </div>
  );

const AddSeller = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control 
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const imageRef = useRef();
  const galleryRef = useRef();
  const galleryRefOne = useRef();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [fileObj, setFileObj] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const { categories } = useSelector((state) => state.offers);
  const { subCategory } = useSelector((state) => state.offers);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [price,setPrice] = useState(null)
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [userPackage, setUserPackage] = useState("");
  const [isCommission, SetIsCommission] = useState(false);
  const [coordinates, setCoordinates] = useState({});

  const [location, setLocation] = useState({
    lat: 29.6123401,
    lng: 78.3441643,
  });
  const [zoom, setZoom] = useState(11);

  const [value, setValue] = useState(null);

  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [error, setError] = useState(null);
  const [subCatId,setSubCatId] = useState(null)

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setValue({
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         });
  //       },
  //       (err) => {
  //         setError(err.message);
  //       }
  //     );
  //   } else {
  //     setError('Geolocation is not supported by this browser.');
  //   }
  // }, []);

  useEffect(() => {
    if (value) {
      geocodeByAddress(value.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setLocation({ lat, lng });
          setZoom(11);
        });
    }
  }, [value]);

  useEffect(() => {
    dispatch(getAllCategories())
      .then((res) => console.log(res.data))
      .catch((error) => console.log(error));
  }, []);
  
  const getSubCategoryId = (id) => {
    setSubCatId(id)
  }


  useEffect(()=>{
    if(categories){
    let newSubCat = categories[0]?.subCategory?.filter((item)=>item._id == subCatId)
    }
  },[categories,subCatId])


  useEffect (() => {
  dispatch(getSubCategory(subCatId))
  .then((res) => {
    toast.success(res?.message)
     console.log(res,"resss sub category")})
  .catch((err) => console.log(err?.message))
  },[subCatId])

  useEffect(()=>{
    if(subscriptionPlan ==="Discovery_Plan" && userPackage === "monthly"){
      setPrice("79.99")
    }else if(subscriptionPlan ==="Discovery_Plan" && userPackage === "yearly"){
      setPrice("159.98")
    }else if(subscriptionPlan ==="Discovery_Plus_Plan" && userPackage === "monthly"){
      setPrice("99.99")
    }else if(subscriptionPlan ==="Discovery_Plus_Plan" && userPackage === "yearly"){
      setPrice("199.98")
    }else if(subscriptionPlan ==="Discovery Pro" && userPackage === "monthly" || userPackage === "yearly" ){
      setPrice("")
    }
  },[subscriptionPlan,userPackage])


  const handleChange = (e) => {
    const img = e.target.files[0];
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => setProfileImage(res.data.image))
        .catch((error) => console.log(error));
    }
    setPreview(URL.createObjectURL(img));
  };

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  const handleGallery = (e) => {
    const img = e.target.files;
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }

    setGallery(fileArray);
    dispatch(uploadMultipleImage(img))
      .then((res) => {
        if (res.statusCode === 200) {
          const image = res.data.image;
          const newGallery = image.map((item) => item.imageUrl);
          setGallery(newGallery);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isSubscribed) {
      reset({
        amount: 0,
        subscriptionPlan: "",
      });
    }
  }, [isSubscribed]);

  const handleSubscription = (e) => {
    if (e.target.checked) {
      setIsSubscribed(true);

    } else {
      setIsSubscribed(false);
      reset({
        amount: 0,
        subscriptionPlan: "",
      });
    }
  };

  const handleCommission = (e) => {
    if (e.target.checked) {
      SetIsCommission(true);
    } else {
      SetIsCommission(false);
      reset({
        amount: 0,
        subscriptionPlan: "",
      });
    }
  };

  const addSeller = (data) => {
    console.log(data, "Data");
    const category = JSON.parse(data.category);
    const subCategory = JSON.parse(data.subCategory);
    const apiData = {
      businessName: data.businessName,
      amount: data.amount,
      subscriptionPlan: subscriptionPlan|| null,
      primaryContact: data.primaryContact,
      isSeller:false,
      email: data.email,
      commission: data.commission || null,
      address: value.label,
      package:userPackage,
      city: value?.value?.structured_formatting?.main_text,
      phone: data.phone,
      // alternateNumber: data.alternateNumber,
      gallery,
      // bio: data.bio,
      password: data.password,
      transactionFee: data.transactionFee,
      confirmPassword: data.confirmPassword,
      profileImage,
      latitude: location.lat,
      longitude: location.lng,
      category: {
        id: category?._id,
        name: category?.mainCategory,
      },
      subCategory : {
        id  : subCategory?._id,
        name : subCategory?.name
      }
    };

   console.log(apiData, "apiData");
    dispatch(addNewSeller(apiData))
      .then((res) => {
        if (res.statusCode) {
          toast.success(res.message);
          navigate("/seller-database");
          dispatch(getAllSellerData());
        }
      })
      .catch((err) => toast.error(err));
  };

  const deleteImage = (img) => {
    const newImgURL = gallery.filter((image) => image !== img);
    setGallery(newImgURL);
  };

  // useEffect(() => {
  //   if (value) {
  //     geocodeByAddress(value.label)
  //       .then((results) => getLatLng(results[0]))
  //       .then(({ lat, lng }) =>
  //         setLocation({
  //           lat,
  //           lng,
  //         })
  //       );
  //   }
  // }, [value]);

  // navigator.geolocation.getCurrentPosition((position) => {
  //   const cordinates = {
  //     latitude: position.coords.latitude,
  //     longitude: position.coords.longitude,
  //   };
  //   setCoordinates(cordinates);
  // });
  const CustomDropdownIndicator = () => {
    return <FaMapMarkerAlt
    onClick={fetchCurrentLocation}
    style={{ color: 'grey', marginRight: '10px',cursor: 'pointer' }} />;
  };
  // console.log(defaultProps,"props")
  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Use reverse geocoding to get the address from the coordinates
          const geocoder = new window.google.maps.Geocoder();
          const latlng = { lat: latitude, lng: longitude };
  
          geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                setValue({
                  label: results[0].formatted_address,
                  value: results[0].place_id,
                });
              } else {
                console.log('No results found');
              }
            } else {
              console.log('Geocoder failed due to: ' + status);
            }
          });
        },
        (error) => {
          console.log('Error fetching the current location: ', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (value) {
      geocodeByAddress(value.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          setLocation({
            lat,
            lng,
          })
        );
    }
  }, [value]);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const cordinates = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      setCoordinates(cordinates);
    });
  }, []);
  return (
    <Layout>
      <Box w="100%" flexDirection={["column", "row"]}>
        <Box
          w="98%"
          mx="auto"
          display="flex"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box w={["100%", "50%"]} mx={{lg:"auto",md:"auto"}}>
            <Text
              fontFamily="Rubik"
              color="#1E0F42"
              fontSize={32}
              fontWeight={600}
              lineHeight="40px"
              letter="-4%"
            >
              Add Seller
            </Text>
          </Box>

          <Header />
        </Box>

        <Box w={["100%", "85%"]} bg flexDirection={["column", "row"]}>
          <Box
            w="95%"
            h="100%"
            mx="auto"
            direction="row"
            justifyContent="space-between"
            flexDirection={["column", "row"]}
          >
            <HStack
              w={["100%", "75%"]}
              justifyContent="space-between"
              alignItems="center"
              flexDirection={["column", "row"]}
            >
              <Box display="flex" alignItems="center">
                <Box position="relative" bg="">
                  <AspectRatio
                    rounded="full"
                    w="120px"
                    h="120px"
                    mt={4}
                    cursor="pointer"
                  >
                    <Image
                      rounded="full"
                      src={preview || placeholder}
                      objectFit={"cover"}
                    />
                  </AspectRatio>
                  <Box
                    pt={2}
                    position="absolute"
                    bg="#1E0F42"
                    top={4}
                    right={4}
                    w="36px"
                    h="36px"
                    align="center"
                    rounded="full"
                    onClick={handleButtonClick}
                  >
                    <AspectRatio w="22px" h="22px" cursor="pointer">
                      <Image src={sellericon} />
                    </AspectRatio>
                  </Box>
                  <Input
                    type="file"
                    display="none"
                    accept="image/png, image/gif, image/jpeg"
                    ref={imageRef}
                    onChange={handleChange}
                  ></Input>
                </Box>
              </Box>
            </HStack>
            <Box w={{lg:"75%",md:"85%"}} my={4}>
              <FormControl
                display="flex"
                direction="row"
                gap={3}
                flexDirection={["column", "row"]}
              >
                <Box w={["100%", "100%"]}>
                  <FormLabel fontSize="14px" color="#6B6162">
                    Business Name
                  </FormLabel>
                  <Input
                    placeholder="Enter name"
                    fontSize="14px"
                    color="#4B4447"
                    bg="white"
                    {...register("businessName")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.businessName?.message}
                  </Text>
                </Box>
              </FormControl>
              <Box
                w="full"
                display="flex"
                justifyContent="space-between"
                mt={3}
              ></Box>

            <FormControl
                display="flex"
                direction="row"
                gap={3}
                flexDirection={["column", "row"]}
              >
                <Box w={["100%", "100%"]}>
                <HStack alignItems="center">
                    <Checkbox onChange={handleSubscription}>
                      <Text fontSize="14px" fontWeight="400" color="#6B6162">
                        Subscription Plan
                      </Text>
                    </Checkbox>
                  </HStack>
                  {/* <FormLabel fontSize="14px" color="#6B6162">
                    Subscription Plan
                  </FormLabel> */}
                  <Select
                    placeholder="Choose Subscription Plan"
                    fontSize="14px"
                    color="#6B6162"
                    bg="white"
                    mt={2}
                    defaultValue=""
                    onChange={e =>setSubscriptionPlan(e.target.value)}
                    // {...register("subscriptionPlan")}
                    disabled={isSubscribed ? false : true}
                  >
                    <option value="Discovery_Plan">Discovery</option>
                    <option value="Discovery_Plus_Plan">Discovery+</option>
                    <option value="Discovery Pro">PAYS</option>
                  </Select>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.businessName?.message}
                  </Text>
                </Box>
              </FormControl>
              <HStack
                w="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={3}
                spacing={4}
              >
                <Box w="full">
                <FormLabel fontSize="14px" color="#6B6162">
                   Choose Subscription Package
                  </FormLabel>
                  <Select
                    placeholder="Choose Subscription Package"
                    fontSize="14px"
                    color="#6B6162"
                    bg="white"
                    mt={2}
                    defaultValue=""
                    onChange={e=>setUserPackage(e.target.value)}
                    disabled={isSubscribed ? false : true}
                  >
                    {/* <option value="monthly">Monthly</option> */}
                  {subscriptionPlan === "Discovery Pro" ? null: <option value="yearly">Yearly</option>}  
                  </Select>
                </Box>
                <FormControl
                  display="flex"
                  direction="row"
                  justifyContent="sp
              ace-between"
                  flexDirection={["column", "row"]}
                  w="full"
                >
                  <Box w="full">
                    <FormLabel color="#6B6162" fontSize="14px">
                      Amount
                    </FormLabel>
                    {/* <Input
                      type="number"
                      fontSize="14px"
                      color="#4B4447"
                      bg="white"
                      value={
                       isSubscribed ? price : null
                      }
                      {...register("amount")}
                      disabled={isSubscribed ? false : true}
                    /> */}
                     <Controller
        name="amount"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            type="number"
            fontSize="14px"
            color="#4B4447"
            bg="white"
            disabled={!isSubscribed}
          />
        )}
      />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.amount?.message}
                    </Text>
                  </Box>
                </FormControl>
              </HStack>

              <Box w={{lg:"65%",md:"72%"}} display="flex" justifyContent="space-between" mt={3}>
                <Box>
                  <Checkbox onChange={handleCommission}>
                    <Text fontSize="14px" fontWeight="400" color="#6B6162">
                      Commission
                    </Text>
                  </Checkbox>
                </Box>
                <Box w={{lg:"80%",md:"90%"}} ml={{lg:"60%",md:"140px"}}>
                  <Text fontSize="14px" fontWeight="400" color="#6B6162" whiteSpace={"nowrap"}  >
                    Transation fees
                  </Text>
                </Box>
              </Box>

              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                mt={1}
              >
                <Box w="49%">
                  {/* <Select
                    fontSize="14px"
                    color="#6B6162"
                    bg="white"
                    placeholder="Select Commission"
                    disabled={isCommission ? false : true}
                  >
                    <option value="10">10%</option>
                    <option value="20">20%</option>
                  </Select> */}
                  <Input
                    type="number"
                    fontSize="14px"
                    {...register("commission")}
                    color="#6B6162"
                    bg="white"
                    disabled={isCommission ? false : true}
                  />
                </Box>
                <Box w="49%">
                  <Input
                    type="number"
                    fontSize="14px"
                    {...register("transactionFee")}
                    color="#6B6162"
                    bg="white"
                    disabled={isCommission ? false : true}
                  />
                </Box>
              </Box>
              <Box mt="5">
                <FormControl
                  display="flex"
                  direction="row"
                  gap={3}
                  flexDirection={["column", "row"]}
                >
                  <Box w={["100%", "49%"]}>
                    <FormLabel fontSize="14px" color="#6B6162">
                      Contact Email
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      color="#4B4447"
                      bg="white"
                      border="1px"
                      {...register("email")}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.email?.message}
                    </Text>
                  </Box>
                  <Box w={{lg:"49%",md:"60%"}}>
                    <FormLabel color="#6B6162" fontSize="14px" fontWeight="400">
                      Category
                    </FormLabel>
                    <Select
                      placeholder="Select Category"
                      fontSize="14px"
                      color="#6B6162"
                      bg="white"
                      border="1px"
                      {...register("category")}
                      onChange={(e) => {
                        const selectedCategory = JSON.parse(e.target.value);
                        getSubCategoryId(selectedCategory._id);
                      }}
                    >
                      {categories?.map((value) => (
                        <option key={value._id} value={JSON.stringify(value)}
                        >
                          {value.mainCategory}
                        </option>
                      ))}
                    </Select>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.category?.message}
                    </Text>
                  </Box>
                </FormControl>
              </Box>

              <Box mt="5">
                <FormControl>
                  <Box >
                    <FormLabel color="#6B6162" fontSize="14px" fontWeight="400">
                    Sub Category
                    </FormLabel>
                    <Select
                      placeholder="Select Sub Category"
                      fontSize="14px"
                      color="#6B6162"
                      bg="white"
                      border="1px"
                      {...register("subCategory")}
                      // onChange={(e) => {
                      //   const selectedCategory = JSON.parse(e.target.value);
                      //   getSubCategoryId(selectedCategory._id);
                      // }}
                    >
                     {subCategory?.map((value) => (
                        <option key={value._id} value={JSON.stringify(value)}
                        >
                          {value.name}
                        </option>
                      ))}
                    </Select>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.subCategory?.message}
                    </Text>
                  </Box>
                </FormControl>
              </Box>
            </Box>

            

            <HStack my={4} w={{lg:"75%",md:"85%"}}>
              <Box w="full">
                <FormLabel color="#6B6162" fontSize="14px">
                  Contact Person
                </FormLabel>
                <Input
                  type="text"
                  fontSize="14px"
                  color="#4B4447"
                  bg="white"
                  {...register("primaryContact")}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.primaryContact?.message}
                </Text>
              </Box>
              <Box w="full">
                <FormLabel color="#6B6162" fontSize="14px">
                  Contact Phone Number
                </FormLabel>
                <Input
                  type="number"
                  fontSize="14px"
                  color="#4B4447"
                  bg="white"
                  {...register("phone")}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.phone?.message}
                </Text>
              </Box>
            </HStack>
            <HStack my={4} w={{lg:"75%",md:"85%"}}>
              <Box w="full">
                <FormLabel color="#6B6162" fontSize="14px">
                  Password
                </FormLabel>
                <Input
                  type="password"
                  placeholder="*********"
                  fontSize="14px"
                  color="#4B4447"
                  bg="white"
                  {...register("password")}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.password?.message}
                </Text>
              </Box>
              <Box w="full">
                <FormLabel color="#6B6162" fontSize="14px">
                  Confirm Password
                </FormLabel>
                <Input
                  type="password"
                  placeholder="*********"
                  fontSize="14px"
                  color="#4B4447"
                  bg="white"
                  {...register("confirmPassword")}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.confirmPassword?.message}
                </Text>
              </Box>
            </HStack>
            <Box
              display="flex"
              justifyContent="space-between"
              w={{lg:"74%",md:"100%"}}
              alignItems="center"
              my={6}
              gap={5}
              flexDirection={["column", "row"]}
            >
              
              <Grid
                w={["100%"]}
                templateColumns="repeat(4, 1fr)"
                mt={4}
                gap={5}
              >
                {gallery.length > 0 ? 
                (
                  gallery.map((image) => (
                    <GridItem
                      key={image.id}
                      my={6}
                      position="relative"
                      w="140px"
                      h="60px"
                    >
                      <AspectRatio cursor="pointer" rounded="lg">
                        <Image src={image} rounded="lg" />
                      </AspectRatio>
                      <Box
                        position="absolute"
                        cursor="pointer"
                        bg="white"
                        top={0.5}
                        right={0.5}
                        rounded="full"
                        shadow="lg"
                        onClick={() => deleteImage(image)}
                      >
                        <GrFormClose fontSize={16} />
                      </Box>
                    </GridItem>
                  ))
                ) : (
                  <>
                    <Input
                    style={{width:"248px"}}
                      w="full"
                      bg="white"
                      type="file"
                      //  display="none"
                      multiple={true}
                      accept="image/png, image/gif, image/jpeg"
                      ref={galleryRef}
                      onChange={handleGallery}
                    ></Input>
                  </>
                )}
              </Grid>
              <Input
                w={"full"}
                bg="white"
                type="file"
                display="none"
                multiple={true}
                accept="image/png, image/gif, image/jpeg"
                ref={galleryRefOne}
                onChange={handleGallery}
                
              />
              {/* <Box mt="7%" cursor="pointer" onClick={handleAddImagesButton}>
                <GrAddCircle />
              </Box> */}
            </Box>

            {/* <Box w={{lg:"75%",md:"85%"}} mt={0}>
              <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                About Seller
              </FormLabel>
              <Textarea
                type="text"
                minHeight="300px"
                bg="white"
                {...register("bio")}
                placeholder="Type something..."
              ></Textarea>
              <Text my={1} color="red" fontSize="xs">
                {errors.bio?.message}
              </Text>
            </Box> */}

            <FormLabel
              fontSize="14px"
              color="#6B6162"
              fontWeight="400"
              mt="10px"
            >
              Add Address
            </FormLabel>
            <Box
              w={{ xl: "75%", lg: "75%", md: "85%" }}
              mt="10px"
              border="1px"
              borderColor="gray.200"
              bg="white"
              shadow="lg"
            >
              <Box w="98%" mx="auto" my={4}>
                <GooglePlacesAutocomplete
                  className="form-control border_primary c_sel has-search "
                  apiKey={process.env.REACT_APP_GOOGLE_API}
                  selectProps={{
                    value,
                    onChange: setValue,
                    components: {
                      DropdownIndicator: (props) => <CustomDropdownIndicator fetchCurrentLocation={fetchCurrentLocation} {...props} />,
                      IndicatorSeparator: () => null // Optional: Remove the default indicator separator
                    }
                  }}
                />
                <Box h={320} my={4}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                    center={location}
                    zoom={zoom}
                  >
                     <Marker
            lat={location.lat}
            lng={location.lng}
          
          />

                  </GoogleMapReact>
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                fontSize="14px"
                bg="#FF1A57"
                color="#FFFFFF"
                my={5}
                w={{ xl: "75%", lg: "75%", md: "85%" }}
                onClick={handleSubmit(addSeller)}
              >
                Add Seller
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default AddSeller;
