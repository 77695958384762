import React from 'react'

const SupportTickets = (props:React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g clip-path="url(#clip0_8076_16814)">
<path d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675C5.39795 1.66675 1.66699 5.39771 1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.0003 13.3334C11.8413 13.3334 13.3337 11.841 13.3337 10.0001C13.3337 8.15913 11.8413 6.66675 10.0003 6.66675C8.15938 6.66675 6.66699 8.15913 6.66699 10.0001C6.66699 11.841 8.15938 13.3334 10.0003 13.3334Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3584 12.3584L15.8917 15.8917" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.1084 15.8917L7.64173 12.3584" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3584 7.64173L15.8917 4.1084" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3584 7.64162L15.3001 4.69995" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.1084 4.1084L7.64173 7.64173" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_8076_16814">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default SupportTickets;