import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Chart2 = ({ Yearslist, datalist }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: [],
    },
  });

  const [series, setSeries] = useState([
    {
      name: "series-1",
      data: [],
    },
  ]);

  useEffect(() => {
    const newDataOne = {
      ...options,
      xaxis: {
        categories: Yearslist,
      },
    };
    setOptions(newDataOne);
    const newData = series.map((item) => {
      return Object.assign(item, { data: datalist });
    });

    setSeries(newData);
  }, [Yearslist, datalist]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={360}
      />
    </div>
  );
};

export default Chart2;
