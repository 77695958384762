import {
  Box,
  Text,
  Image,
  Button,
  Stack,
  Input,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Spinner,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { icon_16 } from "../../assets/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllTickets,
  statusChangeMultipleTickets,
} from "../../api/supporTickets";

import { SearchIcon } from "@chakra-ui/icons";
import Header from "../../components/Header";
import { toast } from "react-toastify";

import TicketTable from "../../components/ticketTable";
import { useNavigate } from "react-router-dom";

const SupportTicket = () => {
  const dispatch = useDispatch();
  const { tickets } = useSelector((state) => state.tickets);
  const [selectItem, setSelectIem] = useState([]);
  const [totalCount,setTotalCount] = useState(null)
  const [users, setUsers] = useState([]);
  const [ids, setIds] = useState("");
  const [isLoading , setIsloading] = useState(true)

  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    if (name === "allChecked") {
      let tempUser = users.map((ticket) => {
        return { ...ticket, isChecked: checked };
      });
      setUsers(tempUser);
      let ids = tempUser.map((value) => value._id);
      if (checked) {
        setIds(ids);
      } else {
        setIds([]);
      }
    } else {
      let tempUser = users.map((ticket) =>
        ticket._id === name ? { ...ticket, isChecked: checked } : ticket
      );
      let idss = tempUser
        .filter((value) => value._id === name)
        .map((value) => value._id);

      setUsers(tempUser);
      if (checked) {
        // setStatus(value.status);
        setIds([...ids, idss[0]]);
      } else {
        // setStatus("");

        let idsss = ids
          .filter((value) => value._id === idss[0])
          .map((value) => value._id);
      }
    }
  };

  const getTicketsData = (item) => {
    const pageNo = item + 1
    dispatch(getAllTickets(pageNo))
      .then((res) => {
        setUsers(res.data.ticketsListing)
        setTotalCount(res?.data?.paginationCount?.totalTickets)
        setIsloading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getTicketsData(0);
  }, []);

  const handleResolvedTickets = () => {
    if (ids?.length > 0) {
      const apiData = {
        ids,
        status: "resolved",
      };
      dispatch(statusChangeMultipleTickets(apiData))
        .then((res) => {
          if (res.statusCode == 200) {
            getTicketsData();
            toast.success(res.message);
            setIds([]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleClosedTickets = () => {
    if (ids?.length > 0) {
      const apiData = {
        ids,
        status: "closed",
      };
      dispatch(statusChangeMultipleTickets(apiData))
        .then((res) => {
          if (res.statusCode == 200) {
            getTicketsData();
            toast.success(res.message);
            setIds([]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleId = (id, roomId) => {
    navigate(`/tickets-chat/${id}`, { state: { roomId } });
  };

  const deleteBulkTickets = () => {
    dispatch(deleteBulkTickets())
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        throw err;
      });
  };

  const selectItems = (ids) => {
    setSelectIem([...selectItem, ids]);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    const updatedList = users.filter((item) => {
      return item.issue.toLowerCase().indexOf(query.toLowerCase()) !== -1 || item.status.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    if (query === "") {
      setUsers(tickets?.ticketsListing);
    } else {
      setUsers(updatedList);
    }
  };

  return (
    <Layout>
      {isLoading ? (
         <HStack w="full" h="100vh" justifyContent="center">
         <Spinner size="lg" color="red" />
       </HStack>
      ): (
        <>
        <Box w={{lg:"100%",md:"98%"}} display="flex" mx={{lg:"auto",md:"auto"}}>
        <Box w={{lg:"98%",md:"93%"}} mx={{lg:"auto",md:"auto"}} display="flex" justifyContent="space-between">
          <Text color="#2E1A47" fontSize={30} w="full" fontWeight="bold">
            Support Tickets
          </Text>
          <Header />
        </Box>
      </Box>
      <Box
        display="flex"
        direction="row"
        justifyContent="space-between"
        flexDirection={["column", "row"]}
        w={{lg:"98%",md:"92%"}}
        mx="auto"
        gap={3}
        my={4}
      >
        <Box
          w={["100%", "30%"]}
          textAlign="center"
          boxShadow="lg"
          p="15px"
          rounded="lg"
          border="1px"
          borderColor="white"
          bg="white"
        >
          <Text fontWeight="bold" color="#FF1A57" fontSize="5xl">
            {tickets?.activeCases}
          </Text>
          <Text fontWeight="bold" color="#2E1A47" fontSize="2xl">
            Active Cases
          </Text>
          <Button
            fontSize="xs"
            mt="15px"
            fontWeight="bold"
            bg="white"
            border="1px"
            borderColor="#FF1A57"
          >
            <Box p={1}>
              <Image src={icon_16} />
            </Box>
            <Text color="#FF1A57"> Filter by selling</Text>
          </Button>
        </Box>

        <Box
          w={["100%", "30%"]}
          textAlign="center"
          boxShadow="lg"
          p="10px"
          rounded="lg"
          border="1px"
          borderColor="white"
          bg="white"
        >
          <Text fontWeight="bold" color="#FF1A57" fontSize="5xl">
            {tickets?.reponseTime || "1hr 04m"}
          </Text>
          <Text fontWeight="bold" color="#2E1A47" fontSize="2xl">
            Response Time
          </Text>
          <Stack display="flex" direction="row" justifyContent="center" p={5}>
            <Text fontSize="xs" fontWeight="bold" color="green.600">
              {tickets?.thismonth}%
            </Text>
            <Text color="#2E1A47" fontSize="xs" mt="15px" fontWeight="bold">
              {" "}
              This Month
            </Text>
          </Stack>
        </Box>

        <Box
          w={["100%", "30%"]}
          textAlign="center"
          boxShadow="lg"
          p="10px"
          rounded="lg"
          bg="#FFEBEB"
          border="1px"
          borderColor="#FFEBEB"
        >
          <Text fontWeight="bold" color="#FF1A57" fontSize="5xl">
            {tickets?.pendingTickets}
          </Text>
          <Text fontWeight="bold" color="#2E1A47" fontSize="2xl">
            Pending Tickets
          </Text>
          <Button
            fontSize="xs"
            mt="15px"
            fontWeight="bold"
            bg="#FFEBEB"
            border="1px"
            borderColor="#FF1A57"
          >
            <Box p={1}>
              <Image src={icon_16} />
            </Box>
            <Text color="#FF1A57"> Filter by Pending</Text>
          </Button>
        </Box>
      </Box>

      <Box
        w={{lg:"98%",md:"92%"}}
        mx="auto"
        display="flex"
        justifyContent="space-between"
        mt="15px"
        gap="2"
        flexDirection={["column", "row"]}
      >
        <Box w={{lg:"55%",md:"30%"}}>
          <FormLabel fontSize={14} color="gray.500" fontWeight={600}>
            Search tickets
          </FormLabel>
          <Box display="flex">
            <InputGroup w={["98%", "100%"]} mt={["2", "-4px"]}>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="#FF1A57" />}
              />
              <Input
                type="tel"
                pl={12}
                _focus={{
                  outline: "none",
                  border: "none",
                }}
                _after={{
                  outline: "none",
                  border: "none",
                }}
                _active={{
                  outline: "none",
                }}
                bg="white"
                borderRadius={5}
                borderColor="#B1ABA9"
                placeholder="Search for ticket name and status.."
                onChange={handleSearch}
              />
            </InputGroup>
          </Box>
        </Box>

        <Box
          w={{lg:"20%",md:"30%"}}
          mx={{lg:"auto",md:"auto"}}
          display="flex"
          justifyContent={"flex-end"}
          flexDirection="column"
          gap="2"
        >
          <Text fontSize={14} color="gray.500" fontWeight={600}>
            Bulk actions(select buyers)
          </Text>
          <Button
            bg="#FF1A57"
            color="white"
            disabled={ids?.length < 1 ? true : false}
            onClick={handleResolvedTickets}
            fontSize="14px"
            fontWeight="600"
            mt={"-4px"}
          >
            Marked as resolved
          </Button>
        </Box>
        <Box
          w={{lg:"20%",md:"30%"}}
          display="flex"
          justifyContent={"flex-end"}
          flexDirection="column"
        >
          <Button
            bg="#FF1A57"
            color="white"
            disabled={ids?.length < 1 ? true : false}
            onClick={handleClosedTickets}
            fontSize="14px"
            fontWeight="600"
            mt={"-4px"}
          >
            Close ticket
          </Button>
        </Box>
      </Box>

      <Box mt="2%" w="full" mx="auto">
        <TicketTable
          itemsPerPage={10}
          items={users}
          handleChange={handleChange}
          handleId={handleId}
          totalCount={totalCount}
          getTicketsData={getTicketsData}
        />
      </Box>
        </>
      )}
     
    </Layout>
  );
};

export default SupportTicket;
