import React from "react";
import ReactApexChart from "react-apexcharts";

class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          id: "apexchart-example",
        },
        xaxis: {
          categories: this.props.graphData?.Yearslist,
        },
      },
      series: [
        {
          name: "series-1",
          data: this.props.graphData?.datalist,
        },
      ],
      fill: {
        colors: ["#F44336", "#E91E63", "#9622aa"],
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#000", "#f1f1f1"], // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: ["#f1f1"],
        },
      },
    };
  }

  render() {
    // console.log("inside", this.props.graphData);
    return (
      <div id="chart">
        {/* <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width={500}
          height={320}
        /> */}
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={360}
        />
      </div>
    );
  }
}

export default Charts;
