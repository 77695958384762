import React from 'react'
import { ViewIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  Button,
  FormControl,
  FormLabel,
  Hide,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changeOldPassword, getAdminDetails } from "../api/user";
import { icon_10, sellericon } from "../assets/icons";
import { placeholder, profile } from "../assets/images";
import Layout from "./Layout";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "./Header";
import { editProfile } from "../api/admin";
import { singleFileUpload } from "../api/category";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useRef } from 'react';
import avatar  from '../assets/images/profilee.png'

const UpdateProfile = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm(
      );
    
      const [show, setShow] = React.useState(false);
      const [showNewPassword, setShowNewPassword] = React.useState(false);
      const [preview, setPreview] = useState(null);
      const [showConfrimPassword, setShowConfrimPassword] = React.useState(false);
      const [profileImage, setProfileImage] = useState("");
      const [changePassword, setChangePassword] = useState("");
      const [isLoading, setIsLoading] = useState(false);
      const dispatch = useDispatch();
      const imageRef = useRef();
      const { admin } = useSelector((state) => state.admin);

      useEffect(() => {
        if (admin) {
          reset({
            firstName: admin?.name?.firstName,
            lastName: admin?.name?.lastName,
          });
          setProfileImage(admin.profileImage);
        }
      }, [admin]);
    
  
    
    
    
      const handleProfile = (data) => {
        const apiData = {
          firstName: data.firstName,
          lastName: data.lastName,
          profileImage: profileImage,
        };
        setIsLoading(true)
        // return console.log(apiData,"apiData");
        dispatch(editProfile(apiData))
          .then((res) => {
            setIsLoading(false)
            if (res.statusCode === 200) {
              toast.success(res.message);
              dispatch(getAdminDetails());
            }
          })
          .catch((err) => {
            toast.error(err) 
            setIsLoading(false)
          });
      };
    
      const handleButtonClick = (e) => {
        imageRef.current.click();
      };
    
      const handleChange = (e) => {
        const img = e.target.files[0];
        if (img) {
          dispatch(singleFileUpload(img))
            .then((res) => setProfileImage(res.data.image))
            .catch((error) => console.log(error));
        }
        setPreview(URL.createObjectURL(img));
      };
  return (
    <>
      <Box
          w={{lg:"60%",md:"94%"}}
          display="flex"
          mt="10px"
          ml="2%"
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box display="flex" alignItems="center">
            <Box position="relative" bg="">
              <AspectRatio
                rounded="full"
                w="120px"
                h="120px"
                mt={4}
                cursor="pointer"
              >
                <Image
                  rounded="full"
                  src={preview || admin?.profileImage || avatar}
                  objectFit={"cover"}
                />
              </AspectRatio>
              <Box
                pt={2}
                position="absolute"
                bg="#1E0F42"
                top={4}
                right={4}
                w="36px"
                h="36px"
                align="center"
                rounded="full"
                onClick={handleButtonClick}
              >
                <AspectRatio w="22px" h="22px" cursor="pointer">
                  <Image src={sellericon} />
                </AspectRatio>
              </Box>
              <Input
                type="file"
                display="none"
                accept="image/png, image/gif, image/jpeg"
                ref={imageRef}
                onChange={handleChange}
              ></Input>
            </Box>
            <Box ml={5}>
              <Text
                fontFamily="Rubik"
                fontStyle="normal"
                fontWeight="600"
                fontSize={["28px", "34px"]}
                lineHeight="40px"
                color="#3B3538"
              >
                {admin?.name?.firstName} {admin?.name?.lastName}
              </Text>
              <Text
                fontStyle="normal"
                fontWeight="600"
                fontSize="23px"
                lineHeight="28px"
                color="#FF1A57"
              >
                {admin?.role}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box w={{lg:"50%",md:"94%"}} ml={{lg:8,md:6}} mt={8}>
          <Text
            fontSize="34px"
            fontWeight="600"
            color="#1E0F42"
            fontFamily="Rubik"
          >
            Edit Profile
          </Text>
          <FormControl mt="10px">
            <HStack>
              <Box w="100%">
                <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                  First Name
                </FormLabel>
                <Input
                  type="text"
                  border="1px"
                  borderColor="gray.400"
                  pr="4.5rem"
                  {...register("firstName")}
                />
              </Box>
              <Box w="100%">
                <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                  Last Name
                </FormLabel>
                <Input
                  type="text"
                  border="1px"
                  borderColor="gray.400"
                  pr="4.5rem"
                  {...register("lastName")}
                />                         
              </Box>
            </HStack>
          </FormControl>
          <Box mt={5} w={["95%", "100%"]}>
            <Button
              bg="#FF1A57"
              w="full"
              fontSize="14px"
              fontWeight="600"
              color="#FFFFFF"
              isLoading={isLoading}
              onClick={handleSubmit(handleProfile)}
            >
              Confirm changes
            </Button>
          </Box>
        </Box>
    </>
  )
}

export default UpdateProfile