import {
    Box,
    Text,
    Image,
    Button,
    InputGroup,
    InputLeftElement,
    Input,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Checkbox,
    Tbody,
    Td,
    Flex,
    Stack,
    Hide,
    Accordion,
    AccordionItem,
    HStack,
    AccordionButton,
    AccordionPanel,
    Slider,
  } from "@chakra-ui/react";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import ReactPaginate from "react-paginate";
  
  function Items({ currentItems, handleChange, handleId,handleOpenCategoryModal,handleDeleteCategory,handleEditSubCategory,handleOpenSubCategoryModal,handleDeleteSubcategory}) {
    return (
        <Box
        boxShadow="xl"
        rounded="lg"
        mt={3}
        border="1px"
        borderColor="gray.200"
      >
        {currentItems?.map((category) => (
          <Accordion allowMultiple key={category._id}>
            <AccordionItem>
              <HStack alignItems="center">
                <AccordionButton>
                  <HStack
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    w="full"
                  >
                    <Box>
                      <Text
                        color="#000000"
                        fontSize="16px"
                        fontWeight="600"
                        m={3}
                        p={0}
                      >
                        {category.mainCategory}
                      </Text>
                    </Box>
                  </HStack>
                </AccordionButton>
                <Box gap={2} display="flex" justifyContent="end" pr={4}>
                  <Box w="full">
                    <Button
                      w="100%"
                      bg="white"
                      border="1px"
                      borderColor="green.400"
                      _hover={{
                        bg: "none",
                      }}
                      shadow="md"
                      onClick={() =>
                        handleOpenCategoryModal({
                          type: "Edit",
                          id: category._id,
                          category: category.mainCategory,
                        })
                      }
                    >
                      <Text
                        fontSize="14px"
                        fontWeight="600"
                        color="green.400"
                      >
                        Edit
                      </Text>
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      bg="white"
                      border="1px"
                      borderColor="#FF1A57"
                      onClick={() => handleDeleteCategory(category._id)}
                    >
                      <Text
                        color="#FF1A57"
                        fontSize="12px"
                        fontWeight="600"
                      >
                        Delete
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </HStack>
              <AccordionPanel pb={4} bg="white">
                {category?.subCategory?.map((subCategory) => (
                  <HStack
                    alignItems="baseline"
                    key={subCategory._id}
                    w="full"
                    h="auto"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Text fontSize={15} fontWeight={600} color="#7B6F6F">
                        {subCategory.name}
                      </Text>
                      <Slider />
                    </Box>
                    <HStack>                    <Button
                      bg="white"
                      border="1px"
                      size="xs"
                      borderColor="#FF1A57"
                      onClick={() =>
                        handleEditSubCategory({
                          id: subCategory._id,
                          subcategory: subCategory.name,
                          type: "Edit",
                        })
                      }
                    >
                      <Text
                        color="#FF1A57"
                        fontSize="12px"
                        fontWeight="600"
                      >
                        Edit
                      </Text>
                    </Button>

                    <Button
                      bg="white"
                      border="1px"
                      size="xs"
                      borderColor="#FF1A57"
                      onClick={() =>
                        handleDeleteSubcategory(subCategory._id)
                      }
                    >
                      <Text
                        color="#FF1A57"
                        fontSize="12px"
                        fontWeight="600"
                      >
                        Delete
                      </Text>
                    </Button></HStack>

                  </HStack>
                ))}
                <Button
                  w={240}
                  bg="#FF1A57"
                  _hover={{
                    bg: "#FF1A57",
                  }}
                  shadow="md"
                  color="white"
                  onClick={() =>
                    handleOpenSubCategoryModal({
                      id: category._id,
                      subcategory: "",
                      type: "Add",
                    })
                  }
                >
                  <Text color="white" fontSize="14px" fontWeight="600">
                    Add Sub Category
                  </Text>
                </Button>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ))}
      </Box>
    );
  }
  
  function CategoryTable({ itemsPerPage, items, handleChange, handleId ,handleDeleteSubcategory,handleOpenCategoryModal,handleDeleteCategory,handleEditSubCategory,handleOpenSubCategoryModal }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(items?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items?.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items?.length;
      setItemOffset(newOffset);
    };
  
    return (
      <>
        <Items
          currentItems={currentItems}
          handleChange={handleChange}
          handleId={handleId}
          handleOpenCategoryModal={handleOpenCategoryModal}
          handleDeleteCategory={handleDeleteCategory}
          handleDeleteSubcategory={handleDeleteSubcategory}
          handleEditSubCategory={handleEditSubCategory}
          handleOpenSubCategoryModal={handleOpenSubCategoryModal}
        />
        <Box bg="white">
          <ReactPaginate
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link "
            previousClassName="page-item me-auto "
            previousLinkClassName="page-link ms-auto bg-transparent"
            nextClassName="page-item ms-auto"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination justify-content-center shadow"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </Box>
      </>
    );
  }
  
  export default CategoryTable;
  