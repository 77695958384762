import {
  Box,
  Hide,
  Text,
  Menu,
  Image,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAdminDetails } from "../api/user";
import { user } from "../assets/images";
import { checkString, removeAllFromLocalStorage } from "../helpers";
import avatar from "../assets/images/profilee.png";
import { resetNotificationCount } from "../redux/reducers/adminSlice";

const Header = () => {
  const { admin } = useSelector((state) => state.admin);
  const { notyCount } = useSelector((state) => state.admin);
  // console.log(notyCount, "=>notyCount");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAdminDetails());
  }, []);

  const handleLogout = () => {
    removeAllFromLocalStorage();
    navigate("/login");
  };
  const handleNotificationClick = () => {
    navigate("/push-notification");
    dispatch(resetNotificationCount()); // Reset the notification count
  };
  return (
    <Box display="flex" w="full" pl={1} justifyContent="flex-end">
      <Hide below="md">
        <Box
          mr={5}
          pos={"relative"}
          onClick={handleNotificationClick}
          style={{ cursor: "pointer" }}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6.66675C15 5.34067 14.4732 4.0689 13.5355 3.13121C12.5979 2.19353 11.3261 1.66675 10 1.66675C8.67392 1.66675 7.40215 2.19353 6.46447 3.13121C5.52678 4.0689 5 5.34067 5 6.66675C5 12.5001 2.5 14.1667 2.5 14.1667H17.5C17.5 14.1667 15 12.5001 15 6.66675Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M11.4419 17.5C11.2954 17.7526 11.0851 17.9622 10.8321 18.1079C10.5791 18.2537 10.2922 18.3304 10.0003 18.3304C9.70828 18.3304 9.42142 18.2537 9.16841 18.1079C8.91539 17.9622 8.7051 17.7526 8.55859 17.5"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          {notyCount > 0 && (
            <Box
              height={"20px"}
              width={"20px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              pos={"absolute"}
              color={"white"}
              top={"-12px"}
              left={"16px"}
              bg={"#FF1A57"}
              rounded={"full"}
            >
              <Text fontSize={"xs"} fontWeight={"bold"}>
                {notyCount}
              </Text>
            </Box>
          )}
        </Box>
        |
        <Box mx={1}>
          <Text
            fontSize={["16px", "16px", "14px"]}
            fontWeight={600}
            color="#1E0F42"
          >
            {admin?.name?.firstName} {admin?.name?.lastName}
          </Text>
          <Text
            fontSize={["10px", "10px", "11px"]}
            fontWeight="bold"
            textAlign="end"
            color="#1E0F42"
          >
            {admin?.role}
          </Text>
        </Box>
      </Hide>
      <Menu>
        <MenuButton aria-label="Options" variant="outline">
          <Image
            // src={admin?.profileImage? admin.profileImage : user}
            src={
              admin?.profileImage === "https://"
                ? user
                : checkString(admin?.profileImage)
                ? user
                : admin?.profileImage || avatar
            }
            w={10}
            h={10}
            rounded="full"
          />
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => navigate("/profile")}
            _hover={{
              bg: "#FF1A57",
              color: "white",
            }}
          >
            Profile
          </MenuItem>
          {/* <MenuItem>Settings</MenuItem> */}
          {/* <MenuItem onClick={()=> handleLogout()}>Logout</MenuItem> */}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Header;
