import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  // Radio,
  RadioGroup,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoIosArrowForward, IoMdVolumeHigh } from "react-icons/io";
import { Select } from "@chakra-ui/react";

// import { rowes } from "../../assets/images";
// import { star, arrow } from "../../assets/icons";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
// import EditChangeImages from "../../components/ChangeImages";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import moment from "moment";
import {
  // DAYS,
  DAYSDATA,
} from "../../constants";
import { getAllCategories } from "../../api/category";
import {
  deleteMultipleOffers,
  // deleteSingleOffer,
  getAlloffers,
  singleOffer,
  // singleOffer,
  updateOffer,
  updateOffersStatus,
} from "../../api/offer";
import ReactStars from "react-rating-stars-component";
// import { render } from "react-dom";
import EditChangeImage from "../../components/EditChangeImage";
import avatar from "../../assets/images/profilee.png";
import { useQuery } from "@tanstack/react-query";
// import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Index = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [select, setSelect] = useState("");
  // const [value, setValue] = useState("");
  const [days, setDays] = useState([]);
  const [gallery, setGallery] = useState([]);
  // const [peak, setPeak] = useState(false);
  // const [offPeak, setOffPeak] = useState(false);
  // const [anytime, setAnytime] = useState(false);
  const [user, setUser] = useState(null);
  const [checked, setChecked] = useState(null);
  // const [isDiscountDisabled, setIsDiscountDisabled] = useState(false);
  const isDiscountDisabled = false;
  // const [isPercentageDisabled, setIsPercentageDisabled] = useState(false);
  const isPercentageDisabled = false;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isStudentDisable, setIsStudentDisable] = useState(true);
  const [studentPrice, setStudentPrice] = useState("");
  const [studentFinalPrice, setStudentFinalPrice] = useState("");
  const [nhsFinalPrice, setNhsFinalPrice] = useState("");
  const [isNHSDisable, setIsNHSDisable] = useState(true);
  const [nhsPrice, setNhsPrice] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [delivery, setDelivery] = useState([]);
  const [selectedDays, setSelectedDays] = useState(DAYSDATA);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [checkedDays, setCheckedDays] = useState([]);
  const [percentagePrice, setPercentagePrice] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [isStudentChecked, setIsStudentChecked] = useState(false);
  const [isNhsChecked, setIsNhshecked] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [deliveryError, setDeliveryError] = useState("");
  // const [delivery, setDelivery] = useState([]);
  // const [ids, setIds] = useState([]);
  // const [users, setUsers] = useState([]);
  // const state = location.state;
  const getSchema = (delivery) => {
    return yup
      .object({
        deliveryCharges:
          delivery.includes("Delivery") ||
          delivery.includes("Online") ||
          delivery.includes("Home Services")
            ? yup
                .string()
                .required(
                  "Delivery charge is required when delivery option is selected."
                )
            : yup.string().nullable(),
      })
      .required();
  };

  // Populate initial state with offer details

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories()).then((res) => {
      setCategories(res);
    });
  }, []);

  const { offer } = useSelector((state) => state.offers);
  const schema = getSchema(delivery);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {deliveryCharges: 0 }
  });

  // const onSubmit = (data) => console.log(data)
  const params = useParams();
  const offerId = params.id;

  const handleOriginalPrice = (e) => {
    setOriginalPrice(e.target.value);
  };
  const handleDiscountedPrice = (e) => {
    setDiscountedPrice(e.target.value);
  };
  const handlePercentagePrice = (e) => {
    setPercentagePrice(e.target.value);
  };

  const handleDelivery = (e) => {
    const { value, checked } = e.target;
    
    if (checked) {
      setDelivery([...delivery, value]);
    } else {
      setDelivery(delivery.filter((item) => item !== value));
    }
  };
  useEffect(() => {
    if (offer?.offerDetails?.delivery) {
      setDelivery(offer.offerDetails.delivery);
    }
  }, [offer]);

  const { data } = useQuery({
    queryKey: ["offer", { offerId }],
    queryFn: ({ signal, queryKey }) => singleOffer({ signal, ...queryKey[1] }),
  });

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  useEffect(() => {
    if (!offerId || !data) {
      return;
    }
    setGallery(data?.data?.offerDetails?.gallery);
    setDays(data?.data?.offerDetails?.days);
    setCategoryId(data?.data?.offerDetails?.category?.name);
    setSubCategoryId(data?.data?.offerDetails?.subCategory?.name);
    setSelectedCategory({
      id: data?.data?.offerDetails?.category?.id,
      name: data?.data?.offerDetails?.category?.name,
    });
    setSelectedSubCategory({
      id: data?.data?.offerDetails?.subCategory?.id,
      name: data?.data?.offerDetails?.subCategory?.name,
    });
    setOriginalPrice(data?.data?.offerDetails?.price.orignalPrice);
    setDiscountedPrice(data?.data?.offerDetails?.price.discountedPrice);
    setPercentagePrice(data?.data?.offerDetails?.price.percentagePrice);
    if (data?.data?.offerDetails.studentDiscount !== null) {
      setIsStudentChecked(true);
      setIsStudentDisable(false);
      setStudentPrice(data?.data?.offerDetails.studentPrice);
      setStudentFinalPrice(data?.data?.offerDetails.studentDiscount);
    }
    if (data?.data?.offerDetails.NHSDiscount !== null) {
      setIsNhshecked(true);
      setIsNHSDisable(false);
      setNhsPrice(data?.data?.offerDetails.NHSPrice);
      setNhsFinalPrice(data?.data?.offerDetails.NHSDiscount);
    }
    setDelivery(data?.data?.offerDetails?.delivery);
    setChecked(data?.data?.offerDetails?.popularity);
    setAvailableDays(data?.data?.offerDetails?.days);
    if (data?.data?.offerDetails?.days?.length == 7) {
      setIsParentChecked(true);
    }
    setUser({
      offerName: data?.data?.offerDetails?.offerName || "hello",
      sellerName: data?.data?.offerDetails?.sellerId?.name?.businessName,
      quantity: data?.data?.offerDetails?.quantity,
      cancellationPolicy: data?.data?.offerDetails?.cancellationPolicy,
      validUntill: data?.data?.offerDetails?.validUntill,
      startDate: data?.data?.offerDetails?.duration?.startDate?.slice(0, 10),
      endDate: data?.data?.offerDetails?.duration?.endDate?.slice(0, 10),
      ageRestrictedProduct: data?.data?.offerDetails?.ageRestrictedProduct,
      commission: data?.data?.offerDetails?.commision,
      specialDiscount: data?.data?.offerDetails?.specialDiscount,
      description: data?.data?.offerDetails?.description,
      terms: data?.data?.offerDetails?.terms,
      deliveryCharges: data?.data?.offerDetails?.sellerId?.deliveryCharges,
    });
  }, [offerId, data]);

  useEffect(() => {
    if (categoryId) {
      const data = categories.filter(
        (item) => item.mainCategory === categoryId
      );
      setSubCategories(data[0]?.subCategory);
    }
  }, [categoryId]);

  useEffect(() => {
    reset(user);
  }, [user]);

  useEffect(() => {
    if (selectedDays.length < 1) {
      setSelectedDays(days);
    }
  }, [days]);

  const handleStudent = (e, type) => {
    if (type === "student") {
      if (e) {
        setIsStudentChecked(true);
        setIsStudentDisable(false);
      } else {
        setIsStudentChecked(false);
        setIsStudentDisable(true);
        setStudentPrice("");
        setStudentFinalPrice("");
      }
    } else {
      if (e) {
        setIsNhshecked(true);
        setIsNHSDisable(false);
      } else {
        setIsNhshecked(false);
        setIsNHSDisable(true);
        setNhsPrice("");
        setNhsFinalPrice("");
      }
    }
  };

  const handleStudentPrice = (e) => {
    const price = e.target.value;
    setStudentPrice(price);
    if (originalPrice !== "" && price !== "") {
      setStudentFinalPrice(`${originalPrice - (price / 100) * originalPrice}`);
    } else {
      setStudentFinalPrice("");
    }
  };

  const handleNhsPrice = (e) => {
    const price = e.target.value;
    setNhsPrice(price);
    if (originalPrice !== "" && price !== "") {
      setNhsFinalPrice(`${originalPrice - (price / 100) * originalPrice}`);
    } else {
      setNhsFinalPrice("");
    }
  };

  const handleCategory = (e) => {
    const { value } = e.target;
    setCategoryId(value);
    const data = categories.filter(
      (item) => item.mainCategory == e.target.value
    );
    const m = { mainCategory: "name", _id: "id" };
    const newData = (o) =>
      Object.assign(...Object.keys(o).map((k) => ({ [m[k] || k]: o[k] })));
    const newObj = newData(data[0]);
    setSelectedCategory({
      id: newObj.id,
      name: newObj.name,
    });
    setSubCategories(data);
    setSubCategoryId("");
  };

  const toggle = (evt) => {
    setChecked((current) =>
      current === evt.target.value ? null : evt.target.value
    );
  };
  useEffect(() => {
    if (discountedPrice === "") {
      setPercentagePrice("");
    } else if (originalPrice === "" && discountedPrice !== "") {
      setPercentagePrice("");
    } else if (originalPrice === "" && percentagePrice !== "") {
      setDiscountedPrice("");
    } else if (originalPrice !== "" && discountedPrice === "") {
      setPercentagePrice("");
    } else if (parseFloat(discountedPrice) > parseFloat(originalPrice)) {
      toast.error("Discounted Price can't be greater than original price");
      setPercentagePrice("");
      setDiscountedPrice("");
    } else if (originalPrice !== "" && discountedPrice !== "") {
      let price = 100 - (discountedPrice * 100) / originalPrice;
      setPercentagePrice(price.toFixed(2));
    }
  }, [discountedPrice]);

  useEffect(() => {
    const myUsers = [...selectedDays];
    if (isParentChecked) {
      myUsers.map((item) => {
        item.isChecked = true;
        return item;
      });
    } else {
      myUsers.map((item) => {
        item.isChecked = availableDays?.includes(item.day);
        return item;
      });
    }
    setSelectedDays([...myUsers]);
  }, [availableDays]);

  const changeCheckboxStatus = (e, id) => {
    const myUsers = [...selectedDays];
    const { checked } = e.target;
    myUsers.map((user) => {
      if (id === "allChecked") {
        setIsParentChecked(checked);
        user.isChecked = checked;
      } else {
        if (user.id === id) {
          user.isChecked = checked;
          if (checked) {
            setCheckedDays([...checkedDays, user]);
          } else {
            const removeUser = checkedDays.filter((item) => item !== user);
            setCheckedDays(removeUser);
          }
        }
        const isAllChildsChecked = myUsers.every(
          (user) => user.isChecked === true
        );
        if (isAllChildsChecked) {
          setIsParentChecked(checked);
        } else {
          setIsParentChecked(false);
        }
      }
      return user;
    });

    setSelectedDays([...myUsers]);
  };

  useEffect(() => {
    if (parseFloat(studentFinalPrice) > parseFloat(originalPrice)) {
      toast.error(
        "Student Effective price should not be greater than original price."
      );
      setStudentFinalPrice("");
      setStudentPrice("");
    }
  }, [studentFinalPrice, originalPrice]);

  // useEffect(() => {
  //   if (parseFloat(studentPrice) > 100) {
  //     toast.error(
  //       "Student Percentage discount should not be greater than 100."
  //     );

  //     setStudentPrice("");
  //     setStudentFinalPrice("");
  //   }
  // }, [studentPrice, originalPrice]);

  useEffect(() => {
    if (parseFloat(nhsFinalPrice) > parseFloat(originalPrice)) {
      toast.error(
        "NHS Effective price should not be greater than original price."
      );
      setNhsFinalPrice("");
      setNhsPrice("");
    }
  }, [nhsFinalPrice, originalPrice]);

  // useEffect(() => {
  //   if (parseFloat(nhsPrice) > 100) {
  //     toast.error("NHS Percentage discount should not be greater than 100.");

  //     setNhsPrice("");
  //     setNhsFinalPrice("");
  //   }
  // }, [nhsPrice, originalPrice]);

  const updateOffers = (data) => {
    if (studentPrice > 100) {
      toast.error(
        "Student Percentage discount should not be greater than 100."
      );
      return;
    }

    if (nhsPrice > 100) {
      toast.error("NHS Percentage discount should not be greater than 100.");
      return;
    }

    Number.prototype.toFixedNoRounding = function (n) {
      const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
      const a = this.toString().match(reg)[0];
      const dot = a.indexOf(".");
      if (dot === -1) {
        return a + "." + "0".repeat(n);
      }
      const b = n - (a.length - dot) + 1;
      return b > 0 ? a + "0".repeat(b) : a;
    };
    const originalPri = parseFloat(originalPrice).toFixedNoRounding(2);
    const discountedPri = parseFloat(discountedPrice).toFixedNoRounding(2);
    const sp = parseFloat(studentFinalPrice || 0).toFixedNoRounding(2);
    const np = parseFloat(nhsFinalPrice || 0).toFixedNoRounding(2);
    const apiData = {
      offerName: data.offerName,
      category: selectedCategory,
      subCategory: selectedSubCategory,
      quantity: data.quantity,
      isReducedPrice: false,
      ageRestrictedProduct: data.ageRestrictedProduct,
      address: "50",
      description: data.description,
      days: selectedDays,
      gallery,
      cancellationPolicy: data.cancellationPolicy,
      validUntill: parseInt(data.validUntill),
      percentage: 10,
      specialDiscount: "50",
      studentDiscount: sp || 0,
      studentPrice: studentPrice || 0,
      NHSDiscount: np || 0,
      NHSPrice: nhsPrice || 0,
      price: {
        orignalPrice: originalPri,
        discountedPrice: discountedPri,
        percentagePrice: percentagePrice,
      },
      duration: {
        startDate: moment(data.startDate).format("DD/MM/YYYY"),
        endDate: moment(data.endDate).format("DD/MM/YYYY"),
      },
      delivery: delivery,
      terms: data.terms,
      popularity: checked,
    };
    // return console.log("apiData", apiData)
    dispatch(updateOffer(offerId, apiData))
      .then((res) => {
        toast.success("Offer Updated Successfully!");
        navigate("/manage-offers");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlesubCategory = (e) => {
    const subCate = subCategories.filter(
      (item) => item.name === e.target.value
    );
    setSubCategoryId(subCate[0].name);
    setSelectedSubCategory({
      id: subCate[0]._id,
      name: subCate[0].name,
    });
  };

  const deleteOffer = () => {
    const apiData = {
      ids: [offerId],
    };
    dispatch(deleteMultipleOffers(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          dispatch(getAlloffers());
          navigate("/manage-offers");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pauseOffer = () => {
    const apiData = {
      ids: [offerId],
      status: "pending",
    };
    dispatch(updateOffersStatus(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          dispatch(getAlloffers());
          navigate("/manage-offers");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resumeOffer = (status) => {
    const apiData = {
      ids: [offerId],
      status: "active",
    };
    dispatch(updateOffersStatus(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          dispatch(getAlloffers());
          navigate("/manage-offers");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <Box>
        <Box
          w={{ xl: "96%", md: "94%" }}
          mx={{ lg: "auto", md: "auto" }}
          display="flex"
          justifyContent="space-between"
        >
          <Link variant="ghost" to="/manage-offers" bg="green">
            <Text
              fontFamily="Commissioner"
              color="#2E1A47"
              fontSize={{ xl: "20px", md: "18px" }}
              fontWeight="600"
              lineHeight="28px"
              letter="-4%"
            >
              {"<"}Back
            </Text>
          </Link>
          <Header />
        </Box>
        <Box w={{ xl: "95%", md: "94%" }} mx={{ lg: "auto", md: "auto" }}>
          <Text
            fontFamily="Rubik"
            color="#2E1A47"
            fontSize={{ xl: "34px", md: "30px" }}
            fontWeight="600"
            lineHeight="40px"
            letter="-4%"
          >
            Edit Offer
          </Text>
        </Box>
        <Box>
          <Box
            w={{ xl: "60%", md: "94%" }}
            mx={{ lg: "auto", md: "auto" }}
            shadow="xl"
            bg="white"
            display="flex"
            mt="15px"
            justifyContent="space-between"
            p="8px"
            borderRadius="md"
            ml={{ lg: 8 }}
          >
            <Box w="45%" mt="10px">
              <Text fontSize="20px" fontWeight="600" color="#1E0F42">
                {offer?.offerDetails?.offerName}
              </Text>
              <Box mt="20px">
                <Flex>
                  <Image
                    src={data?.sellerDetails?.profileImage || avatar}
                    // src={profileImage|| avatar }
                    h="72px"
                    w="72px"
                    rounded="full"
                  />
                  <Box mt="9px" ml="3">
                    <Text fontSize="16px" fontWeight="600" color="#1E0F42">
                      {offer?.offerDetails?.sellerId?.name?.businessName}
                    </Text>
                    <Flex>
                      <ReactStars
                        count={5}
                        size={24}
                        value={offer?.offerDetails?.ratings?.avgRatings}
                        isHalf={true}
                        edit={false}
                        activeColor="#FF1A57"
                      />
                      <Text mt="6px">
                        ({offer?.offerDetails?.ratings?.avgRatings})
                      </Text>
                    </Flex>
                  </Box>
                  {/* <Image src={arrow} ml="30px" /> */}
                  <Box ml="25px" mt="25px">
                    {" "}
                    <IoIosArrowForward />
                  </Box>
                </Flex>
              </Box>
            </Box>
            <Box w="45%" mt="10px">
              <Box ml="40%">
                <Text
                  fontStyle="normal"
                  fontFamily="Commissioner"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="16px"
                  color="#6B6162"
                >
                  Quick Actions
                </Text>
                {offer?.offerDetails?.status === "pending" ? (
                  <Button
                    bg="#1E0F42"
                    color="white"
                    fontSize="14px"
                    borderRadius="5px"
                    mt="10px"
                    w="100%"
                    onClick={resumeOffer}
                  >
                    Resume Offer
                  </Button>
                ) : (
                  <Button
                    bg="#1E0F42"
                    color="white"
                    fontSize="14px"
                    borderRadius="5px"
                    mt="10px"
                    w="100%"
                    onClick={pauseOffer}
                  >
                    Pause Offer
                  </Button>
                )}
                <br />
                <Button
                  bg="#FFA3AC"
                  color="black"
                  fontSize="14px"
                  borderRadius="5px"
                  mt="10px"
                  w="100%"
                  onClick={deleteOffer}
                >
                  Delete Offer
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <EditChangeImage gallery={gallery} setGallery={setGallery} />
        <Box
          w={{ xl: "61%", lg: "60%", md: "94%" }}
          p={1}
          mt={4}
          ml={{ lg: 7 }}
          mx={{ lg: "", md: "auto" }}
        >
          <Box w="100%">
            <Box>
              <Box w="100%">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Seller name
                  </FormLabel>
                  <Input
                    w="100%"
                    fontSize="14px"
                    disabled
                    fontWeight="400"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    {...register("sellerName")}
                  />
                </FormControl>
              </Box>
              <Box w="100%" mt={4}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Offer name
                  </FormLabel>
                  <Input
                    w="100%"
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    placeholder="Discounted Sausage Rolls"
                    shadow="lg"
                    {...register("offerName", { required: true })}
                    bg="white"
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.offerName?.message}
                  </Text>
                </FormControl>
              </Box>
              <Box
                display="flex"
                justifyContent={"space-between"}
                w="100%"
                mt={4}
              ></Box>
            </Box>
            <Box w="100%" display="flex" justifyContent="space-between" gap={6}>
              <Box w="50%" display="flex" justifyContent="space-between">
                <Box w="46%" mt={3}>
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                      Quantity
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      {...register("quantity", { required: true })}
                      onKeyDown={(event) => {
                        if (event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]*/g,
                          ""
                        );
                      }}
                    />
                  </FormControl>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.quantity?.message}
                  </Text>
                </Box>
                <Box w="46%" mt={3}>
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                      Original Price
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      value={originalPrice}
                      onChange={handleOriginalPrice}
                      // {...register('orignalPrice', {required: true})}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.orignalPrice?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>
              <Box w="50%" display="flex" justifyContent="space-between">
                <Box w="46%" mt={3}>
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                      New Discount Price
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      type="number"
                      isDisabled={isDiscountDisabled}
                      value={discountedPrice}
                      onChange={handleDiscountedPrice}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.discountedPrice?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box w="47%" mt={3}>
                  <FormControl>
                    <FormLabel fontSize="14px" fontWeight="400" color="#6B6162">
                      Discount Percentage
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      type="number"
                      min={1}
                      // _disabled={{
                      //   borderColor: "red",
                      //   borderWidth: 1,
                      // }}
                      max={100}
                      cursor={"not-allowed"}
                      disabled
                      isDisabled={isPercentageDisabled}
                      value={percentagePrice}
                      onChange={handlePercentagePrice}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.percentagePrice?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box w="48%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Start Date
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    type="date"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    shadow="lg"
                    bg="white"
                    {...register("startDate", { required: true })}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.startDate?.message}
                  </Text>
                </FormControl>
              </Box>
              <Box w="48%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    End Date
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    type="date"
                    shadow="lg"
                    bg="white"
                    {...register("endDate")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.endDate?.message}
                  </Text>
                </FormControl>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box w="31%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Special Discounts(%)
                  </FormLabel>
                  <HStack>
                    <Checkbox
                      colorScheme="pink"
                      isChecked={isStudentChecked}
                      onChange={(e) =>
                        handleStudent(e.target.checked, "student")
                      }
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        Student
                      </Text>
                    </Checkbox>
                    <Input
                      type="number"
                      bg="white"
                      shadow="lg"
                      disabled={isStudentDisable}
                      value={studentPrice}
                      onChange={handleStudentPrice}
                      // {...register("studentDiscount")}
                    />
                  </HStack>
                  <HStack my={2}>
                    <Checkbox
                      colorScheme="pink"
                      isChecked={isNhsChecked}
                      onChange={(e) => handleStudent(e.target.checked, "nhs")}
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        NHS
                      </Text>
                    </Checkbox>
                    <Input
                      type="number"
                      isDisabled={isNHSDisable}
                      onChange={handleNhsPrice}
                      bg="white"
                      value={nhsPrice}
                      shadow="lg"
                      // {...register("NHSDiscount")}
                    />
                  </HStack>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.specialDiscount?.message}
                  </Text>
                </FormControl>
              </Box>

              <Box w="31%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Effective Price
                  </FormLabel>
                  <Input
                    type="text"
                    readOnly={true}
                    fontSize="14px"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    value={studentFinalPrice}
                    fontWeight="400"
                    // {...register("commision")}
                  ></Input>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.commission?.message}
                  </Text>
                </FormControl>
                <FormControl>
                  <Input
                    type="text"
                    readOnly={true}
                    fontSize="14px"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    value={nhsFinalPrice}
                    fontWeight="400"
                    // {...register("commision")}
                  ></Input>
                  <Text my={1} color="red" fontSize="xs">
                    {errors.commission?.message}
                  </Text>
                </FormControl>
              </Box>
              <Box w="31%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Age restricted product?
                  </FormLabel>
                  <Select
                    placeholder="Select One"
                    fontSize="14px"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    fontWeight="400"
                    {...register("ageRestrictedProduct")}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box w="48%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Cancellation Policy
                  </FormLabel>
                  <Textarea
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    type="text"
                    shadow="lg"
                    bg="white"
                    {...register("cancellationPolicy")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.cancellationPolicy?.message}
                  </Text>
                </FormControl>
              </Box>
              <Box w="48%" mt={3}>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Cancellation validity from the date of purchase
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    fontWeight="400"
                    type="number"
                    color="#4B4447"
                    shadow="lg"
                    bg="white"
                    h={20}
                    {...register("validUntill")}
                    onKeyDown={(event) => {
                      if (event.key === ".") {
                        event.preventDefault();
                      }
                    }}
                    onInput={(event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]*/g,
                        ""
                      );
                    }}
                  />

                  <Text my={1} color="red" fontSize="xs">
                    {errors.validUntill?.message}
                  </Text>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <FormControl>
                <FormLabel
                  fontSize="14px"
                  fontWeight="400"
                  color="#6B6162"
                  mb="0.5"
                >
                  Terms & Conditions
                </FormLabel>
                <Textarea
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  type="text"
                  shadow="lg"
                  bg="white"
                  {...register("terms")}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.terms?.message}
                </Text>
              </FormControl>
            </Box>
          </Box>

          <Box mt={5}>
            <Box>
              <Text fontSize="14px" color="#6B6162">
                Days
              </Text>
            </Box>
            <Box w="100%" shadow="lg" bg="white" rounded="lg" p={2}>
              <Box w="67%" m={4}>
                <Checkbox
                  onChange={(e) => changeCheckboxStatus(e, "allChecked")}
                  isChecked={isParentChecked}
                  colorScheme="pink"
                >
                  Select All Days
                </Checkbox>
                <Grid
                  templateRows="repeat(3, 1fr)"
                  templateColumns="repeat(3, 1fr)"
                  gap={10}
                >
                  {DAYSDATA.map((day, index) => (
                    <GridItem key={day.day} colSpan={1} w={160} display="flex">
                      <Box>
                        <Checkbox
                          isChecked={day?.isChecked}
                          colorScheme="pink"
                          value="child"
                          onChange={(e) => changeCheckboxStatus(e, index, day)}
                        >
                          <Text
                            fontSize="14px"
                            color="#3B3538"
                            w="74px"
                            h="21px"
                          >
                            {day.day}
                          </Text>
                        </Checkbox>
                      </Box>
                    </GridItem>
                  ))}
                </Grid>
                {/* <AllDays
            DAYS={users}
            changeCheckboxStatus={changeCheckboxStatus}
           
          /> */}
              </Box>
            </Box>
          </Box>

          <Box mt={6} w="100%" shadow="lg" bg="white" p={2} rounded="lg">
            <Box mt={3} w="73%">
              <RadioGroup colorScheme="pink" p={1}>
                <Box
                  m={5}
                  display="flex"
                  justifyContent={"space-between"}
                  w="full"
                >
                  <Box w="30%">
                    <Checkbox
                      value="Dine-in"
                      isChecked={delivery?.includes("Dine-in")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Dine-in
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box w="30%">
                    
                        <Checkbox
                      value="Take-away"
                      isChecked={delivery?.includes("Take-away")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Take-away
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box w="30%">
                  
                   
                        <Checkbox
                      value="Delivery"
                      isChecked={delivery?.includes("Delivery")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Delivery
                      </Text>
                    </Checkbox>
                  </Box>
                </Box>
                <Box
                  m={5}
                  display="flex"
                  justifyContent={"space-between"}
                  w="full"
                >
                  <Box w="30%">
                    <Checkbox
                      value="In-stores"
                      isChecked={delivery?.includes("In-stores")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" color="#3B3538">
                        In-stores
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box w="30%">
                    <Checkbox
                      value="Online"
                      isChecked={delivery?.includes("Online")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Online
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box w="30%">
                    <Checkbox
                      value="Home Services"
                      isChecked={delivery?.includes("Home Services")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" h="21px" color="#3B3538">
                        Home Services
                      </Text>
                    </Checkbox>
                  </Box>
                </Box>
                <Box
                  m={5}
                  display="flex"
                  justifyContent={"space-between"}
                  w="full"
                >
                  <Box w="30%">
                    <Checkbox
                      value="Other"
                      isChecked={delivery?.includes("Other")}
                      onChange={handleDelivery}
                      colorScheme="pink"
                    >
                      <Text fontSize="14px" color="#3B3538">
                        Other
                      </Text>
                    </Checkbox>
                  </Box>
                </Box>
              </RadioGroup>
            </Box>
          </Box>

          {(delivery.includes("Delivery") ||
            delivery.includes("Online") ||
            delivery.includes("Home Services")) && (
            <FormControl>
              <FormLabel
                fontSize="14px"
                fontWeight="400"
                color="#6B6162"
                mt="5"
              >
                Delivery Charges
              </FormLabel>
              <Input
                w="100%"
                fontSize="14px"
                fontWeight="400"
                color="#4B4447"
                placeholder="Delivery Charges"
                shadow="lg"
                type="number"
                {...register("deliveryCharges")}
                bg="white"
              />
              <p style={{ fontSize: "13px", color: "red", marginTop: "2px" }}>
                {errors.deliveryCharges?.message}
              </p>
              {/* <Text my={1} color="red" fontSize="xs">
                 {deliveryError}
                </Text> */}
            </FormControl>
          )}
          <Box
            mt={3}
            w="100%"
            shadow="lg"
            bg="white"
            p={2}
            rounded="lg"
            {...register("popularity")}
          >
            <Box mt={3} w="71%">
              <Box
                m={5}
                colorScheme="pink"
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  <Checkbox
                    colorScheme="pink"
                    value="peak"
                    isChecked={checked == "peak"}
                    onChange={toggle}
                  >
                    <Text fontSize="14px" w="69px" h="21px">
                      Peak
                    </Text>
                  </Checkbox>
                </Box>
                <Box>
                  <Checkbox
                    colorScheme="pink"
                    value="offPeak"
                    isChecked={checked == "offPeak"}
                    onChange={toggle}
                  >
                    <Text fontSize="14px" w="69px" h="21px">
                      Off-Peak
                    </Text>
                  </Checkbox>
                </Box>
                <Box>
                  <Checkbox
                    colorScheme="pink"
                    value="anyTime"
                    isChecked={checked == "anyTime"}
                    onChange={toggle}
                  >
                    <Text fontSize="14px" w="69px" h="21px">
                      Any Time
                    </Text>
                  </Checkbox>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box mt={5}>
              <Text fontSize="14px" w="113px" h="21px" color="#6B6162">
                Offer description{" "}
              </Text>
              <Textarea
                shadow="lg"
                bg="white"
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                fontSize="14px"
                color="#4B4447"
                {...register("description", { required: true })}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.description?.message}
              </Text>
            </Box>
            <Box w="full" align="center" mt={8}>
              <Box mt={2}>
                <Button
                  w="100%"
                  bg="#FF1A57"
                  color="white"
                  fontSize="14px"
                  fontFamily="Commissioner"
                  onClick={handleSubmit(updateOffers)}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Index;
