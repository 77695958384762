import React from "react";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { Box, Image, Input } from "@chakra-ui/react";
import { card_4, card_5, card_6, noImg } from "../assets/images";
import { icon_15 } from "../assets/icons";
import { singleFileUpload } from "../api/category";
import { useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useForm } from "react-hook-form";

const ChangeImages = ({ gallery, setGallery }) => {
  const dispatch = useDispatch();
  const imageRef = useRef();
  const imageOneRef = useRef();
  const imageTwoeRef = useRef();
  const [preview, setPreview] = useState(null);
  const [previewOne, setPreviewOne] = useState(null);
  const [previewTwo, setPreviewTwo] = useState(null);
  const { register } = useForm();

  // useEffect(() => {
  //   if (gallery?.length > 0) {
  //     setPreview(gallery[0] || card_5);
  //     setPreviewOne(gallery[1] || card_4);
  //     setPreviewTwo(gallery[2] || card_6);
  //   }
  // }, [gallery]);

 
  const handleChange = (e) => {
    const img = e.target.files[0];
    setPreview(URL.createObjectURL(img));
    if (img) {
      dispatch(singleFileUpload(img))
        .then((res) => setGallery([...gallery, res.data.image]))
        .catch((error) => console.log(error));
    }
  };

  const handleButtonClick = (e) => {
    imageRef.current.click();
  };

  const handleImageOne = (e) => {
    const image = e.target.files[0];
    setPreviewOne(URL.createObjectURL(image));
    if (image) {
      dispatch(singleFileUpload(image))
        .then((res) => {
          setGallery([...gallery, res.data.image]);
        })
        .catch((error) => console.log(error));
    }
  };
  const handleEditOne = (e) => {
    imageOneRef.current.click();
  };

  const handleImageTwo = (e) => {
    const imageOne = e.target.files[0];
    setPreviewTwo(URL.createObjectURL(imageOne));
    if (imageOne) {
      dispatch(singleFileUpload(imageOne))
        .then((res) => setGallery([...gallery, res.data.image]))
        .catch((error) => console.log(error));
    }
  };
  const handleEditTwo = (e) => {
    imageTwoeRef.current.click();
  };
  return (
    <Box flexDirection={["column", "row"]}>
      <Box
        w={["96%", "83%"]}
        ml={["7px", "30px"]}
        display="flex"
        mt="30px"
        justifyContent="space-between"
        flexDirection={["column", "row"]}
        gap={2}
        {...register("gallery")}
      >
        <Box w={["100%", "70%"]} position="relative">
          <Image
            src={preview || noImg}
            width="full"
            height="237px"
            objectFit={"cover"}
            rounded="lg"
          />
          {/* <Image
            src={icon_15}
            bg="#1E0F42"
            p="10px"
            borderRadius="100%"
            position="absolute"
            top="-10px"
            right="-6px"
            h="41px"
            w="41px"
            onClick={handleButtonClick}
          /> */}
          <Box
            bg="#1E0F42"
            color="white"
            p="12px"
            borderRadius="100%"
            position="absolute"
            top="-10px"
            right="-6px"
            h="41px"
            w="41px"
            onClick={handleButtonClick}
          >
            <FaRegEdit />
          </Box>

          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg"
            ref={imageRef}
            onChange={handleChange}
          ></Input>
        </Box>
        {/* <Box w={["100%", "28%"]} position="relative">
          <Image
            src={previewOne || card_5}
            w="full"
            h="110px"
            objectFit={"cover"}
            rounded="lg"
          />
          <Box
            bg="#1E0F42"
            color="white"
            p="12px"
            borderRadius="100%"
            position="absolute"
            top="-10px"
            right="-6px"
            h="41px"
            w="41px"
            onClick={handleEditOne}
          >
            <FaRegEdit />
          </Box>
          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg"
            ref={imageOneRef}
            onChange={handleImageOne}
          ></Input>
          <Image
            src={previewTwo || card_4}
            w="full"
            h="110px"
            mt="15px"
            objectFit={"cover"}
            rounded="lg"
          />

          <Box
            bg="#1E0F42"
            color="white"
            p="12px"
            borderRadius="100%"
            position="absolute"
            bottom="80px"
            right="-6px"
            h="41px"
            w="41px"
            onClick={handleEditTwo}
          >
            <FaRegEdit />
          </Box>

          <Input
            type="file"
            display="none"
            accept="image/png, image/gif, image/jpeg"
            ref={imageTwoeRef}
            onChange={handleImageTwo}
          ></Input>
        </Box> */}
      </Box>
    </Box>
  );
};

export default ChangeImages;
