import { getCategories, getSubCategories } from "../redux/reducers/offerSlice";
import api from "./index";

// **************  Get all Categories ******************
export const getAllCategories = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/admin/category/category-listing?limit=100");
      await dispatch(getCategories(res.data.data.categories));
      resolve(res.data.data.categories);
    } catch (err) {
      reject(err);
    }
  });
};

// **************  Get Sub Category ******************
export const getSubCategory = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/category/get-subCategory/${id}`);
      // console.log(res.data,"sub category responseeeeee");
        dispatch(getSubCategories(res.data.data.categories[0]?.subCategory));
      resolve(res.data.data.categories);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

//*****************  Add new category ***********************
export const addNewCategory = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/admin/category/create-category", apiData);
      // dispatch(getCategories(res.data.data.categories));
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};

//*****************************  sub category ***************** */
export const addSubCategory = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/admin/category/create-subCategory", apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//********************** delete main category *******************/
export const deleteCategory = (id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(`/admin/category/delete-category/${id}`);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//********************** update main category *******************/
export const updateCategory = (id, apiData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `/admin/category/update-mainCategory/${id}`,
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
//********************** update sub category *******************/
export const updateSubCategory = (id, apiData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `/admin/category/update-subCategory/${id}`,
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//**************** delete sub category *************** */
export const deleteSubCategory = (id) => async (dispatch) => {
  // console.log(id,"id")
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(`/admin/category/delete-subCategory/${id}`);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//*******************upload images category *************************/
export const singleFileUpload = (data) => async () => {
  const formData = new FormData();
  formData.append("image", data);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post("/common/file/image-upload", formData);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
