import {
  Box,
  Text,
  Image,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Checkbox,
  Tbody,
  Td,
  Flex,
  Stack,
  Hide,
  StatNumber,
  Stat,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { buyer, profile, User, user } from "../assets/images";
import profilee from '../assets/images/profilee.png'
import { checkStringPresence } from "../helpers";
import { useDispatch } from "react-redux";
function Items({ currentItems, handleChange, handleId, handleEditBtn }) {

  return (
    <>
      <Box mt="2%">
        <TableContainer
          rounded="md"
          w={{lg:"98%",md:"92%"}}
          mx={{lg:"auto",md:"auto"}}
          border="1px"
          borderColor="gray.300"
        >
          <Table size="sm">
            <Thead bg="gray.100">
              <Tr>
                <Th p={4}>
            
                  <Checkbox
                    isChecked={
                      currentItems?.filter((user) => user?.isChecked !== true)
                        .length < 1
                    }
                    name="allChecked"
                    onChange={handleChange}
                    colorScheme="red"
                  ></Checkbox>
                </Th>
                <Th>
                  <Text fontSize="xs">BUYER</Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign={"center"} ml="10px">
                    STATUS
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign={"center"}>
                    PURCHASES
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign={"center"}>
                    REDEEMED
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign={"center"}>
                    TOTAL SPEND
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign={"center"}>
                    WALLET
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign={"center"}>
                    ACCOUNT CREATED
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign={"center"}>
                    LAST ACTIVE
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs">ACTION</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {currentItems?.map((value) => (
                <Tr key={value._id} mt={4}>
                  <Td p={4} mt={4}>
                    <Checkbox
                      isChecked={value.isChecked || false}
                      colorScheme="red"
                      name={value._id}
                      onChange={handleChange}
                    />
                  </Td>
                  <Td w="40%">
                    <Flex alignItems="center" gap={1}>
                      <Image
                        borderRadius="full"
                        boxSize="40px"
                        w={8}
                        objectFit="cover"
                        h={8}
                        src={value.profileImage === "user.png" ? profilee : checkStringPresence(value.profileImage)?profilee:value.profileImage }
                      />
                      
                      <Text
                        fontFamily="Commissioner"
                        fontWeight={600}

                        fontSize={12}
                        color="#3B3538"
                        mb={1}
                        textAlign="justify"
                        whiteSpace="normal"
                      >
                        {value.name}
                        <br />
                        <Text fontSize="8px" color="gray.500">
                          {/* {value.address} */}
                        </Text>
                      </Text>
                    </Flex>
                  </Td>
                  <Td minW="80px" ml="10px">
                    <Box
                      bg={
                        value.accountStatus === "approved"
                          ? "#B5D779"
                          : value.accountStatus === "Pending"
                          ? "#B5D779"
                          : value.accountStatus === "active"
                          ? "#B5D779"
                          : value.accountStatus === "Paused"
                          ? "#FDE68B"
                          : "orange"
                      }
                      borderRadius="20px"
                      p={2}
                    >

                      <Text textAlign="center" fontSize={12} fontWeight={600}>
                        {value.accountStatus}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Text
                      fontFamily="Commissioner"
                      fontStyle="normal"
                      fontWeight={600}
                      fontSize="14px"
                      lineHeight="21px"
                      textAlign="center"
                      color="#3B3538"
                    >
                      {value.purchases}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontFamily="Commissioner"
                      fontStyle="normal"
                      fontWeight={600}
                      fontSize="14px"
                      lineHeight="21px"
                      textAlign="center"
                      color="#3B3538"
                    >
                      {value.redeemed}
                    </Text>
                  </Td>
                  <Td>
                    <Stat>
                      <StatNumber
                        fontFamily="Commissioner"
                        fontStyle="normal"
                        fontWeight={600}
                        fontSize="14px"
                        lineHeight="21px"
                        textAlign="center"
                        color="#3B3538"
                      >
                        {value.spend}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>
                    {" "}
                    <Stat>
                      <StatNumber
                        fontFamily="Commissioner"
                        fontStyle="normal"
                        fontWeight={600}
                        fontSize="14px"
                        lineHeight="21px"
                        textAlign="center"
                        color="#3B3538"
                      >
                        £ {value.walletBalance}
                      </StatNumber>
                    </Stat>
                  </Td>
                  <Td>
                    <Text
                      fontFamily="Commissioner"
                      fontStyle="normal"
                      fontWeight={600}
                      fontSize="14px"
                      lineHeight="21px"
                      textAlign="center"
                      color="#3B3538"
                    >
                      {moment(value.createdAt).format("DD/MM/YYYY")}
                    </Text>
                  </Td>

                  <Td>
                    <Text
                      fontFamily="Commissioner"
                      fontStyle="normal"
                      fontWeight={600}
                      fontSize="14px"
                      lineHeight="21px"
                      textAlign="center"
                      color="#3B3538"
                    >
                      {moment(value.lastActive).format("DD/MM/YYYY")}

                      {/* {value.lastActive} */}
                    </Text>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="white"
                      color="#FF1A57"
                      border="1px"
                      borderColor="#FF1A57"
                      fontSize="xs"
                      borderRadius="lg"
                      px={5}

                      onClick={() => {
                        handleId(value._id);
                      }}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

function BuyerTable({ itemsPerPage, items, handleChange, handleId ,getBuyers ,setUsers ,totalCount}) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const dispatch = useDispatch()
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(items);
    setPageCount(Math.ceil(totalCount / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    let pageNo = event.selected + 1;
    console.log(pageNo)
    dispatch(getBuyers(pageNo)).then((res)=>{
      // console.log(res.data)
      setUsers(res?.data?.buyers)
    }).catch((err)=>{
      console.log(err)
    })
    const newOffset = (event.selected * itemsPerPage) % totalCount
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );

    setItemOffset(newOffset);
  };

  return (
    <>
      <Items
        currentItems={currentItems}
        handleChange={handleChange}
        handleId={handleId}
      />

      <Box bg="white" w="96%" mx="auto">
        <ReactPaginate
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item me-auto"
          previousLinkClassName="page-link ms-auto bg-transparent"
          nextClassName="page-item ms-auto"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center shadow"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Box>
    </>
  );
}

export default BuyerTable;
