import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tickets: [],
  ticket: {},
};

const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    getTickets: (state, action) => {
      state.tickets = action.payload;
      
    },
    getTicket: (state, action) => {
        state.ticket = action.payload;
        
      },
  },
});

export const { getTickets, getTicket } = ticketSlice.actions;

export default ticketSlice.reducer;