import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  Center,
  Textarea,
  VStack,
  StatNumber,
  Stat,
  HStack,
  Spinner,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import Layout from "../../components/Layout";
import { icon_16, icon_17, offer, search } from "../../assets/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addNewCategory } from "../../api/category";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  deleteMultipleOffers,
  getAlloffers,
  updateOffersStatus,
} from "../../api/offer";
import Header from "../../components/Header";

import { getAllBillingReport } from "../../api/billingReport";
import BillingTable from "../../components/BillingTable";

const BillingReport = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState("roffers");
 
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [totalCount,setTotalCount] = useState(null)
  const [searchData,setSearchData] = useState('')
 
  const getBilling = (pageNo, search, limit) => {
    setType(type);
    setType(true);
    dispatch(getAllBillingReport(pageNo, search, limit))
      .then((res) => {
        setLoading(false);
        setUsers(res?.data.data);
        setTotalCount(res?.data.totalCount)
        console.log(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBilling(null,'');
  }, []);

  const handleSearch = () =>{
    if(searchData != ''){
      getBilling(null,searchData)
    }
  }

  return (
    <Layout>
      {isLoading ? (
        <HStack w="full" h="100vh" justifyContent="center">
          <Spinner size="lg" color="red" />
        </HStack>
      ) : (
        <>
          <Box
            w={["100%", "98%", "93%"]}
            mx={{ lg: "auto", md: "auto" }}
            display="flex"
            justifyContent="space-between"
          >
            <Text
              fontFamily="Rubik"
              color="#2E1A47"
              fontSize={["20px", "34px"]}
              fontWeight="600"
              lineHeight="40px"
              letter="-4%"
              w="full"
            >
              Billing Report
            </Text>
            <Header />
          </Box>
          <Box
            w={{ xl: "100%", lg: "100%", md: "96%" }}
            mx={{ lg: "auto", md: "auto" }}
          >
            <Tabs
              variant="soft-rounded"
              colorScheme="green"
              w={["100%", "100%"]}
            >
              <TabList ml={{ lg: "3", md: "2" }} mt={"3"} gap={"7"}>
              </TabList>
              <TabPanels>
                <TabPanel ml={{ lg: "3" }} mt={"2"} w={["100%", "100%"]}>
                  <Box flexDirection={["column", "row"]}>
                    <Box
                      w={["100%", "100%"]}
                      mx={{ lg: "auto", md: "auto" }}
                      mt="15px"
                      flexDirection={["column", "row"]}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Text fontSize="14px" fontWeight="400" color="#6B6162">
                          Search Offers
                        </Text>
                        <Text
                          fontSize="14px"
                          color="#6B6162"
                          fontWeight="400"
                          mr={["0", "7"]}
                        ></Text>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={["column", "row"]}
                        gap={2}
                      >
                        <InputGroup w={["full", "85%"]} mt={["2", "0"]}>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="#FF1A57" />}
                          />
                          <Input
                            w={["100%", "full"]}
                            type="tel"
                            bg="white"
                            shadow="lg"
                            pl={12}
                            _focus={{
                              outline: "none",
                              border: "none",
                            }}
                            _after={{
                              outline: "none",
                              border: "none",
                            }}
                            _active={{
                              outline: "none",
                            }}
                            borderRadius={5}
                            borderColor="#B1ABA9"
                            placeholder="Search for  seller name ..."
                            onChange={(e)=>setSearchData(e.target.value.trim())}
                          />
                        </InputGroup>
                        <Box w={["18%", "15%"]}>
                          <Button
                            bg="primary"
                            variant="outline"
                            w={["20%", "90%"]}
                            border="none"
                            mt={["2", "0"]}
                            onClick={handleSearch}
                          >
                            <Text fontSize="xs" color="white">
                              Search
                            </Text>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {users?.length > 0 ? (
                    <Box w={"auto"}>
                      <BillingTable itemsPerPage={10} items={users} totalCount={totalCount} getBilling={getBilling} />
                    </Box>
                  ) : (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignContent={"center"}
                      mt={"50px"}
                    >
                      <Text fontSize={"20px"} fontWeight={"bold"}>
                        No Report Found
                      </Text>
                    </Box>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </>
      )}
    </Layout>
  );
};

export default BillingReport;
