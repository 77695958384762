export const DAYS = [
    {
      id: 0,
      day: "Sunday",
      startTime:'',
      endTime:''
    },
    {
      id: 1,
      day: "Monday",
      startTime:'',
      endTime:''
    },
    {
      id: 2,
      day: "Tuesday",
      startTime:'',
      endTime:''
    },
    {
      id: 3,
      day: "Wednesday",
      startTime:'',
      endTime:''
    },
    {
      id: 4,
      day: "Thursday",
      startTime:'',
      endTime:''
    },
    {
      id: 5,
      day: "Friday",
      startTime:'',
      endTime:''
    },
    {
      id: 6,
      day: "Saturday",
      startTime:'',
      endTime:''
    },
  ];

  export const DAYSDATA = [
    {
      id: 0,
      day: "Sunday",
    },
    {
      id: 1,
      day: "Monday",
    },
    {
      id: 2,
      day: "Tuesday",
    },
    {
      id: 3,
      day: "Wednesday",
    },
    {
      id: 4,
      day: "Thursday",
    },
    {
      id: 5,
      day: "Friday",
    },
    {
      id: 6,
      day: "Saturday",
    },
  ];

  export const OFFPEAK = [
    {
      id: 0,
      day: "Sunday",
      startTime:'',
      endTime:''
    },
    {
      id: 1,
      day: "Monday",
      startTime:'',
      endTime:''
    },
    {
      id: 2,
      day: "Tuesday",
      startTime:'',
      endTime:''
    },
    {
      id: 3,
      day: "Wednesday",
      startTime:'',
      endTime:''
    },
    {
      id: 4,
      day: "Thursday",
      startTime:'',
      endTime:''
    },
    {
      id: 5,
      day: "Friday",
      startTime:'',
      endTime:''
    },
    {
      id: 6,
      day: "Saturday",
      startTime:'',
      endTime:''
    },
  ];
  export const OPENINGTIME = [
    {
      id: 0,
      day: "Sunday",
      startTime:'',
      endTime:''
    },
    {
      id: 1,
      day: "Monday",
      startTime:'',
      endTime:''
    },
    {
      id: 2,
      day: "Tuesday",
      startTime:'',
      endTime:''
    },
    {
      id: 3,
      day: "Wednesday",
      startTime:'',
      endTime:''
    },
    {
      id: 4,
      day: "Thursday",
      startTime:'',
      endTime:''
    },
    {
      id: 5,
      day: "Friday",
      startTime:'',
      endTime:''
    },
    {
      id: 6,
      day: "Saturday",
      startTime:'',
      endTime:''
    },
  ];

  export const BUTTON_DATA = [
    { id: 1, type: "peak", title: "Peak" },
    { id: 2, type: "offPeak", title: "Off Peak" },
    { id: 3, type: "anyTime", title: "Any Time" },
  ];
  export const HEADER_REPORTS = [
    { f1:"Seller Name",f2:"BUYER SUBSCRIBED",f3:"EARNING",f4:"REFUND SELLER",f5:"PAYMENT GATEWAY",f6:"MERCHANT",f7:"COMMISSION",f8:"Action" },
   
  ];


  