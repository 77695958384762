import { getAllBuyers, getBuyer } from "../redux/reducers/buyerSlice";
import api from "./index";

export const getBuyers = (pageNo) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/buyer/get-buyers?pageNo=${pageNo}`);
      resolve(res.data);
      dispatch(getAllBuyers(res.data.data));
    } catch (err) {
      reject(err);
    }
  });
};

export const getBuyerById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/buyer/get-buyer-details/${id}`);
      resolve(res.data);
      dispatch(getBuyer(res.data.data));
    } catch (err) {
      reject(err.data.message);
    }
  });
};


export const updateBuyerDetails = (id, apidata) => async (dispatch) => {
  // return console.log(apidata);
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `/admin/buyer/update-buyer-details/${id}`,
        apidata
      );
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

// ---------- change buyer status from pending to approved -------------
export const changeBuyerStatus = (apiData) => async (dispatch) => {
  // return console.log(apiData);
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put("/admin/buyer/change-bulk-status", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

export const deleteBuyers = (apidata) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete("/admin/buyer/delete-bulk-buyer", {
        data: apidata,
      });
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
