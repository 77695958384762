import { allOffers, getOffer } from "../redux/reducers/offerSlice";
import api from "./index";

//**************** get all offer **************** */
export const getAlloffers = (pageNo) => async (dispatch) => {

  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/offer/get-offers-listing?pageNo=${pageNo+1}`);
      resolve(res.data);
      dispatch(allOffers(res.data.data));
    } catch (err) {
      console.log(err);
    }
  });
};

//************************** create new offer ************** */

export const createOffer = (apiData) => async (dispatch) => {
  const { days } = apiData;
  const newObj = days
    .filter((item) => item.isChecked === true)
    .map((item) => {
      delete item.id;
      delete item.isChecked;
      return item["day"];
    });

  const newApiData = Object.assign(apiData, { days: newObj });
  // return console.log(newApiData,"======faiza ===>>>>>> =")
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/admin/offer/create-offer", newApiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

//*************** update offer **************************** */
export const updateOffer = (id, apiData) => async (dispatch) => {
  const { days } = apiData;
  const newObj = days
    .filter((item) => item.isChecked === true)
    .map((item) => {
      delete item.id;
      delete item.isChecked;
      return item["day"];
    });
  const newApiData = Object.assign(apiData, { days: newObj });
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/offer/update-offer/${id}`, newApiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
//*************** update multiple offer's status **************************** */
export const updateOffersStatus = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `/admin/offer/change-multiple-offers-status`,
        apiData
      );
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

//****************** is-pause-offer  ****************/
export const isPauseOffer = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/offer/is-pause-offer/${id}`);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
//****************** add-redemption-offer  ****************/
export const redemption = ({id,days}) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/offer/add-redemption/${id}?days=${days}`);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

//******************* delete offer  ************************ */
export const deleteSingleOffer = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(`/admin/offer/delete-offer/${id}`);
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};

//********************** get single offer details ************ */
// export const singleOffer = (id) => async (dispatch) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await api.get(`/admin/offer/get-offers-details/${id}`);
//       resolve(res.data);
//       dispatch(getOffer(res.data.data));
//     } catch (err) {
//       reject(err.data.message);
//     }
//   });
// };

export async function singleOffer({ signal, offerId }) {
  const apiUrl = `admin/offer/get-offers-details/${offerId}`;
  const response = await api.get(apiUrl);
  // console.log("response", response);
  return response.data;
}

//---------------delete multiple offers------------
export const deleteMultipleOffers = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete("/admin/offer/delete-bulk-offer", {
        data: apiData,
      });
      resolve(res.data);
    } catch (err) {
      reject(err.data.message);
    }
  });
};
