import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  addNewCategory,
  addSubCategory,
  getAllCategories,
  updateSubCategory,
} from "../../api/category";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const schema = yup
  .object({
    subcategory: yup.string().required("Sub Category is required"),
  })
  .required();

const AddSubCategoryModal = ({ isOpen, onClose, setCategory, data }) => {
  const { id, subcategory, type } = data;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (type === "Edit") {
      reset({
        subcategory,
      });
    } else {
      reset({
        subcategory: "",
      });
    }
  }, [data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleAddSubCategory = (data) => {
    setIsLoading(true);
    if (type === "Add") {
      const apiData = {
        id,
        category: data.subcategory,
      };
      dispatch(addSubCategory(apiData))
        .then((res) => {
          setIsLoading(false);
          if (res.statusCode === 200) {
            toast.success(res.message);
            reset({
              subcategory: "",
            });
            dispatch(getAllCategories())
              .then((res) => setCategory(res))
              .catch((err) => console.log(err));
            onClose();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.data.message);
        });
    } else {
      const apiData = {
        categoryName: data.subcategory,
      };
      dispatch(updateSubCategory(id, apiData))
        .then((res) => {
          setIsLoading(false);
          if (res.statusCode === 200) {
            toast.success(res.message);
            reset({
              subcategory: "",
            });
            dispatch(getAllCategories())
              .then((res) => setCategory(res))
              .catch((err) => console.log(err));
            onClose();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.data.message);
        });
    }
  };

  return (
    <>
      <Box w={["100%", "25%"]}></Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{type} Sub Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text fontSize="14px" fontWeight="400" color="#6B6162">
                  Sub Category Name
                </Text>
              </FormLabel>
              <Input
                placeholder="Sub Category"
                fontSize="14px"
                fontWeight="400"
                color="#4B4447"
                border="1px"
                borderColor="gray.300"
                {...register("subcategory")}
              />
              <Text my={1} color="red" fontSize="xs">
                {errors.subcategory?.message}
              </Text>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              bg="#FF1A57"
              color="white"
              mr={3}
              isLoading={isLoading}
              onClick={handleSubmit(handleAddSubCategory)}
            >
             Save
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                reset({
                  subcategory: "",
                });
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddSubCategoryModal;
