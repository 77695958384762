import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  Button,
  FormControl,
  FormLabel,
  Hide,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import * as yup from "yup";
import React, { useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changeOldPassword, getAdminDetails } from "../../api/user";
import { icon_10, sellericon } from "../../assets/icons";
import { placeholder, profile } from "../../assets/images";
import Layout from "../../components/Layout";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../components/Header";
import { editProfile } from "../../api/admin";
import { singleFileUpload } from "../../api/category";
import { useEffect } from "react";
import { toast } from "react-toastify";
import UpdateProfile from "../../components/UpdateProfile";
import { useNavigate } from "react-router-dom";


const schema = yup.object({
  oldPassword: yup
    .string()
    .min(5, "Password must be 5 characters at minimum")
    .max(16),
  newPassword: yup
    .string()
    .min(5, "Password must be 5 characters at minimum")
    .max(16),
    confirmPassword : yup
    .string()
    .min(5, "Password must be 5 characters at minimum")
    .max(16)
    .oneOf([yup.ref("newPassword"), null], "Password must match"),
});

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [show, setShow] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [preview, setPreview] = useState(null);
  const [showConfrimPassword, setShowConfrimPassword] = React.useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const imageRef = useRef();
  const { admin } = useSelector((state) => state.admin);

  const handleClick = () => setShow(!show);
  const handleClickOnNew = () => setShowNewPassword(!showNewPassword);
  const handleClickOnConfirm = () =>
    setShowConfrimPassword(!showConfrimPassword);
  const navigate = useNavigate();

  const handlePassword = (e) => {
    setChangePassword(e.target.value);
  };
  // console.log(changePassword);

  useEffect(() => {
    if (admin) {
      reset({
        firstName: admin?.name?.firstName,
        lastName: admin?.name?.lastName,
      });
      setProfileImage(admin.profileImage);
    }
  }, [admin]);

  const resetPassword = (data) => {
    const apiData = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    // return console.log(apiData,"apiData")
    setIsLoading(true);
    dispatch(changeOldPassword(apiData))
      .then((res) => {
        setIsLoading(false);
        toast.success(res.message);
        navigate("/");
      })
      .catch((err) => {
        toast.error(err.message);
        setIsLoading(false);
      });
  };

  const discardChanges = () =>{
    reset({
      oldPassword:"",
      newPassword:"",
      confirmPassword:""
    })
  }

  return (
    <>
      <Layout boxSize={"borderBox"}>
        <Box
          w={{ lg: "98%", md: "98%" }}
          mx={{ lg: "auto", md: "" }}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="#2E1A47"
            fontWeight="600"
            FontSize="20px"
            variant="ghost"
            onClick={() => navigate(-1)}
          >
            {"< Back"}
          </Button>
          <Header />
        </Box>
        <Box w="95%" mx="auto">
          <Text
            fontFamily="Rubik"
            color="#2E1A47"
            fontSize={["30px", "34px"]}
            fontWeight={["550", "600"]}
            lineHeight="40px"
            letter="-4%"
          >
            Profile
          </Text>
        </Box>
        <UpdateProfile />
        <Box w={{ lg: "50%", md: "94%" }} ml={{ lg: 8, md: 6 }} mt={5}>
          <Text
            fontSize="34px"
            fontWeight="600"
            color="#1E0F42"
            fontFamily="Rubik"
          >
            Reset password
          </Text>

          <FormControl mt={5}>
            <FormLabel mb="0.5">
              <Text fontSize="14px" fontWeight="400" color="#6B6162">
                Old Password
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                w={["95%", "100%"]}
                border="1px"
                borderColor="gray.400"
                pr="4.5rem"
                name="oldPassword"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                {...register("oldPassword")}
              />
              <InputRightElement width="4.5rem">
                {show ? (
                  <ViewIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClick}
                  />
                ) : (
                  <ViewOffIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClick}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            {errors?.oldPassword && (
              <Text my={1} color="red" fontSize="xs">
                Old Password is required
              </Text>
            )}

            <FormLabel mt={8} mb="0.5">
              <Text fontSize="14px" fontWeight="400" color="#6B6162">
                New Password
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                w={["95%", "100%"]}
                border="1px"
                borderColor="gray.400"
                pr="4.5rem"
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter password"
                {...register("newPassword")}
              />
              <InputRightElement width="4.5rem">
                {showNewPassword ? (
                  <ViewIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClickOnNew}
                  />
                ) : (
                  <ViewOffIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClickOnNew}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            {errors?.newPassword && (
              <Text my={1} color="red" fontSize="xs">
                New Password is required
              </Text>
            )}

            <FormLabel mt={8} mb="0.5">
              <Text fontSize="14px" fontWeight="400" color="#6B6162">
                Confirm Password
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                w={["95%", "100%"]}
                border="1px"
                borderColor="gray.400"
                pr="4.5rem"
                name="confirmPassword"
                type={showConfrimPassword ? "text" : "password"}
                placeholder="Enter password"
                {...register("confirmPassword")}
              />
              <InputRightElement width="4.5rem">
                {showConfrimPassword ? (
                  <ViewIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClickOnConfirm}
                  />
                ) : (
                  <ViewOffIcon
                    h="1.75rem"
                    size="lg"
                    color="#6B6162"
                    onClick={handleClickOnConfirm}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            {errors?.confirmPassword && (
              <Text my={1} color="red" fontSize="s">
                Password must match
              </Text>
            )}
          </FormControl>
          <Box mt={10} w={["95%", "100%"]}>
            <Button
              fontSize="14px"
              fontWeight="600"
              color="#FFFFFF"
              bg="#FF1A57"
              w="full"
              isLoading={isLoading}
              onClick={handleSubmit(resetPassword)}
            >
              Confirm changes
            </Button>
            <Button
              w="full"
              mt={5}
              mb={5}
              border="1px"
              borderColor="#1E0F42"
              onClick={() => discardChanges()}
            >
              <Text fontSize="14px" fontWeight="600" color="#1E0F42">
                Discard changes
              </Text>
            </Button>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default ResetPassword;
