import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// const firebaseConfig = {
//     apiKey: "AIzaSyBQoqLXp8Sc684Hs_ME5X2pM5kh3Ygne9Y",
//     authDomain: "roffers-bb7ba.firebaseapp.com",
//     projectId: "roffers-bb7ba",
//     storageBucket: "roffers-bb7ba.appspot.com",
//     messagingSenderId: "543181605341",
//     appId: "1:543181605341:web:d55f1bd2b2411048f40e34",
//     measurementId: "G-M450YHNDPG",
//   };

const firebaseConfig = {
  apiKey: "AIzaSyBGHgDGQrPi4lKKmSRP1Dk5K67au6mGYFg",
  authDomain: "primo-offers.firebaseapp.com",
  projectId: "primo-offers",
  storageBucket: "primo-offers.appspot.com",
  messagingSenderId: "713701787690",
  appId: "1:713701787690:web:4791ac4a151a6c11fa9b53",
  measurementId: "G-2E4NJNTWDR"
};

   const firebaseApp = initializeApp(firebaseConfig);

  export const messaging = getMessaging(firebaseApp);
  


  export const requestForToken = async (setTokenFound, setCurrentToken) => {
    return await getToken(messaging, {vapidKey:'BBlPa3C3bys8Hs-p8-Srr5OdxP38lsHE_W9EQ4wpQKVB1K5D15tTTALulNOnv4xcz7ReO3EwzpvxZqJZRN-lPng'}).then((currentToken) => {
      if (currentToken) {
        // setTokenFound(true);
        setCurrentToken(currentToken)
      } else {
        setTokenFound(false);
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});