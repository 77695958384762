import { getAllsellers, getSeller } from "../redux/reducers/sellerSlice";
import api from "./index";

//************** get all seller ***************

export const getAllSellerData = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/admin/seller/get-sellers?limit=100");
      resolve(res.data);
      dispatch(getAllsellers(res.data.data));
    } catch (error) {
      reject(error);
    }
  });
};

//**************** seller status change *********** */

export const changeSellersStatus = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(`/admin/seller/bulk-change-status`, apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//**************** seller change status *********** */

export const changeStatus = (apiData,id) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.put(
        `/admin/seller/change-seller-status/${id}`,
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//**************** add seller *********** */

export const addNewSeller = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(`/admin/seller/create-seller`, apiData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//**************** get seller info *********** */

export const getSellerById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/admin/seller/get-seller-info/${id}`);
      resolve(res.data);
      dispatch(getSeller(res.data.data.sellerDetails));
    } catch (error) {
      reject(error);
    }
  });
};

//**************** update seller info *********** */

export const updateSellerInfo = (id, apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        `/admin/seller/update-seller-profile/${id}`,
        apiData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
export const uploadMultipleImage = (images) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const imageData = new FormData();
    [...images].forEach((image) => {
      imageData.append("image", image);
    });
    try {
      const res = await api.post(
        `/common/file/multiple-image-upload`,
        imageData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
