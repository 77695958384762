import { combineReducers } from "@reduxjs/toolkit";
import authUser from "./userSlice";
import offers from "./offerSlice";
import admin from './adminSlice'
import sellers from './sellerSlice'
import buyers from './buyerSlice'
import tickets from './ticketSlice'

const rootReducer = combineReducers({
  authUser,
  offers,
  admin,
  sellers,
  buyers,
  tickets
});
export default rootReducer;
