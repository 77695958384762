import SecureLS from "secure-ls";

let ls = new SecureLS();

export function setToLocalStorage(key, data) {
    ls.set(key, data)
    
}

export function getFromLocalStorage(key) {
    let getData = ls.get(key)
    return getData;
    
}

export function removeFromLocalStorage(key) {
    ls.remove(key)
}
export function removeAllFromLocalStorage() {
    ls.removeAll()
}


export function checkString(url){
  
    const substring = "roffer-storage.s3.eu-west-2.amazonaws.com";
  
    const isSubstringPresent = url?.includes(substring);
    return isSubstringPresent
  } 
  
  export function checkStringPresence(searchString) {
    const mainString = "./static/media/Buyer.e40be713ca6301d5ee54.jpg";
    return mainString.includes(searchString);
  }