import {
    Box,
    Text,
    Image,
    Button,
    InputGroup,
    InputLeftElement,
    Input,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Checkbox,
    Tbody,
    Td,
    Flex,
    Stack,
    Hide,
  } from "@chakra-ui/react";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import ReactPaginate from "react-paginate";
import { profile } from "../assets/images";
import { userSvg } from "../assets/icons";
  
  function Items({ currentItems, handleChange, handleId }) {
    return (
      <Box mt="2%"  key={currentItems?._id}>
        <TableContainer rounded="md" border="1px" borderColor="gray.300">
          <Table size="sm">
            <Thead bg="gray.100">
              <Tr>
                <Th p={4}>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      currentItems?.filter((user) => user?.isChecked !== true)
                        .length < 1
                    }
                    name="allChecked"
                    onChange={handleChange}
                  ></Checkbox>
                </Th>
                <Th>
                  <Text fontSize="xs" ml={1}>
                    ADMIN NAME
                  </Text>
                </Th>
                <Th>
                  <Text textAlign="center" fontSize="xs">
                    STATUS
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign="center">
                    EMAIL
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign="center">
                    ACCOUNT CREATED
                  </Text>
                </Th>
                <Th>
                  <Text fontSize="xs" textAlign="center">
                    ACTION
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {/* {console.log(currentItems,"===fsssfds=")} */}
              {currentItems?.map((value) => (
                
                <Tr>
                  <Td p={4}>
                    <Checkbox
                      colorScheme="red"
                      isChecked={value.isChecked || false}
                      name={value._id}
                      onChange={handleChange}
                    />
                  </Td>
                  <Td>
                    <Flex>
                      <Image
                        borderRadius="full"
                        boxSize="30px"
                        src={value.profileImage || userSvg}
                        ml="2px"
                      />
                      <Text
                        fontWeight={700}
                        fontSize={15}
                        ml="10px"
                        color="gray.600"
                      >
                        {value.name.firstName}
                        <br />
                        <Text fontSize={12} color="gray.500">
                          {value.name.lastName} | {value?.address?.address}
                        </Text>
                      </Text>
                    </Flex>
                  </Td>
                  <Td display="flex" justifyContent="center">
                    <Box
                      minW="80px"
                      bg={
                        value.status === "active"
                          ? "#B5D779"
                          : value.status === "pending"
                          ? "#FFA3AC"
                          : value.status === "suspend"
                          ? "#CCC9C7"
                          : value.status === "reject"
                          ? "#FDE68B"
                          : "orange"
                      }
                      borderRadius="20px"
                      p={1}
                      py={2}
                    >
                      <Text textAlign="center" fontSize={14} fontWeight={600}>
                        {value.status}
                      </Text>
                    </Box>
                  </Td>
  
                  <Td>
                    <Text
                      fontSize="xs"
                      textAlign="center"
                      fontWeight="bold"
                      color="gray.600"
                    >
                      {value.email}
                    </Text>
                  </Td>
                 
                  <Td>
                    <Text
                      fontSize="xs"
                      textAlign="center"
                      fontWeight="bold"
                      color="gray.600"
                    >
                      {moment(value.lastActive).format("ll")}
                    </Text>
                  </Td>
                  
                  <Td>
                    <Button
                      colorScheme="white"
                      color="#FF1A57"
                      border="1px"
                      borderColor="pink.500"
                      h="35px"
                      fontSize="xs"
                      borderRadius="lg"
                      p={5}
                      onClick={() => {
                        handleId(value._id);
                      }}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
  
  function AdminTable({ itemsPerPage, items, handleChange, handleId }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(items?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items?.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items?.length;
      setItemOffset(newOffset);
    };
  
    return (
      <>
        <Items
          currentItems={currentItems}
          handleChange={handleChange}
          handleId={handleId}
        />
        <Box bg="white">
          <ReactPaginate
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link "
            previousClassName="page-item me-auto "
            previousLinkClassName="page-link ms-auto bg-transparent"
            nextClassName="page-item ms-auto"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination justify-content-center shadow"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </Box>
      </>
    );
  }
  
  export default AdminTable;
  