import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Hide,
  Input,
  Text,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import { useForm } from "react-hook-form";

const EditCategory = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: "",
  });

  console.log(state, "state");

  const handleBackButton = () => {
    navigate("/categories");
  };
  return (
    <>
      <Layout>
        <Box w="98%" mx="auto" flexDirection={["column", "row"]}>
          <Box w="100%" mx="auto" display="flex" justifyContent="space-between">
            <Button onClick={handleBackButton} variant="ghost">
              <Text
                color="#1E0F42"
                fontWeight="600"
                fontSize="20px"
                fontFamily="Rubik"
              >
                {"<"} Back
              </Text>
            </Button>
            <Header />
          </Box>
          <Box w={["100%", "60%"]}>
            <Box>
              <Text fontSize="34px" fontWeight="600" color="#1E0F42">
                Edit category
              </Text>
            </Box>
            <Box mt={6}>
              <FormControl>
                <FormLabel>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="-2"
                  >
                    Add new category
                  </Text>
                </FormLabel>
                <Input
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  border="1px"
                  borderColor="gray.300"
                  placeholder="Category name"
                  {...register("category", {required:true})}
                />
              </FormControl>
            </Box>
            <Box mt={6}>
              <FormControl>
                <FormLabel>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="-2"
                  >
                    Sub-category
                  </Text>
                </FormLabel>
                <Input
                  placeholder="Subcategory 01"
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  border="1px"
                  borderColor="gray.300"
                />
              </FormControl>
            </Box>
            <Box mt={6}>
              <FormControl>
                <FormLabel>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="-2"
                  >
                    Sub-category
                  </Text>
                </FormLabel>
                <Input
                  placeholder="Subcategory 02"
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  border="1px"
                  borderColor="gray.300"
                  {...register("sub-category", {required:true})}
                />
              </FormControl>
            </Box>
            <Box mt={6}>
              <FormControl>
                <FormLabel>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="-2"
                  >
                    Sub-category
                  </Text>
                </FormLabel>
                <Input
                  placeholder="Subcategory 03"
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  border="1px"
                  borderColor="gray.300"
                />
              </FormControl>
            </Box>
            <Box mt={6}>
              <FormControl>
                <FormLabel>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="-2"
                  >
                    Sub-category
                  </Text>
                </FormLabel>
                <Input
                  placeholder="Subcategory 04"
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  border="1px"
                  borderColor="gray.300"
                />
              </FormControl>
            </Box>
            <Box mt={6}>
              <FormControl>
                <FormLabel>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="-2"
                  >
                    Sub-category
                  </Text>
                </FormLabel>
                <Input
                  placeholder="Subcategory 05"
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  border="1px"
                  borderColor="gray.300"
                />
              </FormControl>
            </Box>
            <Box mt={8}>
              <Button bg="#1E0F42" rounded="md">
                <Text color="white" fontSize="14px" fontWeight="600">
                  + Add more sub-category
                </Text>
              </Button>
            </Box>
            <Box mt={10}>
              <Button bg="#FF1A57" rounded="md" w="full">
                <Text color="white" fontSize="14px" fontWeight="600">
                  Confirm changes
                </Text>
              </Button>
            </Box>
            <Box mt={10} mb={10}>
              <Button
                rounded="md"
                w="full"
                border="1px"
                borderColor="#1E0F42"
                bg="white"
              >
                <Text color="#1E0F42" fontSize="14px" fontWeight="600">
                  Discard changes
                </Text>
              </Button>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default EditCategory;
