import {
  Box,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Radio,
  RadioGroup,
  Textarea,
  Select,
  Grid,
  GridItem,
  HStack,
} from "@chakra-ui/react";

import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { createOffer } from "../../api/offer";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ChangeImages from "../../components/ChangeImages";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../components/Header";
import moment from "moment/moment";
import { DAYS, DAYSDATA } from "../../constants";
import _ from "lodash";
import { offerValidator } from "../../helpers/validation";
import { getAllCategories } from "../../api/category";
import { getAllSellerData } from "../../api/seller";
// import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
// import { category } from "../../assets/icons";

// const schema = yup
//   .object({
//     deliveryCharges: yup.string().required("Delivery charge is required when delivery option is selected."),
  
//   })
//   .required()

  
  
  // Define the validation schema
  const schema = yup
  .object({
    subCategory: yup.string()
  })
  .required()
  
  const NewOffer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categories: cat } = useSelector(state => state?.offers);
    const [days, setDays] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [originalPrice, setOriginalPrice] = useState("");
    const [discountedPrice, setDiscountedPrice] = useState("");
    const [percentagePrice, setPercentagePrice] = useState("");
    const [isStudentDisable, setIsStudentDisable] = useState(true);
    const [isNHSDisable, setIsNHSDisable] = useState(true);
    const [checked, setChecked] = useState(null);
    const [studentPrice, setStudentPrice] = useState("");
    const [nhsPrice, setNhsPrice] = useState("");
    const [studentFinalPrice, setStudentFinalPrice] = useState("");
    const [nhsFinalPrice, setNhsFinalPrice] = useState("");
    const [sellers, setSellers] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [selectedDays, setSelectedDays] = useState(DAYSDATA);
    const [isParentChecked, setIsParentChecked] = useState(false);
    const [checkedDays, setCheckedDays] = useState([]);
    const [imgError, setImgError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [deliveryError, setDeliveryError] = useState('');
    const [popularityError, setPopularityError] = useState('');
    const [sellerId, setSellerId] = useState(null);
  
    const [categoryValue, setCategoryValue] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [subCategoryValue, setSubCategoryValue] = useState("");
    const [subCategoryName, setSubCategoryName] = useState("");

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {deliveryCharges: 0}
    });
  
    const toggle = (evt) => {
      setChecked((current) =>
        current === evt.target.value ? null : evt.target.value
      );
    };
  
    const handleStudent = (e, type) => {
      if (type === "student") {
        if (e) {
          setIsStudentDisable(false);
        } else {
          setIsStudentDisable(true);
          setStudentPrice("");
          setStudentFinalPrice("");
        }
      } else {
        if (e) {
          setIsNHSDisable(false);
        } else {
          setIsNHSDisable(true);
          setNhsPrice("");
          setNhsFinalPrice("");
        }
      }
    };
  
    const handleOriginalPrice = (e) => {
      setOriginalPrice(e.target.value);
    };
  
    const handleDiscountedPrice = (e) => {
      setDiscountedPrice(e.target.value);
    };
  
    const handlePercentagePrice = (e) => {
      setPercentagePrice(e.target.value);
    };
  // const [categoryValue,setCategoryValue] = useState("")
  // const [subCategoryValue,setSubCategoryValue] = useState("")
 

    const handleCategoryValue = (e) => {
      const selectedCategoryId = e.target.value;
      const selectedCategoryName = e.target.options[e.target.selectedIndex].text;
      setCategoryValue(selectedCategoryId);
      // Optionally store the name
      setCategoryName(selectedCategoryName);
    }
    const handleSubCategoryValue = (e) => {
      const selectedSubCategoryId = e.target.value;
      const selectedSubCategoryName = e.target.options[e.target.selectedIndex].text;
      setSubCategoryValue(selectedSubCategoryId);
      // Optionally store the name
      setSubCategoryName(selectedSubCategoryName);
    }

    // console.log(categoryValue,"categoryValue",subCategoryValue,"subCategoryValue",categoryName,subCategoryName,"dfsfdf")
  
    useEffect(() => {
      if (originalPrice === "" && discountedPrice !== "") {
        setPercentagePrice("");
      } else if (originalPrice === "" && percentagePrice !== "") {
        setDiscountedPrice("");
      } else if (originalPrice !== "" && discountedPrice === "") {
        setPercentagePrice("");
      } else if (originalPrice !== "" && discountedPrice !== "") {
        let price = originalPrice * percentagePrice;
        setPercentagePrice(`${100 - (discountedPrice * 100) / originalPrice}`);
      }
    }, [originalPrice, discountedPrice]);
  
    useEffect(() => {
      if (discountedPrice === "") {
        setPercentagePrice("");
      } else if (originalPrice === "" && discountedPrice !== "") {
        setPercentagePrice("");
      } else if (originalPrice === "" && percentagePrice !== "") {
        setDiscountedPrice("");
      } else if (originalPrice !== "" && discountedPrice === "") {
        setPercentagePrice("");
      } else if (parseFloat(discountedPrice) > parseFloat(originalPrice)) {
        toast.error("Discounted Price can't be greater than original price");
        setPercentagePrice("");
        setDiscountedPrice("");
      } else if (originalPrice !== "" && discountedPrice !== "") {
        let price = 100 - (discountedPrice * 100) / originalPrice;
        setPercentagePrice(price.toFixed(2));
      }
    }, [discountedPrice]);
  
    const parseSellerId = (sellerId) => {
      try {
        return JSON.parse(sellerId)._id;
      } catch (error) {
        console.error("Failed to parse sellerId:", error);
        return null; // Or handle the error as needed
      }
    };
  
    const parseCategory = (category) => {
      try {
        return JSON.parse(category);
        
      } catch (error) {
        console.error("Failed to parse category:", error);
        return { _id: null, mainCategory: null }; // Or handle the error as needed
      }
    };
    const parseSubCategory = (subCategory) => {
      try {
        return JSON.parse(subCategory);
      } catch (error) {
        console.error("Failed to parse category:", error);
        return { _id: null, name: null }; // Or handle the error as needed
      }
    };
  
    const onSubmit = (data) => {
      if (gallery.length < 1) {
        setImgError('Add at least one image.');
      } else if (originalPrice === "") {
        setPriceError("Original Price is required");
      }
      else if (!checked) {
        setPopularityError("Popularity is required");
      } else {
        setImgError('');
        setPriceError('');
        setDeliveryError('');
        setPopularityError('');
  
        const originalPri = parseFloat(originalPrice).toFixed(2);
        const discountedPri = parseFloat(discountedPrice).toFixed(2);
        const sp = parseFloat(studentFinalPrice).toFixed(2);
        const np = parseFloat(nhsFinalPrice).toFixed(2);
  
        let apiData = {
          offerName: data.offerName,
          sellerId: parseSellerId(sellerId),
          quantity: data.quantity,
          category: {
            id: categoryValue,
            name: categoryName,
          },
          subCategory: {
            id: subCategoryValue,
            name: subCategoryName,
          },
          isReducedPrice: false,
          commision: 10,
          ageRestrictedProduct: data.ageRestrictedProduct,
          address: 'demo address',
          description: data.description,
          days: selectedDays,
          gallery,
          cancellationPolicy: data.cancellationPolicy,
          validUntill: parseInt(data.validUntill),
          percentage: 10,
          specialDiscount: '50',
          studentDiscount: studentFinalPrice || 0,
          studentPrice: studentPrice || 0,
          NHSDiscount: nhsFinalPrice || 0,
          NHSPrice: nhsPrice || 0,
          price: {
            orignalPrice: Number(originalPri),
            discountedPrice: Number(discountedPri),
            percentagePrice: Number(percentagePrice),
          },
          duration: {
            startDate: moment(data.startDate).format('DD/MM/YYYY'),
            endDate: moment(data.endDate).format('DD/MM/YYYY'),
          },
          delivery: delivery,
          deliveryCharges: Number(data.deliveryCharges),
          terms: data.terms,
          popularity: checked,
        };
  //  return console.log(apiData,"apiDataaaaaa")
        apiData.varients = [];
  
        if (data.varientColor) {
          apiData.varients.push({
            key: "color",
            value: data.varientColor,
          });
        }
  
        if (data.varientSize) {
          apiData.varients.push({
            key: "size",
            value: data.varientSize,
          });
        }
        // return console.log(apiData,"==== datta")
        dispatch(createOffer(apiData))
          .then((res) => {
            if (res.statusCode === 200) {
              toast.success('Offer Created Successfully!');
              navigate('/manage-offers');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
  
    useEffect(() => {
      dispatch(getAllCategories()).then((res) => {
        setCategories(res);
      });
    }, [dispatch]);
  
    useEffect(() => {
      dispatch(getAllSellerData()).then((res) => {
        const data = res.data.sellers?.filter(
          (item) => item.accountStatus === "approved"
        );
        setSellers(data);
      });
    }, [dispatch]);
  
    const handleStudentPrice = (e) => {
      const price = e.target.value;
      setStudentPrice(price);
      if (originalPrice !== "" && price !== "") {
        let sp = `${originalPrice - (price / 100) * originalPrice}`;
        setStudentFinalPrice(parseFloat(sp).toFixed(2));
      } else {
        setStudentFinalPrice("");
      }
    };
  
    const handleNhsPrice = (e) => {
      const enterPrice = e.target.value;
      setNhsPrice(enterPrice);
      if (originalPrice !== "" && enterPrice !== "") {
        let np = `${originalPrice - (enterPrice / 100) * originalPrice}`;
        setNhsFinalPrice(parseFloat(np).toFixed(2));
      } else {
        setNhsFinalPrice("");
      }
    };
  
    useEffect(() => {
      if (parseFloat(studentFinalPrice) > parseFloat(originalPrice)) {
        toast.error(
          "Student Effective price should not be greater than original price"
        );
        setStudentFinalPrice("");
        setStudentPrice("");
      }
    }, [studentFinalPrice, originalPrice]);
  
    useEffect(() => {
      if (parseFloat(studentPrice) > 100) {
        toast.error("Student Percentage discount should not be greater than 100");
        setStudentPrice("");
        setStudentFinalPrice("");
      }
    }, [studentPrice, originalPrice]);
  
    useEffect(() => {
      if (parseFloat(nhsFinalPrice) > parseFloat(originalPrice)) {
        toast.error(
          "NHS Effective price should not be greater than original price"
        );
        setNhsFinalPrice("");
        setNhsPrice("");
      }
    }, [nhsFinalPrice, originalPrice]);
  
    useEffect(() => {
      if (nhsPrice > 100) {
        toast.error("NHS Percentage discount should not be greater than 100");
        setNhsPrice("");
        setNhsFinalPrice("");
      }
    }, [nhsPrice, originalPrice]);
  
    const handleDelivery = (e) => {
      if (e.target.checked) {
        setDelivery([...delivery, e.target.value]);
      } else {
        const filteredData = delivery.filter((item) => item !== e.target.value);
        setDelivery(filteredData);
      }
    };
  
    const changeCheckboxStatus = (e, id) => {
      const myUsers = [...selectedDays];
      const { checked } = e.target;
      myUsers.map((user) => {
        if (id === "allChecked") {
          setIsParentChecked(checked);
          user.isChecked = checked;
        } else {
          if (user.id === id) {
            user.isChecked = checked;
            if (checked) {
              setCheckedDays([...checkedDays, user]);
            } else {
              const removeUser = checkedDays.filter((item) => item !== user);
              setCheckedDays(removeUser);
            }
          }
          const isAllChildsChecked = myUsers.every(
            (user) => user.isChecked === true
          );
          if (isAllChildsChecked) {
            setIsParentChecked(checked);
          } else {
            setIsParentChecked(false);
          }
        }
        return user;
      });
  
      setSelectedDays([...myUsers]);
    };
  
  //   return (
  //     <form onSubmit={handleSubmit(onSubmit)}>
  //       {/* Render form fields and error messages */}
  //       {/* Example: */}
  //       <input {...register("offerName")} placeholder="Offer Name" />
  //       {errors.offerName && <p>{errors.offerName.message}</p>}
  //       {/* Other fields... */}
  //       <button type="submit">Submit</button>
  //     </form>
  //   );
  // };
  
  // export default NewOffer;
  
  return (
    <>
      <Layout>
        <Box>
          <Box display="flex" justifyContent="space-between" px={8}>
            <Heading
              color="#1E0F42"
              fontSize={{ xl: "34px", md: "30px" }}
              fontWeight="600"
              w="full"
            >
              New Offer
            </Heading>
            <Header />
          </Box>
          <ChangeImages gallery={gallery} setGallery={setGallery} />
          <Text fontSize="14px" color="red" ml={8}>{imgError}</Text>
          <Box w={{ xl: "61%", lg: "70%",md:"96%" }} p={1} mt={4} pl={8}>
            <Box w="100%">
              <Box>
                <Box w="100%">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Select seller
                    </FormLabel>
                    <Select
                      w="100%"
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      placeholder="Select seller"
                      shadow="lg"
                      bg="white"
                      onChange={(e)=>setSellerId(e.target.value)}
                      // {...register("sellerId",{ required: true })}
                    >
                      {sellers.map((value) => (
                        <option value={JSON.stringify(value)} key={value._id}>
                          {value.name.businessName}
                        </option>
                      ))}
                    </Select>
                    {/* <Text my={1} color="red" fontSize="xs">
                      {errors.sellerId?.message}
                    </Text> */}
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                      mt={4}
                    >
                      Select Category
                    </FormLabel>
                    <Select
                      w="100%"
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      placeholder="Select Category"
                      shadow="lg"
                      bg="white"
                      // {...register("category",{ required: true })}
                      onChange={handleCategoryValue}
                      value={categoryValue}
                    >
                      {sellerId && sellerId ? 
                    <option  value={JSON.parse(sellerId)?.category?.id}>
                    {JSON.parse(sellerId)?.category?.name}
                  </option>  :null
                    }
                    </Select>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.category?.message}
                    </Text>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                      mt={4}
                    >
                      Select Sub Category
                    </FormLabel>
                    <Select
                      w="100%"
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      placeholder="Select Sub Category"
                      shadow="lg"
                      bg="white"
                      value={subCategoryValue}
                      onChange={handleSubCategoryValue}
                      // {...register("subCategory",{ required: true })}
                    >
                      {sellerId && sellerId ? 
                    <option  value={JSON.parse(sellerId)?.subCategory?.id}>
                    {JSON.parse(sellerId)?.subCategory?.name}
                  </option>  :null
                    }
                     {/* <option>sub - category</option>    */}
                     
                    </Select>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.subCategory?.message}
                    </Text>
                    <Box display="flex" justifyContent="space-between">
                <Box w="48%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Varient Size 
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      type="text"
                     
                      shadow="lg"
                      bg="white"
                      {...register("varientSize", { required: true })}
                    />
                  {/*  <Text my={1} color="red" fontSize="xs">
                      {errors.varientSize?.message}
                    </Text>
                    */}
                  </FormControl>
                </Box>
                <Box w="48%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Varient Color
                    </FormLabel>
                    <Input
                      overflow={"hidden"}
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      type="color"
                      shadow="lg"
                      p={0}
                      bg="white"
                      {...register("varientColor")}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.varientColor?.message}
                    </Text>
                  </FormControl>
                </Box>
                
              </Box>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                      mt={4}
                    >
                      Offer name
                    </FormLabel>
                    <Input
                      w="100%"
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      placeholder="Discounted Sausage Rolls"
                      shadow="lg"
                      {...register("offerName", { required: true })}
                      bg="white"
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.offerName?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  w="100%"
                  mt={4}
                ></Box>
              </Box>
              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                gap={6}
              >
                <Box w="50%" display="flex" justifyContent="space-between">
                  <Box w="46%" mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                      >
                        Quantity
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        shadow="lg"
                        bg="white"
                        {...register("quantity", { required: true })}
                        onKeyDown={(event) => {
                          if (event.key === ".") {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          event.target.value = event.target.value.replace(
                            /[^0-9]*/g,
                            ""
                          );
                        }}
                      />
                    </FormControl>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.quantity?.message}
                    </Text>
                  </Box>
                  <Box w="46%" mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                      >
                        Original Price
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        shadow="lg"
                        bg="white"
                        type="number"
                        value={originalPrice}
                        onChange={handleOriginalPrice}
                        // {...register('orignalPrice', {required: true})}
                      />
                       <Text fontSize="14px" color="red">{priceError}</Text>
                    </FormControl>
                  </Box>
                </Box>
                <Box w="50%" display="flex" justifyContent="space-between">
                  <Box w="46%" mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                      >
                        Discounted Price
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        shadow="lg"
                        bg="white"
                        type="number"
                        value={discountedPrice}
                        onChange={handleDiscountedPrice}
                        // {...register('discountedPrice', {required: true})}
                      />
                      <Text my={1} color="red" fontSize="xs">
                        {errors.discountedPrice?.message}
                      </Text>
                    </FormControl>
                  </Box>
                  <Box w="47%" mt={3}>
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        fontWeight="400"
                        color="#6B6162"
                      >
                        Percentage Discount
                      </FormLabel>
                      <Input
                        type="number"
                        fontSize="14px"
                        fontWeight="400"
                        color="#4B4447"
                        shadow="lg"
                        bg="white"
                        cursor={"not-allowed"}
                        disabled
                        value={percentagePrice}
                        onChange={handlePercentagePrice}
                        // {...register('percentagePrice', {required: true})}
                      />
                      <Text my={1} color="red" fontSize="xs">
                        {errors.percentagePrice?.message}
                      </Text>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Box w="48%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Start Date
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      type="date"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      shadow="lg"
                      bg="white"
                      {...register("startDate", { required: true })}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.startDate?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box w="48%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      End Date
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      type="date"
                      shadow="lg"
                      bg="white"
                      {...register("endDate")}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.endDate?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Box w="31%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Special Discounts(%)
                    </FormLabel>
                    <HStack>
                      <Checkbox
                        colorScheme="pink"
                        onChange={(e) =>
                          handleStudent(e.target.checked, "student")
                        }
                      >
                        <Text fontSize="14px" w="69px" h="21px">
                          Student
                        </Text>
                      </Checkbox>
                      <Input
                        type="number"
                        bg="white"
                        shadow="lg"
                        disabled={isStudentDisable}
                        value={studentPrice}
                        onChange={handleStudentPrice}
                        // {...register("studentDiscount")}
                      />
                    </HStack>
                    <HStack my={2}>
                      <Checkbox
                        colorScheme="pink"
                        onChange={(e) => handleStudent(e.target.checked, "nhs")}
                      >
                        <Text fontSize="14px" w="69px" h="21px">
                          NHS
                        </Text>
                      </Checkbox>
                      <Input
                        type="number"
                        isDisabled={isNHSDisable}
                        onChange={handleNhsPrice}
                        bg="white"
                        value={nhsPrice}
                        shadow="lg"
                        // {...register("NHSDiscount")}
                      />
                    </HStack>
                    <Text my={1} color="red" fontSize="xs">
                      {errors.specialDiscount?.message}
                    </Text>
                  </FormControl>
                </Box>

                <Box w="31%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Effective Price
                    </FormLabel>
                    <Input
                      type="number"
                      readOnly={true}
                      fontSize="14px"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      value={studentFinalPrice}
                      fontWeight="400"
                    ></Input>
                  </FormControl>
                  <FormControl>
                    <Input
                      type="number"
                      readOnly={true}
                      fontSize="14px"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      value={nhsFinalPrice}
                      fontWeight="400"
                    ></Input>
                  </FormControl>
                </Box>
                <Box w="31%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Age restricted product?
                    </FormLabel>
                    <Select
                      placeholder="Select One"
                      fontSize="14px"
                      color="#4B4447"
                      shadow="lg"
                      // value={false}
                      bg="white"
                      fontWeight="400"
                      {...register("ageRestrictedProduct", {required: true})}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Select>
                    <Text my={1} color="red" fontSize="xs">
             {errors.ageRestrictedProduct?.message}
          </Text>
                  </FormControl>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Box w="48%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Cancellation Policy
                    </FormLabel>
                    <Textarea
                      fontSize="14px"
                      fontWeight="400"
                      color="#4B4447"
                      type="text"
                      shadow="lg"
                      bg="white"
                      {...register("cancellationPolicy")}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.cancellationPolicy?.message}
                    </Text>
                  </FormControl>
                </Box>
                <Box w="48%" mt={3}>
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      fontWeight="400"
                      color="#6B6162"
                      mb="0.5"
                    >
                      Cancellation validity from the date of purchase
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      type="number"
                      color="#4B4447"
                      shadow="lg"
                      bg="white"
                      h={20}
                      {...register('validUntill')}
                      onKeyDown={event => {
                        if (event.key === '.') {
                          event.preventDefault();
                        }
                      }}
                      onInput={event => {
                        event.target.value = event.target.value.replace(
                          /[^0-9]*/g,
                          '',
                        );
                      }}
                    />
                    <Text my={1} color="red" fontSize="xs">
                      {errors.validUntill?.message}
                    </Text>
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    fontWeight="400"
                    color="#6B6162"
                    mb="0.5"
                  >
                    Terms & Conditions
                  </FormLabel>
                  <Textarea
                    fontSize="14px"
                    fontWeight="400"
                    color="#4B4447"
                    type="text"
                    shadow="lg"
                    bg="white"
                    {...register("terms")}
                  />
                  <Text my={1} color="red" fontSize="xs">
                    {errors.terms?.message}
                  </Text>
                </FormControl>
              </Box>
            </Box>

            <Box mt={5}>
              <Box>
                <Text fontSize="14px" color="#6B6162">
                  Days
                </Text>
              </Box>
              <Box w="100%" shadow="lg" bg="white" rounded="lg" p={2}>
                <Box w="67%" m={4}>
                  <Checkbox
                    onChange={(e) => changeCheckboxStatus(e, "allChecked")}
                    isChecked={isParentChecked}
                    colorScheme="pink"
                  >
                    Select All Days
                  </Checkbox>
                  <Grid
                    templateRows="repeat(3, 1fr)"
                    templateColumns="repeat(3, 1fr)"
                    gap={10}
                  >
                    {DAYSDATA.map((day, index) => (
                      <GridItem
                        key={day.day}
                        colSpan={1}
                        w={160}
                        display="flex"
                      >
                        <Box>
                          <Checkbox
                            isChecked={day?.isChecked}
                            colorScheme="pink"
                            value="child"
                            onChange={(e) =>
                              changeCheckboxStatus(e, index, day)
                            }
                          >
                            <Text
                              fontSize="14px"
                              color="#3B3538"
                              w="74px"
                              h="21px"
                            >
                              {day.day}
                            </Text>
                          </Checkbox>
                        </Box>
                      </GridItem>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box mt={6} w="100%" shadow="lg" bg="white" p={2} rounded="lg">
              <Box mt={3} w="73%">
                <RadioGroup colorScheme="pink" p={1}>
                  <Box
                    m={5}
                    display="flex"
                    justifyContent={"space-between"}
                    w="full"
                  >
                    <Box w="30%">
                      <Checkbox
                        value="Dine-in"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Dine-in
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box w="30%">
                      <Checkbox
                        value="Take-away"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Take-away
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box w="30%">
                      <Checkbox
                        value="Delivery"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Delivery
                        </Text>
                      </Checkbox>
                    </Box>
                  </Box>
                  <Box
                    m={5}
                    display="flex"
                    justifyContent={"space-between"}
                    w="full"
                  >
                    <Box w="30%">
                      <Checkbox
                        value="In-stores"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" color="#3B3538">
                          In-stores
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box w="30%">
                      <Checkbox
                        value="Online"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Online
                        </Text>
                      </Checkbox>
                    </Box>
                    <Box w="30%">
                      <Checkbox
                        value="Home Services"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" h="21px" color="#3B3538">
                          Home Services
                        </Text>
                      </Checkbox>
                    </Box>
                  </Box>
                  <Box
                    m={5}
                    display="flex"
                    justifyContent={"space-between"}
                    w="full"
                  >
                    <Box w="30%">
                      <Checkbox
                        value="Other"
                        onChange={handleDelivery}
                        colorScheme="pink"
                      >
                        <Text fontSize="14px" color="#3B3538">
                          Other
                        </Text>
                      </Checkbox>
                    </Box>
                  </Box>
                </RadioGroup>
              </Box>
            </Box>
            {/* <Text fontSize="14px" color="red">{deliveryError}</Text> */}
            {(delivery.includes("Delivery") || delivery.includes("Online") || delivery.includes("Home Services")) && (
            <FormControl>
                <FormLabel
                  fontSize="14px"
                  fontWeight="400"
                  color="#6B6162"
                  mt="5"
                >
                  Delivery Charges
                </FormLabel>
                <Input
                  w="100%"
                  fontSize="14px"
                  fontWeight="400"
                  color="#4B4447"
                  placeholder="Delivery Charges"
                  shadow="lg"
                  type="number"
                  {...register("deliveryCharges", { required: true })}
                  bg="white"
                />
                <p style={{fontSize: "13px", color: "red", marginTop: "2px"}}>{errors.deliveryCharges?.message}</p>
                {/* <Text my={1} color="red" fontSize="xs">
                 {deliveryError}
                </Text> */}
              </FormControl>
                )}

            <Box
              mt={3}
              w="100%"
              shadow="lg"
              bg="white"
              p={2}
              rounded="lg"
              {...register("popularity", { required: true })}
            >
              <Box mt={3} w="71%">
                <Box
                  m={5}
                  colorScheme="pink"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box>
                    <Checkbox
                      colorScheme="pink"
                      value="peak"
                      isChecked={checked == "peak"}
                      onChange={toggle}
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        Peak
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box>
                    <Checkbox
                      colorScheme="pink"
                      value="offPeak"
                      isChecked={checked == "offPeak"}
                      onChange={toggle}
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        Off-Peak
                      </Text>
                    </Checkbox>
                  </Box>
                  <Box>
                    <Checkbox
                      colorScheme="pink"
                      value="anyTime"
                      isChecked={checked == "anyTime"}
                      onChange={toggle}
                    >
                      <Text fontSize="14px" w="69px" h="21px">
                        Any Time
                      </Text>
                    </Checkbox>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Text fontSize="14px" color="red">{popularityError}</Text>
            <Box>
              <Box mt={5}>
                <Text fontSize="14px" w="113px" h="21px" color="#6B6162">
                  Offer description{" "}
                </Text>
                <Textarea
                  shadow="lg"
                  bg="white"
                  placeholder=""
                  fontSize="14px"
                  color="#4B4447"
                  {...register("description", { required: true })}
                />
                <Text my={1} color="red" fontSize="xs">
                  {errors.description?.message}
                </Text>
              </Box>
              <Box w="full" align="center" mt={8}>
                <Box mt={2}>
                  <Button
                    w="100%"
                    bg="#FF1A57"
                    color="white"
                    fontSize="14px"
                    fontFamily="Commissioner"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default NewOffer;
