import React from "react";
import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const TopCard = ({ title, icon, total, profit, link }) => {
  const navigate = useNavigate();
  return (
    <Box
      key={title}
      rounded="8px"
      w={["full", "32%"]}
      bg="white"
      shadow="lg"
      px={2}
      py={6}
      my={2}
    >
      <VStack spacing={1}>
        <HStack justifyContent="center" alignItems="center">
          <Image src={icon} w="28px" h="28px" />
          <Text
            fontSize={["32px", "48px", "32px"]}
            color="primary"
            fontWeight={600}
          >
            {total}
          </Text>
        </HStack>
        <Text fontSize={["16px", "20px"]} fontWeight={600}>
          {title}
        </Text>
        <Text fontSize={["10px", "12px"]} color="#72992E" fontWeight={600}>
          {profit}
        </Text>
        {total > 0 && (
          <Button
            fontSize={13}
            disabled={total <= 0}
            variant="outline"
            borderColor="primary"
            onClick={() => navigate(link)}
            color="primary"
          >
            {"Manage >"}
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default TopCard;
