import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buyers: [],
  buyer : {}
};

const buyerSlice = createSlice({
  name: "buyers",
  initialState,
  reducers: {
    getAllBuyers: (state, action) => {
      state.buyers = action.payload;
    },
    getBuyer: (state, action) => {
      state.buyer = action.payload;
    },
  },
});

export const { getAllBuyers , getBuyer } = buyerSlice.actions;

export default buyerSlice.reducer;
