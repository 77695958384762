import { setToLocalStorage } from "../helpers";
import { getAdmins } from "../redux/reducers/adminSlice";
import { getDashboardData, loginSuccess } from "../redux/reducers/userSlice";
import api from "./index";

// Login using Email
export const userLoginUsingEmail = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/admin/auth/login", apiData);
      dispatch(loginSuccess(res.data.data));
      setToLocalStorage("token", res.data.data.token);
      setToLocalStorage("userData", res.data.data.user);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Forgot password api...
export const forgotPassword = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/admin/auth/forgot-password", apiData);
      resolve(res.data.data);
    } catch (err) {
     reject(err)
    }
  });
};   

//************* admin change password *************/
export const changeOldPassword = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/admin/change-password", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

//**admin verify-forgot-password-otp api */

export const otpVerification = (apiData) => async (dispatch) => {
  return new Promise(async (resolve,reject) => {
    try {
      const res = await api.post("/admin/auth/verify-password-otp",apiData)
      resolve(res.data.data)
    } catch (err){
     reject(err)      
    } 
  })
}

/** admin setPassword-Token **** */

export const setPassword = (token, apiData)=> async (dispatch) => {
  return new Promise(async(resolve,reject) =>{
    try {
      const res = await api.post(`/admin/auth/set-password/${token}`, apiData)
      resolve(res.data)
    } catch (err) {
      reject(err)
    }
  })
}

//-----get Admin Details------------

export const getAdminDetails = ()=> async(dispatch)=>{
  return new Promise(async(resolve , reject)=>{
    try{
      const res = await api.get("/admin/get-profile");
      resolve(res.data.data.adminDetails);
      dispatch(getAdmins(res.data.data.adminDetails));
    }catch(error){
      reject(error)
    }
  })
}

//--------get Dashboard Details----------


 export const getDashboardDetails = ()=>async(dispatch)=>{
  return new Promise(async(resolve , reject)=>{
    try{
      const res = await api.get("/admin/get-dashboard-details");
      resolve(res.data.data);
      // we need to pass our data to the redux store from here 
      dispatch(getDashboardData(res.data.data))
    }catch(error){
      reject(error)
    }
  })
 }