import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  subCategory: [],
  buttonType:"Add",
  offers:[],
  offer : {}
};

const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    getCategories: (state, action) => {
      state.categories = action.payload;
    },
    getSubCategories: (state, action) => {
      state.subCategory = action.payload;
    },
    categoryButtonType: (state, action) => {
      state.buttonType = action.payload;
    },
    allOffers: (state, action) => {
      state.offers = action.payload;
    },
    getOffer: (state, action) => {
      state.offer = action.payload;
      
    },
  },
});

export const { getCategories, getSubCategories, categoryButtonType, allOffers , getOffer} = offerSlice.actions;

export default offerSlice.reducer;
