import {
  Box,
  Text,
  Image,
  HStack,
  VStack,
  Button,
  Stack,
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Hide,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { cart, caution, offer, support, userSvg } from "../../assets/icons";
import Charts from "../../components/Chart";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import TopCard from "../../components/TopCard";
import Header from "../../components/Header";
import { useEffect } from "react";
import { getDashboardDetails } from "../../api/user";
import { useNavigate } from "react-router-dom";
import { getGraphData } from "../../api/admin";
import Chart2 from "../../components/Chart2";

const MainDashboard = () => {
  const { dashboardData, graphData } = useSelector((state) => state.authUser);
  const [graphType, setGraphType] = useState("buyer");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("dashboardData", dashboardData);

  useEffect(() => {
    dispatch(getDashboardDetails());
  }, []);

  function fixed(value) {
    if (value) {
      return value.toFixed(0);
    }
  }

  const handleSupportTickets = () => {
    navigate("/support-tickets");
  };

  const getGraph = (type) => {
    setIsLoading(true);
    dispatch(getGraphData(type))
      .then((res) => {
        if (res.statusCode === 200) {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getGraph(graphType);
  }, [graphType]);

  const handleGraphType = (index) => {
    switch (index) {
      case 0:
        setGraphType("buyer");
        break;
      case 1:
        setGraphType("activeSeller");
        break;
      case 2:
        setGraphType("activeOffer");
        break;
      case 3:
        setGraphType("redeemOffer");
        break;
      default:
        setGraphType("buyer");
        break;
    }
  };

  return (
    <Layout>
      <Box
        w={{ xl: "99%", lg: "90%", md: "98%" }}
        mx={{ xl: "auto", lg: "auto", md: "auto" }}
        h="100%"
      >
        <Box w="full" display="flex" justifyContent="space-around">
          <Hide below="sm">
            <Box>
              <Text
                fontSize={["20px", "20px", "20px", 32]}
                fontWeight="600"
                color="#1E0F42"
              >
                Home
              </Text>
            </Box>
          </Hide>
          <Header />
        </Box>
        <Stack
          alignItems="baseline"
          direction={["column", "row"]}
          justifyContent="space-between"
          mt={6}
        >
          <TopCard
            total={dashboardData?.buyerCount}
            profit={`${fixed(
              dashboardData?.activeSellerPercentage
            )}% this month`}
            title="Registerd Buyers"
            icon={userSvg}
            link="/buyer-database"
          />
          <TopCard
            total={dashboardData?.sellerCount}
            profit={`${fixed(
              dashboardData?.activeBuyerPercentage
            )}% this month`}
            title="Active Sellers"
            icon={cart}
            link="/seller-database"
          />
          <TopCard
            total={dashboardData?.offerCount}
            profit={`${fixed(
              dashboardData?.activeOfferPercentage
            )}% this month`}
            title="Active offers"
            value={dashboardData}
            icon={offer}
            link="/manage-offers"
          />
        </Stack>

        <Grid
          mb={6}
          mt={2}
          h="400px"
          templateRows="repeat(3, 1fr)"
          templateColumns={["repeat(1, 1fr)", "repeat(6, 1fr)"]}
          gap={5}
        >
          <GridItem
            rounded="8px"
            h="400px"
            rowSpan={2}
            colSpan={4}
            bg="white"
            p={3}
          >
            <Tabs color="#6B6162" onChange={(index) => handleGraphType(index)}>
              <TabList
                overflowX={["scroll", "scroll", "hidden", "hidden", "hidden"]}
              >
                <Tab>Registered Buyers</Tab>
                <Tab>Active Sellers</Tab>
                <Tab>Active Offers</Tab>
                <Tab>Redeemed Offers</Tab>
              </TabList>
              {isLoading ? (
                <HStack
                  w="full"
                  h={360}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner color="primary" />
                </HStack>
              ) : (
                <TabPanels>
                  <TabPanel>
                    <Chart2
                      Yearslist={graphData?.Yearslist}
                      datalist={graphData?.datalist}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Chart2
                      Yearslist={graphData?.Yearslist}
                      datalist={graphData?.datalist}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Chart2
                      Yearslist={graphData?.Yearslist}
                      datalist={graphData?.datalist}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Chart2
                      Yearslist={graphData?.Yearslist}
                      datalist={graphData?.datalist}
                    />
                  </TabPanel>
                </TabPanels>
              )}
            </Tabs>
          </GridItem>
          <GridItem
            rounded="8px"
            mt={[4, 0]}
            p={[4, 2]}
            colSpan={[4, 2]}
            bg="#FFEBEB"
          >
            <Box h="full">
              <HStack justifyContent="center" mt={7}>
                <Image src={caution} w="26px" h="26px" />
                <Text color="primary" fontSize="32px" fontWeight={600}>
                  {dashboardData?.sellerApprovalRequest}
                </Text>
              </HStack>

              <Box h="80px">
                <Text
                  fontSize="20px"
                  color="#1E0F42"
                  fontWeight={600}
                  textAlign="center"
                >
                  Seller Approval Requests
                </Text>
              </Box>
            </Box>
          </GridItem>
          <GridItem rounded="8px" colSpan={[4, 2]} p={[4, 0]} bg="#FFEBEB">
            <VStack spacing={-4} h="full">
              <HStack my={6}>
                <Image src={support} w="26px" h="26px" />
                <Text color="primary" fontSize="32px" fontWeight={600}>
                  {dashboardData?.supportCenterCount}
                </Text>
              </HStack>
              <VStack spacing={4}>
                <Text
                  fontSize="20px"
                  color="#1E0F42"
                  fontWeight={600}
                  align="center"
                >
                  Support Tickets
                </Text>
                {dashboardData?.supportCenterCount > 0 && (
                  <Button
                    fontSize={13}
                    disabled={dashboardData?.supportCenterCount <= 0}
                    variant="outline"
                    borderColor="primary"
                    color="primary"
                    onClick={handleSupportTickets}
                  >
                    {"Manage >"}
                  </Button>
                )}
              </VStack>
            </VStack>
          </GridItem>
        </Grid>
      </Box>
    </Layout>
  );
};

export default MainDashboard;
