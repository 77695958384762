import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Outlet} from 'react-router-dom';
import { getFromLocalStorage } from '../helpers';

const PrivateRoutes = () => {
  const token = getFromLocalStorage('token');
  const user = getFromLocalStorage('userData');


  // const {user, token} = useSelector(state => state.authUser);
  return user || Object.keys(getFromLocalStorage('userData')).length ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
